<script lang="ts">
	import type { UserSettings } from '../../../shared/database';
	import { createErrorDialog } from '../dialogs';
	import { sortSites } from '../sort';
	import { pSites, showNsfwContent, useMobileLayout } from '../state';
	import { getSiteHomeUrl, getSiteName } from '../settings';
	import SiteIcon from './SiteIcon.svelte';
	import ExternalLink from './input/ExternalLink.svelte';
    import AutofillTextInput from './input/AutofillTextInput.svelte';

	export let userSettings: UserSettings | null;
	export let userHasAccess: boolean;
</script>

<article>
	{#if !userHasAccess}
		<section>
			<h1>Access Denied</h1>
			<p>You do not have permission to use this site, either because you are not logged in or your account is not on the list of permitted accounts. To learn how to gain access, <a href="/help/access">refer to this page</a>.</p>
		</section>
	{/if}

	<section>
		{#if userHasAccess}
			<h1>Site Info</h1>
		{:else}
			<h2>What Is This Site For?</h2>
		{/if}
		<p>This site serves the same purpose as RSS feed readers, but for web novels.</p>
		<p>You know how basically every web novel site has a built-in way to follow stories and get notified when they update? That's great, I love that, but the <em>problem</em> is that if you frequent enough sites having to look through each site's individual thing takes a long time.</p>
		<p>That's what this site is here to fix. It's one, unified place to recieve updates on stories being followed. Oh, and also to get around sites that actually <em>don't</em> provide this functionality themselves (looking at you, AO3).</p>
	</section>

	<section>
		<h2>Help With</h2>
		<ul>
			<li><a href="/help/access">Getting Access to This Site</a></li>
			<li><a href="/help/ctf">Complex Tag Filtering</a></li>
		</ul>
	</section>

	<section>
		<h2>All Supported Sites</h2>
		{#await $pSites}
			<p>Loading supported sites...</p>
		{:then siteData}
			{@const sortedSites = sortSites(userSettings, Object.values(siteData.sites))}
			{@const displayedSites = sortedSites.filter(site => $showNsfwContent || !site.isNsfw)}
			{@const numHiddenSites = sortedSites.length - displayedSites.length}
			<ul>
				{#each displayedSites as site (site.site)}
					{@const homeUrl = userSettings === null ? site.homeUrl : getSiteHomeUrl(userSettings, site, siteData.sites, [])}
					{@const siteName = userSettings === null ? site.name : getSiteName(userSettings, site, siteData.sites, [])}
					<li>
						<SiteIcon site={site} userSettings={userSettings} allSites={siteData.sites} alternateAvailability={[]} />
						<ExternalLink href={homeUrl}>{siteName}</ExternalLink>
					</li>
				{/each}
			</ul>
			{#if numHiddenSites !== 0}
				<p>{numHiddenSites} {numHiddenSites === 1 ? 'site is' : 'sites are'} currently hidden because of the NSFW Content setting.</p>
			{/if}
		{:catch e}
			{@const _ = createErrorDialog(e.message)}
		{/await}
	</section>

	<section>
		<h2>Explicitly Unsupported Sites</h2>
		<dl class:dl-grid={!$useMobileLayout}>
			<dt><ExternalLink href="https://www.scribblehub.com/">Scribble Hub</ExternalLink></dt>
			<dd>Does not expose a relevant API endpoint or RSS feed, and brute force web scraping runs into a Cloudflare captcha challenge that I can't get around without paying a lot of money. If you work on Scribble Hub <ExternalLink href="mailto:ruby.tunaley@gmail.com">please get in touch with me</ExternalLink> so we can figure something out.</dd>
			<dt><ExternalLink href="https://www.webtoons.com/en/">Webtoon</ExternalLink></dt>
			<dd>Web server uptime is too inconsistent, causing frequent errors.</dd>
		</dl>
	</section>
</article>

<style lang="scss">
	li > :global(*),
	.dl-grid > dt
	{
		vertical-align: middle;
	}
</style>
