export function nextAnimationFrame (): Promise<void>
{
	return new Promise(resolve => requestAnimationFrame(() => resolve()));
}

export function rafLoop (callback: () => void): () => void
{
	let go = true;
	const stopFn = () => go = false;
	const tick = (): void =>
	{
		if (go)
		{
			requestAnimationFrame(tick);
			callback();
		}
	};
	requestAnimationFrame(tick);
	return stopFn;
}
