<script context="module" lang="ts">
	export type SortDirection = 'asc' | 'desc';
</script>

<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import IconSortListAscending from './svg-icons/IconSortListAscending.svelte';
	import IconSortListDescending from './svg-icons/IconSortListDescending.svelte';
	import IconButton from './input/IconButton.svelte';

	export let direction: SortDirection;
	export let disabled = false;

	$: icon = direction === 'asc' ? IconSortListAscending : IconSortListDescending;
	$: alt = `Switch to ${direction === 'asc' ? 'Descending' : 'Ascending'} Order`;

	const dispatcher = createEventDispatcher<{
		change: {
			direction: SortDirection;
		};
	}>();

	function emitChange (): void
	{
		dispatcher('change', {
			direction: direction
		});
	}

	function toggleDirection (): void
	{
		direction = direction === 'asc' ? 'desc' : 'asc';
		emitChange();
	}
</script>

<IconButton
	icon={icon}
	alt={alt}
	classes={{
		secondary: true
	}}
	disabled={disabled}
	on:click={toggleDirection} />
