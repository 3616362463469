import { get, type Writable } from 'svelte/store';
import type { SiteData, StoryData, UndoData, GetAuthStateResponse, RlStatusStreamData, AddStoryStreamData } from '../../shared/common';
import type { LinkedServiceId, Log, ReadingList, SiteId, UserSettings } from '../../shared/database';
import { assertNever } from './assertions';

function jsonExport (value: unknown): string
{
	return JSON.stringify(
		value,
		(_, value) => (typeof value === 'bigint' ? ['int', Number(value)] : value)
	);
}

function jsonImport (text: string): unknown
{
	return JSON.parse(
		text,
		(_, value) => (Array.isArray(value) && value.length === 2 && value[0] === 'int' && typeof value[1] === 'number' && Number.isInteger(value[1]) ? BigInt(value[1]) : value)
	);
}

function isOk (response: Response, allow204: boolean = false): boolean
{
	return response.status === 200 ||
		response.status === 201 ||
		(allow204 && response.status === 204);
}

export class ApiError extends Error
{}

export class HttpError extends ApiError
{
	#complexMessage: string;

	constructor (
		method: string,
		url: string,
		header: string,
		body: string
	)
	{
		super(`${header}: ${body}`);
		this.#complexMessage = `[${method} ${url}] ${header}: ${body}`;
	}

	static async create (
		method: string,
		url: string,
		response: Response
	): Promise<HttpError>
	{
		return new HttpError(
			method,
			url,
			`${response.status} ${response.statusText}`,
			await response.text()
		);
	}

	override toString (): string
	{
		return this.#complexMessage;
	}
}

export interface AuthState
{
	readonly isLoggedIn: boolean;
	readonly isAuthorized: boolean;
	readonly linkedServices: readonly  LinkedServiceId[];
	readonly linkedAccounts: Partial<Readonly<Record<LinkedServiceId, string>>>;
}

export async function apiGetAuthState (): Promise<AuthState>
{
	const url = '/api/auth/get-state';
	const response = await fetch(url, {
		credentials: 'include',
		mode: 'no-cors'
	});
	if (response.status >= 500)
	{
		throw await HttpError.create('GET', url, response);
	}
	const isAuthorized = isOk(response);
	const json = isAuthorized ? jsonImport(await response.text()) as GetAuthStateResponse : null;
	return {
		isLoggedIn: isAuthorized || response.status === 403,
		isAuthorized: isAuthorized,
		linkedServices: json?.linkedServices ?? [],
		linkedAccounts: json?.linkedAccounts ?? {}
	};
}

export async function apiGetStoryData (site: SiteId, storyId: string): Promise<StoryData>
{
	if (typeof site !== 'string' || (site as any) === '')
	{
		throw new RangeError('site must be a non-empty string');
	}
	else if (typeof storyId !== 'string' || storyId === '')
	{
		throw new RangeError('storyId must be a non-empty string');
	}
	const url = `/api/story/${encodeURIComponent(site)}/${encodeURIComponent(storyId)}`;
	const response = await fetch(url, {
		credentials: 'include',
		mode: 'no-cors'
	});
	if (!isOk(response))
	{
		throw await HttpError.create('GET', url, response);
	}
	return jsonImport(await response.text()) as StoryData;
}

export async function apiCreateReadingList (name: string): Promise<ReadingList>
{
	if (typeof name !== 'string' || name === '')
	{
		throw new RangeError('name must be a non-empty string');
	}
	const url = `/api/reading-list/${encodeURIComponent(name)}`;
	const response = await fetch(url, {
		method: 'PUT'
	});
	if (!isOk(response))
	{
		throw await HttpError.create('PUT', url, response);
	}
	return jsonImport(await response.text()) as ReadingList;
}

export async function apiDeleteReadingList (listId: bigint): Promise<void>
{
	if (typeof listId !== 'bigint' || listId < 0n)
	{
		throw new RangeError('listId must be a non-negative bigint');
	}
	const url = `/api/reading-list/${listId}`;
	const response = await fetch(url, {
		method: 'DELETE'
	});
	if (!isOk(response, true))
	{
		throw await HttpError.create('DELETE', url, response);
	}
}

export interface AbortManager
{
	readonly controller: AbortController;
	readonly allowed: Writable<boolean>;
}

export function apiAddStoryToReadingList (
	listId: bigint,
	storyUrl: string | URL,
	onProgressUpdate: (update: string) => void,
	manager: AbortManager
): Promise<StoryData | null>
{
	return new Promise((resolve, reject) =>
	{
		manager.allowed.set(false);
		if (typeof listId !== 'bigint' || listId < 0n)
		{
			reject(new RangeError('listId must be a non-negative bigint'));
			return;
		}
		else if (
			(storyUrl instanceof URL && storyUrl.href === '') ||
			(!(storyUrl instanceof URL) && typeof storyUrl !== 'string') ||
			storyUrl === ''
		)
		{
			reject(new RangeError('storyUrl must be a non-empty URL or a non-empty string'));
			return;
		}

		const url = `${location.protocol === 'http:' ? 'ws' : 'wss'}://${location.hostname}:${location.port || (location.protocol === 'http:' ? '80' : '443')}` +
			`/api/reading-list/${listId}` +
			`/${encodeURIComponent(typeof storyUrl === 'string' ? storyUrl : storyUrl.href)}`;
		const ws = new WebSocket(url);
		let settled = false;
		manager.controller.signal.addEventListener('abort', () =>
		{
			if (get(manager.allowed))
			{
				settled = true;
				ws.close();
				resolve(null);
			}
		});
		ws.addEventListener('close', event =>
		{
			if (event.reason)
			{
				reject(new Error(event.reason));
				settled = true;
			}
			else if (!settled)
			{
				reject(new ApiError('WebSocket was closed without an object of kind "parse-finished" or "error" being encountered'));
				settled = true;
			}
		});
		ws.addEventListener('message', event =>
		{
			if (typeof event.data !== 'string')
			{
				reject(new TypeError('WebSocket message was not a string'));
				settled = true;
				return;
			}
			const value = jsonImport(event.data) as AddStoryStreamData;
			switch (value.kind)
			{
				case 'enable-abort':
					manager.allowed.set(true);
					ws.send('\x06');
					break;

				case 'disable-abort':
					manager.allowed.set(false);
					ws.send('\x06');
					break;

				case 'error':
					reject(new ApiError(value.message));
					settled = true;
					ws.close();
					break;

				case 'parse-finished':
					resolve(value.storyData);
					settled = true;
					ws.close();
					break;

				case 'progress-update':
					onProgressUpdate(value.message);
					break;

				default:
					assertNever(value, `Unknown AddStoryStreamData kind "${(value as any).kind}"`);
			}
		});
	});
}

export async function apiMoveStoryToReadingList (site: SiteId, storyId: string, targetListId: bigint): Promise<Log | null>
{
	if (typeof site !== 'string' || (site as any) === '')
	{
		throw new RangeError('site must be a non-empty string');
	}
	else if (typeof storyId !== 'string' || storyId === '')
	{
		throw new RangeError('storyId must be a non-empty string');
	}
	else if (typeof targetListId !== 'bigint' || targetListId < 0n)
	{
		throw new RangeError('listId must be a non-negative bigint');
	}
	const url = `/api/reading-list/${targetListId}/pull/${encodeURIComponent(site)}` +
		`/${encodeURIComponent(storyId)}`;
	const response = await fetch(url, {
		method: 'POST'
	});
	if (!isOk(response, true))
	{
		throw await HttpError.create('POST', url, response);
	}
	else if (response.status !== 204)
	{
		return jsonImport(await response.text()) as Log;
	}
	return null;
}

export async function apiRenameReadingList (listId: bigint, newName: string): Promise<void>
{
	if (typeof listId !== 'bigint' || listId < 0n)
	{
		throw new RangeError('listId must be a non-negative bigint');
	}
	else if (typeof newName !== 'string' || newName === '')
	{
		throw new RangeError('newName must be a non-empty string');
	}
	const url = `/api/reading-list/${listId}/${encodeURIComponent(newName)}`;
	const response = await fetch(url, {
		method: 'POST'
	});
	if (!isOk(response, true))
	{
		throw await HttpError.create('POST', url, response);
	}
}

export async function apiSwapReadingListPositions (list1Id: bigint, list2Id: bigint): Promise<void>
{
	if (typeof list1Id !== 'bigint' || list1Id < 0n)
	{
		throw new RangeError('list1Id must be a non-negative bigint');
	}
	else if (typeof list2Id !== 'bigint' || list2Id < 0n)
	{
		throw new RangeError('list2Id must be a non-negative bigint');
	}
	const url = `/api/reading-list/swap-positions/${list1Id}/${list2Id}`;
	const response = await fetch(url, {
		method: 'POST'
	});
	if (!isOk(response, true))
	{
		throw await HttpError.create('POST', url, response);
	}
}

export async function apiDeleteStoryFromReadingList (site: SiteId, storyId: string): Promise<Log>
{
	if (typeof site !== 'string' || (site as any) === '')
	{
		throw new RangeError('site must be a non-empty string');
	}
	else if (typeof storyId !== 'string' || storyId === '')
	{
		throw new RangeError('storyId must be a non-empty string');
	}
	const url = `/api/story/${encodeURIComponent(site)}/${encodeURIComponent(storyId)}`;
	const response = await fetch(url, {
		method: 'DELETE'
	});
	if (!isOk(response))
	{
		throw await HttpError.create('DELETE', url, response);
	}
	return jsonImport(await response.text()) as Log;
}

export async function apiUpdateStoryLastReadChapter (
	site: SiteId,
	storyId: string,
	lastReadChapterId: string | null
): Promise<Log | null>
{
	if (typeof site !== 'string' || (site as any) === '')
	{
		throw new RangeError('site must be a non-empty string');
	}
	else if (typeof storyId !== 'string' || storyId === '')
	{
		throw new RangeError('storyId must be a non-empty string');
	}
	else if ((lastReadChapterId !== null && typeof lastReadChapterId !== 'string') || lastReadChapterId === '')
	{
		throw new RangeError('lastReadChapterId must be null or a non-empty string');
	}
	let url;
	if (lastReadChapterId !== null)
	{
		url = `/api/story/${encodeURIComponent(site)}/${encodeURIComponent(storyId)}` +
			`/set-last-read-chapter/${encodeURIComponent(lastReadChapterId)}`;
	}
	else
	{
		url = `/api/story/${encodeURIComponent(site)}/${encodeURIComponent(storyId)}` +
			'/set-all-chapters-as-read';
	}
	const response = await fetch(url, {
		method: 'POST'
	});
	if (!isOk(response, true))
	{
		throw await HttpError.create('POST', url, response);
	}
	else if (response.status !== 204)
	{
		return jsonImport(await response.text()) as Log;
	}
	return null;
}

export async function* apiGetReadingListData (listId?: bigint | undefined): AsyncIterableIterator<RlStatusStreamData>
{
	if ((listId !== undefined && typeof listId !== 'bigint') || (typeof listId === 'bigint' && listId < 0n))
	{
		throw new RangeError('listId must be undefined or a non-negative bigint');
	}
	const url = `/api/reading-list${listId === undefined ? '' : `/${listId}`}`;
	const response = await fetch(url, {
		credentials: 'include',
		mode: 'no-cors'
	});
	if (!isOk(response, true) || response.body === null)
	{
		throw await HttpError.create('GET', url, response);
	}
	const streamReader = response.body.getReader();
	const decoder = new TextDecoder('utf-8');
	let buffer = '';
	let chunk: ReadableStreamReadResult<Uint8Array>;
	while (!(chunk = await streamReader.read()).done)
	{
		buffer += decoder.decode(chunk.value);
		let etxIndex = buffer.indexOf('\x03');
		while (etxIndex !== -1)
		{
			const value = jsonImport(buffer.substring(0, etxIndex)) as RlStatusStreamData;
			yield value;
			buffer = buffer.substring(etxIndex + 1);
			etxIndex = buffer.indexOf('\x03');
		}
	}
}

export async function apiGetLogs (listId: bigint): Promise<Log[]>
{
	if (typeof listId !== 'bigint' || listId < 0n)
	{
		throw new RangeError('listId must be a non-negative bigint');
	}
	const url = `/api/logs/${listId}`;
	const response = await fetch(url, {
		credentials: 'include',
		mode: 'no-cors'
	});
	if (!isOk(response))
	{
		throw await HttpError.create('GET', url, response);
	}
	return jsonImport(await response.text()) as Log[];
}

export async function apiUndoLogs (upToAndIncludingLogId: bigint): Promise<UndoData>
{
	if (typeof upToAndIncludingLogId !== 'bigint' || upToAndIncludingLogId < 0n)
	{
		throw new RangeError('upToAndIncludingLogId must be a non-negative bigint');
	}
	const url = `/api/logs/${upToAndIncludingLogId}`;
	const response = await fetch(url, {
		method: 'DELETE'
	});
	if (!isOk(response))
	{
		throw await HttpError.create('DELETE', url, response);
	}
	return jsonImport(await response.text()) as UndoData;
}

export async function apiGetSiteData (): Promise<SiteData>
{
	const url = '/api/sites';
	const response = await fetch(url, {
		credentials: 'include',
		mode: 'no-cors'
	});
	if (!isOk(response))
	{
		throw await HttpError.create('GET', url, response);
	}
	return jsonImport(await response.text()) as SiteData;
}

export async function apiSetEntryOption<T extends Extract<keyof StoryData['options'], string>> (
	site: SiteId,
	storyId: string,
	optionKey: T,
	optionValue: StoryData['options'][T]
): Promise<void>
{
	if (typeof site !== 'string' || (site as any) === '')
	{
		throw new RangeError('site must be a non-empty string');
	}
	else if (typeof storyId !== 'string' || storyId === '')
	{
		throw new RangeError('storyId must be a non-empty string');
	}
	else if (typeof optionKey !== 'string' || (optionKey as any) === '')
	{
		throw new RangeError('optionKey must be a non-empty string');
	}
	const url = `/api/story/${encodeURIComponent(site)}/${encodeURIComponent(storyId)}/option/${encodeURIComponent(optionKey)}`;
	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: jsonExport(optionValue)
	});
	if (!isOk(response, true))
	{
		throw await HttpError.create('POST', url, response);
	}
}

export async function apiGetUserSettings (): Promise<UserSettings>
{
	const url = '/api/user-settings';
	const response = await fetch(url, {
		credentials: 'include',
		mode: 'no-cors'
	});
	if (!isOk(response))
	{
		throw await HttpError.create('GET', url, response);
	}
	return jsonImport(await response.text()) as UserSettings;
}

export async function apiSetUserSetting<T extends Extract<Exclude<keyof UserSettings, 'userId'>, string>> (
	settingId: T,
	settingValue: UserSettings[T]
): Promise<void>
{
	if (typeof settingId !== 'string' || (settingId as any) === '')
	{
		throw new RangeError('settingId must be a non-empty string');
	}
	const url = `/api/user-settings/${encodeURIComponent(settingId)}`;
	const response = await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: jsonExport(settingValue)
	});
	if (!isOk(response, true))
	{
		throw await HttpError.create('POST', url, response);
	}
}

export async function apiPurgeData (): Promise<void>
{
	const url = '/api/purge';
	const response = await fetch(url, {
		method: 'DELETE'
	});
	if (!isOk(response, true))
	{
		throw await HttpError.create('DELETE', url, response);
	}
}

export async function apiAddTagToEntry (
	site: SiteId,
	storyId: string,
	tag: string
): Promise<void>
{
	const url = `/api/story/${encodeURIComponent(site)}/${encodeURIComponent(storyId)}/tag/${encodeURIComponent(tag)}`;
	const response = await fetch(url, {
		method: 'PUT'
	});
	if (!isOk(response, true))
	{
		throw await HttpError.create('PUT', url, response);
	}
}

export async function apiRemoveTagFromEntry (
	site: SiteId,
	storyId: string,
	tag: string
): Promise<void>
{
	const url = `/api/story/${encodeURIComponent(site)}/${encodeURIComponent(storyId)}/tag/${encodeURIComponent(tag)}`;
	const response = await fetch(url, {
		method: 'DELETE'
	});
	if (!isOk(response, true))
	{
		throw await HttpError.create('DELETE', url, response);
	}
}

export async function apiAcknowledgeStoryDeletion
(
	site: SiteId,
	storyId: string
): Promise<void>
{
	const url = `/api/story/${encodeURIComponent(site)}/${encodeURIComponent(storyId)}/acknowledge-deletion`;
	const response = await fetch(url, {
		method: 'POST'
	});
	if (!isOk(response, true))
	{
		throw await HttpError.create('POST', url, response);
	}
}
