<script context="module" lang="ts">
	export type IconComponent = ComponentType<SvelteComponentTyped<{ alt: string }>>;
</script>

<script lang="ts">
	import { createEventDispatcher, type ComponentType, type SvelteComponentTyped, onDestroy } from 'svelte';

	export let icon: IconComponent;
	export let alt: string;
	export let classes: Readonly<Record<string, boolean>> = {};
	export let disabled = false;
	export let type: HTMLButtonElement['type'] = 'button';

	const dispatcher = createEventDispatcher<{
		click: {};
	}>();

	$: allowedClasses = Object.entries(classes).filter(([, allowed]) => allowed).map(([className]) => className);
</script>

<button
	type={type}
	title={alt}
	class={allowedClasses.join(' ')}
	disabled={disabled}
	on:click={() => dispatcher('click', {})}>
	<svelte:component this={icon} alt={alt} />
</button>
