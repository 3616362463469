<script context="module" lang="ts">
    import type { TagState } from './ReadingListFilters.svelte';

	export type FilterInteractions = Readable<{
		readonly usingComplexTagFiltering: Readable<boolean>;
		readonly addRequiredTag: (tag: string) => void;
		readonly removeTagFromFilter: (tag: string) => void;
		readonly tagStateMap: Readable<ReadonlyMap<string, TagState>>;
	} | undefined>;
</script>

<script lang="ts">
	import type { SiteData, StoryData } from '../../../shared/common';
	import type { Log, ReadingList, Site, SiteId, UserSettings } from '../../../shared/database';
	import ReadingProgress, { type EventArgsMap } from './ReadingProgress.svelte';
	import ExternalLink from './input/ExternalLink.svelte';
	import SiteIcon from './SiteIcon.svelte';
	import { ApiError, apiAddTagToEntry, apiDeleteStoryFromReadingList, apiMoveStoryToReadingList, apiRemoveTagFromEntry, apiSetEntryOption } from '../api';
	import { createEventDispatcher, onDestroy } from 'svelte';
	import { createErrorDialog, createGlobalDialog } from '../dialogs';
	import ConjunctionList from './layout/ConjunctionList.svelte';
	import { getStoryIndexUrl, getStoryTitle } from '../settings';
	import { derived, writable, type Readable, type Writable, get } from 'svelte/store';
	import { isReadingListData, type PartialReadingListData } from './ReadingListSwitcher.svelte';
	import { allStoryStatuses, globalDisable, reactiveCssVariable, reactiveMatchMedia, showNsfwContent, useMobileLayout } from '../state';
	import { getStoryKey } from '../keying';
	import IconButton from './input/IconButton.svelte';
	import IconTrashCan from './svg-icons/IconTrashCan.svelte';
    import IconEdit from './svg-icons/IconEdit.svelte';
    import AutofillTextInput, { type SubmitEventArgs } from './input/AutofillTextInput.svelte';
    import { normalizeForComparison } from '../text';
    import { assertNever } from '../assertions';
    import type { TagSet } from '../tags';

	export let site: Site;
	export let allSites: SiteData['sites'];
	export let storyData: Writable<StoryData>;
	export let currentListId: bigint;
	export let allListData: Writable<Map<bigint, PartialReadingListData>>;
	export let allTags: Writable<TagSet>;
	export let userSettings: UserSettings;
	export let filterInteractions: FilterInteractions;
	export let isLastStory: boolean;

	$: usingComplexTagFiltering = $filterInteractions?.usingComplexTagFiltering;
	$: tagStateMap = $filterInteractions?.tagStateMap;
	$: storyDisable = $allListData.get(currentListId)?.storyDisablers.get(getStoryKey($storyData)) ?? globalDisable;

	const dispatcher = createEventDispatcher<EventArgsMap>();

	function disableFixedImgDimensions (event: Event): void
	{
		(event.target as HTMLImageElement).removeAttribute('width');
		(event.target as HTMLImageElement).removeAttribute('height');
	}

	let premiumChaptersReferenceElement: HTMLElement | undefined;

	async function setShowPremiumChapters (targetValue: boolean): Promise<void>
	{
		if ($storyData.options.optShowPremiumChapters !== targetValue)
		{
			await storyDisable.runAction(async () =>
			{
				await apiSetEntryOption(
					$storyData.story.site,
					$storyData.story.storyId,
					'optShowPremiumChapters',
					targetValue
				);
				storyData.update(storyData =>
				{
					storyData.options.optShowPremiumChapters = targetValue;
					return storyData;
				});
				if (premiumChaptersReferenceElement)
				{
					keepInView(premiumChaptersReferenceElement);
				}
			});
		}
	}

	async function setRolePresence (referenceElement: HTMLElement, roleId: string, targetValue: boolean)
	{
		const index = $storyData.options.optRoles.indexOf(roleId);
		if ((index !== -1) !== targetValue)
		{
			await storyDisable.runAction(async () =>
			{
				const optRoles = $storyData.options.optRoles.slice();
				if (targetValue)
				{
					optRoles.push(roleId);
				}
				else
				{
					optRoles.splice(index, 1);
				}
				await apiSetEntryOption($storyData.story.site, $storyData.story.storyId, 'optRoles', optRoles);
				storyData.update(storyData =>
				{
					storyData.options.optRoles = optRoles;
					return storyData;
				});
				keepInView(referenceElement);
			});
		}
	}

	async function setNsfw (targetValue: boolean): Promise<void>
	{
		if ($storyData.options.optNsfw !== targetValue)
		{
			await storyDisable.runAction(async () =>
			{
				await apiSetEntryOption(
					$storyData.story.site,
					$storyData.story.storyId,
					'optNsfw',
					targetValue
				);
				storyData.update(storyData =>
				{
					storyData.options.optNsfw = targetValue;
					return storyData;
				});
				if (targetValue && !$showNsfwContent)
				{
					createGlobalDialog(
						(parent, closeDialog) =>
						{
							const p = document.createElement('p');
							p.textContent = `"${$storyData.story.title}" is now hidden because your browser is configured to hide NSFW content. To show NSFW content, change the "NSFW Content" setting in the "Settings" tab.`;
							parent.appendChild(p);
							const div = document.createElement('div');
							div.className = 'p-like';
							const btnOk = document.createElement('button');
							btnOk.type = 'button';
							btnOk.textContent = 'OK';
							btnOk.addEventListener('click', closeDialog);
							div.appendChild(btnOk);
							const btnUndo = document.createElement('button');
							btnUndo.type = 'button';
							btnUndo.textContent = 'Undo';
							btnUndo.addEventListener('click', async () =>
							{
								try
								{
									btnOk.disabled = true;
									btnUndo.disabled = true;
									targetValue = false;
									await apiSetEntryOption(
										$storyData.story.site,
										$storyData.story.storyId,
										'optNsfw',
										targetValue
									);
									storyData.update(storyData =>
									{
										storyData.options.optNsfw = targetValue;
										return storyData;
									});
								}
								catch (e)
								{
									createErrorDialog(e instanceof Error ? e.message : String(e));
								}
								finally
								{
									closeDialog();

								}
							});
							div.appendChild(btnUndo);
							parent.appendChild(div);
						},
						true
					);
				}
			});
		}
	}

	let customTitleInput = $storyData.options.optCustomTitle ?? '';

	async function applyCustomTitle (): Promise<void>
	{
		const effectiveCustomTitle = customTitleInput.replace(/\s+/gu, ' ');
		const targetValue = effectiveCustomTitle === '' ? null : effectiveCustomTitle;
		if ($storyData.options.optCustomTitle !== targetValue)
		{
			await storyDisable.runAction(async () =>
			{
				await apiSetEntryOption($storyData.story.site, $storyData.story.storyId, 'optCustomTitle', targetValue);
				storyData.update(storyData =>
				{
					storyData.options.optCustomTitle = targetValue;
					return storyData;
				});
			});
		}
	}

	let customCoverArtUrlInput = $storyData.options.optCustomCoverArtUrl ?? '';

	async function applyCustomCoverArtUrl (): Promise<void>
	{
		const currentValue = $storyData.options.optCustomCoverArtUrl === null ? null : new URL($storyData.options.optCustomCoverArtUrl);
		let targetValue: URL | null;
		try
		{
			const effectiveCustomCoverArtUrl = customCoverArtUrlInput.trim();
			targetValue = effectiveCustomCoverArtUrl === '' ? null : new URL(effectiveCustomCoverArtUrl);
		}
		catch (e)
		{
			if (e instanceof TypeError)
			{
				createErrorDialog(`Invalid URL: ${customCoverArtUrlInput}`);
				return;
			}
			throw e;
		}
		if (currentValue?.href !== targetValue?.href)
		{
			await storyDisable.runAction(async () =>
			{
				const primitiveValue = targetValue ? targetValue.href : targetValue;
				await apiSetEntryOption($storyData.story.site, $storyData.story.storyId, 'optCustomCoverArtUrl', primitiveValue);
				storyData.update(storyData =>
				{
					storyData.options.optCustomCoverArtUrl = primitiveValue;
					return storyData;
				});
			});
		}
	}

	async function setCustomCoverArtUrlIsBanner (targetValue: boolean): Promise<void>
	{
		if ($storyData.options.optCustomCoverArtIsBanner !== targetValue)
		{
			await storyDisable.runAction(async () =>
			{
				await apiSetEntryOption($storyData.story.site, $storyData.story.storyId, 'optCustomCoverArtIsBanner', targetValue);
				storyData.update(storyData =>
				{
					storyData.options.optCustomCoverArtIsBanner = targetValue;
					return storyData;
				});
			});
		}
	}

	async function moveStory (event: Event & { currentTarget: EventTarget & HTMLSelectElement }): Promise<void>
	{
		const targetListId = BigInt(event.currentTarget.value);
		if (currentListId !== targetListId && $allListData.has(targetListId))
		{
			await storyDisable.runAction(async () =>
			{
				try
				{
					const log = await apiMoveStoryToReadingList(
						$storyData.story.site,
						$storyData.story.storyId,
						targetListId
					);
					allListData.update(map =>
					{
						const key = getStoryKey($storyData);
						map.get(currentListId)?.stories.delete(key);
						map.get(targetListId)?.stories.set(key, $storyData);
						return map;
					});
					if (log !== null)
					{
						dispatcher('logCreated', {
							log: log
						});
					}
				}
				catch (e)
				{
					if (!(e instanceof ApiError))
					{
						throw e;
					}
					createErrorDialog(e.message);
				}
			});
		}
	}

	async function deleteStory (): Promise<void>
	{
		await storyDisable.runAction(async () =>
		{
			try
			{
				const log = await apiDeleteStoryFromReadingList(
					$storyData.story.site,
					$storyData.story.storyId
				);
				allListData.update(map =>
				{
					map.get(currentListId)?.stories.delete(getStoryKey($storyData));
					return map;
				});
				dispatcher('logCreated', {
					log: log
				});
			}
			catch (e)
			{
				if (!(e instanceof ApiError))
				{
					throw e;
				}
				createErrorDialog(e.message);
			}
		});
	}

	$: sortedTags = $storyData.options.tags.slice().sort((a, b) => normalizeForComparison(a).localeCompare(normalizeForComparison(b)));

	async function addTag (event: CustomEvent<SubmitEventArgs>): Promise<void>
	{
		const cmpValue = normalizeForComparison(event.detail.value);
		const notPresent = $storyData.options.tags.every(tag => normalizeForComparison(tag) !== cmpValue);
		if (notPresent)
		{
			await storyDisable.runAction(async () =>
			{
				try
				{
					await apiAddTagToEntry($storyData.story.site, $storyData.story.storyId, event.detail.value);
					storyData.update(storyData =>
					{
						storyData.options.tags.push(event.detail.value);
						return storyData;
					});
					allTags.update(set =>
					{
						set.add(event.detail.value);
						return set;
					});
					event.detail.clear();
				}
				catch (e)
				{
					if (!(e instanceof ApiError))
					{
						throw e;
					}
					createErrorDialog(e.message);
				}
			});
		}
		else
		{
			createGlobalDialog(`"${getStoryTitle(userSettings, site, $storyData)}" is already tagged with "${event.detail.value}"`);
		}
	}

	async function removeTag (tag: string): Promise<void>
	{
		const tagIndex = $storyData.options.tags.indexOf(tag);
		if (tagIndex === -1)
		{
			throw new Error(`Attempt to remove tag "${tag}" from story that does not have said tag`);
		}

		await storyDisable.runAction(async () =>
		{
			try
			{
				await apiRemoveTagFromEntry($storyData.story.site, $storyData.story.storyId, tag);
				storyData.update(storyData =>
				{
					storyData.options.tags.splice(tagIndex, 1);
					return storyData;
				});
			}
			catch (e)
			{
				if (!(e instanceof ApiError))
				{
					throw e;
				}
				createErrorDialog(e.message);
			}
		});
	}

	function filterByTag (tag: string): void
	{
		if ($usingComplexTagFiltering)
		{
			return;
		}
		const state = $tagStateMap?.get(tag);
		if (state === undefined)
		{
			return;
		}
		switch (state)
		{
			case null:
				$filterInteractions?.addRequiredTag(tag);
				break;

			case 'required':
			case 'forbidden':
				$filterInteractions?.removeTagFromFilter(tag);
				break;

			default:
				assertNever(state, `Unknown TagState "${state}"`);
		}
	}

	async function filterByOrRemoveTag (tag: string): Promise<void>
	{
		if ($allowEdits)
		{
			return await removeTag(tag);
		}
		filterByTag(tag);
	}

	const allowEdits = writable(false);

	function keepInView (referenceElement: HTMLElement): void
	{
		const targetRelativeY = referenceElement.getBoundingClientRect().top;
		requestAnimationFrame(() =>
		{
			const absoluteY = referenceElement!.getBoundingClientRect().top + window.scrollY;
			window.scrollTo({
				top: absoluteY - targetRelativeY,
				behavior: 'instant'
			});
		});
	}

	$: coverArtUrl = $storyData.options.optCustomCoverArtUrl ?? $storyData.story.coverArtUrl ?? site.defaultCoverArtUrl;
	$: coverArtIsBanner =
		$storyData.options.optCustomCoverArtUrl !== null ? !!$storyData.options.optCustomCoverArtIsBanner :
		$storyData.story.coverArtUrl === null ? false :
		site.usesBannerCovers === 'yes' || (
			$storyData.story.coverArtUrl !== null &&
			site.usesBannerCovers !== 'no' &&
			$storyData.story.attrCoverArtIsBanner
		);
	$: displayedStatus = allStoryStatuses[$storyData.story.status];
	$: sortedAllTags = Array.from($allTags).sort((a, b) => normalizeForComparison(a).localeCompare(normalizeForComparison(b)));

	const smallCoverWidth = reactiveCssVariable('--small-cover-art-width');
	const smallCoverHeight = reactiveCssVariable('--small-cover-art-height');

	$: displayedTitle = getStoryTitle(userSettings, site, $storyData);
</script>

<div
	class:display-table-row={!$useMobileLayout && !coverArtIsBanner}
	class:float-container={!coverArtIsBanner}>
	<div
		class:display-table-cell={!$useMobileLayout && !coverArtIsBanner}
		class:p-like={coverArtIsBanner}
		class:floating={$useMobileLayout && !coverArtIsBanner}>
		{#if $storyData.deleted}
			<img
				class:normal-cover-art={!coverArtIsBanner}
				class:banner-cover-art={coverArtIsBanner}
				class:null-cover-art={!coverArtUrl}
				src={coverArtUrl ?? site.iconUrl ?? '/img/icons/circleQuestion.svg'}
				alt={`Cover art for ${displayedTitle}`}
				loading="lazy"
				width={coverArtIsBanner ? '100px' : $smallCoverWidth}
				height={coverArtIsBanner ? '100px' : $smallCoverHeight}
				on:load={disableFixedImgDimensions} />
		{:else}
			<ExternalLink href={getStoryIndexUrl(userSettings, site, $storyData.story)}>
				<img
					class:normal-cover-art={!coverArtIsBanner}
					class:banner-cover-art={coverArtIsBanner}
					class:null-cover-art={!coverArtUrl}
					src={coverArtUrl ?? site.iconUrl ?? '/img/icons/circleQuestion.svg'}
					alt={`Cover art for ${displayedTitle}`}
					loading="lazy"
					width={coverArtIsBanner ? '100px' : $smallCoverWidth}
					height={coverArtIsBanner ? '100px' : $smallCoverHeight}
					on:load={disableFixedImgDimensions} />
			</ExternalLink>
		{/if}
	</div>
	<div
		class:display-table-cell={!$useMobileLayout}
		class:shift-over={!$useMobileLayout && coverArtIsBanner}>
		<h2>
			{#if coverArtUrl}
				<span>
					<SiteIcon
						site={site}
						allSites={allSites}
						userSettings={userSettings}
						alternateAvailability={$storyData.story.attrAlternateAvailability}
						link={false} />
				</span>
			{/if}
			<span>
				{#if $storyData.deleted}
					{displayedTitle}
				{:else}
					<ExternalLink href={getStoryIndexUrl(userSettings, site, $storyData.story)}>
						{displayedTitle}
					</ExternalLink>
				{/if}
			</span>
		</h2>

		<div class="reactive-p" aria-roledescription="paragraph">
			{#if $storyData.authors.length === 0}
				[no authors]
			{:else}
				by
				<ConjunctionList>
					{#each $storyData.authors as author (author.authorId)}
						<li>
							{#if author.url !== null}
								<ExternalLink href={author.url}>{author.name}</ExternalLink>
							{:else}
								{author.name}
							{/if}
						</li>
					{/each}
				</ConjunctionList>
			{/if}
		</div>

		{#if $storyData.deleted}
			<p class="reactive-p"><strong class="warning">DELETED</strong></p>
		{:else if $storyData.story.status !== 'unknown'}
			<p class="reactive-p">{displayedStatus}</p>
		{/if}

		{#if $storyData.story.attrHasTooManyChaptersBug}
			<p class="reactive-p">
				<strong class="warning">WARNING:</strong>
				This story is in a degraded state because of a bug with {site.name} where, past a
				certain number of chapters, new chapters stop showing up in the story's Table of Contents.
				Please manually verify if a new chapter has been posted. This warning will be removed when
				{site.name} fixes the bug.
			</p>
		{/if}

		{#if sortedTags.length !== 0}
			<div class="reactive-p">
				<ul class="inline-list">
					{#each sortedTags as tag}
						{@const state = $tagStateMap?.get(tag)}
						<li>
							<button
								type="button"
								class:secondary={(!$allowEdits && state == null) || $usingComplexTagFiltering}
								class:required={!$allowEdits && state === 'required' && !$usingComplexTagFiltering}
								class:warning={$allowEdits}
								disabled={$globalDisable || $storyDisable || (!$allowEdits && $usingComplexTagFiltering)}
								on:click={() => filterByOrRemoveTag(tag)}>
								{tag}
								{#if $allowEdits}
									<IconTrashCan alt={`Remove tag "${tag}"`} style="display: inline;" />
								{/if}
							</button>
						</li>
					{/each}
				</ul>
			</div>
		{/if}

		{#if $allowEdits}
			<div class="reactive-p" class:clear={!coverArtIsBanner}>
				<AutofillTextInput
					options={sortedAllTags}
					placeholder="Add tag..."
					allowUnmatched={true}
					disabled={$globalDisable || $storyDisable}
					on:submit={addTag} />
			</div>
		{/if}

		<div class:clear={!coverArtIsBanner}>
			<ReadingProgress
				site={site}
				storyData={storyData}
				storyDisable={storyDisable}
				userSettings={userSettings}
				allowEdits={allowEdits}
				isLastStory={isLastStory}
				on:logCreated />
			{#if $allowEdits}
				{#if site.usesPremiumChapters && $storyData.chapters.some(chapterData => chapterData.chapter.attrIsPremium)}
					<div class="reactive-p" class:shift-over={coverArtIsBanner} bind:this={premiumChaptersReferenceElement}>
						<label>
							<input
								type="checkbox"
								disabled={$globalDisable || $storyDisable}
								checked={$storyData.options.optShowPremiumChapters}
								on:change={event => setShowPremiumChapters(event.currentTarget.checked)} />
							Show Premium Chapters
						</label>
					</div>
					{#if $storyData.options.optShowPremiumChapters && $storyData.chapters.some(chapterData => chapterData.chapter.attrRequiredRoleId !== null)}
						{#if $storyData.roles.length !== 0}
							<dl class="reactive-p" class:shift-over={coverArtIsBanner} class:dl-grid={!$useMobileLayout}>
								{#each $storyData.roles as role (role.roleId)}
									{@const hasRole = $storyData.options.optRoles.includes(role.roleId)}
									<dt>
										{#if role.url !== null}
											<ExternalLink href={role.url}>{role.name}</ExternalLink>
										{:else}
											{role.name}
										{/if}
									</dt>
									<dd>
										<label>
											<input
												type="checkbox"
												disabled={$globalDisable || $storyDisable}
												checked={hasRole}
												on:change={event => setRolePresence(event.currentTarget, role.roleId, event.currentTarget.checked)} />
											{hasRole ? 'Enabled' : 'Disabled'}
										</label>
									</dd>
								{/each}
							</dl>
						{:else}
							<p class="reactive-p" class:shift-over={coverArtIsBanner}><span class="warning">ERROR:</span> Story has role requirements but no Role objects were found.</p>
						{/if}
					{/if}
				{/if}

				<div class="reactive-p" class:shift-over={coverArtIsBanner}>
					<label>
						<input type="checkbox"
							disabled={$globalDisable || $storyDisable}
							checked={$storyData.options.optNsfw ?? $storyData.story.isNsfw}
							on:change={event => setNsfw(event.currentTarget.checked)} />
						Is NSFW
					</label>
				</div>

				<div class="reactive-p" class:shift-over={coverArtIsBanner}>
					<input
						type="text"
						placeholder="Custom title..."
						disabled={$globalDisable || $storyDisable}
						bind:value={customTitleInput} />
					<IconButton
						icon={IconEdit}
						alt="Apply custom title"
						disabled={$globalDisable || $storyDisable}
						on:click={applyCustomTitle} />
				</div>

				<div class="reactive-p" class:shift-over={coverArtIsBanner}>
					<input
						type="url"
						placeholder="Custom cover art URL..."
						disabled={$globalDisable || $storyDisable}
						bind:value={customCoverArtUrlInput} />
					<IconButton
						icon={IconEdit}
						alt="Apply custom cover art URL"
						disabled={$globalDisable || $storyDisable}
						on:click={applyCustomCoverArtUrl} />
				</div>

				<div class="reactive-p">
					<label>
						<input
							type="checkbox"
							checked={!!$storyData.options.optCustomCoverArtIsBanner}
							disabled={$globalDisable || $storyDisable}
							on:change={event => setCustomCoverArtUrlIsBanner(event.currentTarget.checked)} />
						Custom Cover Art is a Banner
					</label>
				</div>

				{#if $allListData.size >= 2}
					{@const allReadingLists = Array.from($allListData.values()).
						filter(isReadingListData).
						map(listData => listData.readingList)}
					<div class="reactive-p" class:shift-over={coverArtIsBanner}>
						<label>
							Move to:
							<select disabled={$globalDisable || $storyDisable} on:change={moveStory}>
								{#each allReadingLists as readingList (readingList.listId)}
									<option
										selected={readingList.listId === currentListId}
										disabled={readingList.listId === currentListId}
										hidden={readingList.listId === currentListId}
										value={readingList.listId}>
										{readingList.name}
									</option>
								{/each}
							</select>
						</label>
					</div>
				{/if}

				<div class="reactive-p" class:shift-over={coverArtIsBanner} class:final-div={isLastStory}>
					<IconButton
						icon={IconTrashCan}
						alt="Delete Story from Reading List"
						classes={{
							warning: true
						}}
						disabled={$globalDisable || $storyDisable}
						on:click={deleteStory} />
				</div>
			{/if}
		</div>
	</div>
</div>

<style lang="scss">
	h2
	{
		margin-top: 0;
	}

	h2 > span
	{
		vertical-align: top;
	}

	.final-div
	{
		margin-bottom: 0;
	}

	.normal-cover-art
	{
		max-width: var(--small-cover-art-width);
		max-height: var(--small-cover-art-height);
		margin-right: var(--margin-medium);
		margin-bottom: var(--margin-medium);
	}

	.banner-cover-art
	{
		max-width: 100%;
		background-color: var(--banner-cover-bg-color);
	}

	.null-cover-art
	{
		min-width: var(--small-cover-art-width);
	}

	.shift-over
	{
		margin-left: calc(var(--small-cover-art-width) + var(--margin-medium));
	}

	.floating
	{
		float: left;
	}

	.reactive-p
	{
		margin: var(--margin-large) 0;
	}

	@media screen and (pointer: none), screen and (pointer: coarse), screen and (max-width: 720px)
	{
		h2
		{
			margin-bottom: var(--margin-medium);
		}

		.float-container
		{
			display: flow-root;
		}

		.shift-over
		{
			margin-left: 0;
		}

		.clear
		{
			clear: both;
		}

		.reactive-p
		{
			margin: var(--margin-medium) 0;
		}
	}
</style>
