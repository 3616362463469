<script lang="ts">
    import type { SiteData } from '../../../shared/common';
	import type { AlternateAvailability, Site, SiteId, UserSettings } from '../../../shared/database';
	import { effectiveTheme, type Theme } from '../state';
	import { getSiteHomeUrl, getSiteIconUrl, getSiteName } from '../settings';
	import ExternalLink from './input/ExternalLink.svelte';

	export let site: Site;
	export let allSites: SiteData['sites'];
	export let userSettings: UserSettings | null;
	export let alternateAvailability: readonly AlternateAvailability[];
	export let link = true;
	export let forceInvert: boolean | undefined = undefined;

	const invertMap: Partial<Readonly<Record<SiteId, NonNullable<Theme>>>> = {
		// 'scribble-hub': 'dark'
	};

	$: shouldInvertIcon = forceInvert ?? (site.iconUrl === null || (
		Object.hasOwn(invertMap, site.site) && $effectiveTheme === invertMap[site.site]
	));

	function disableFixedImgDimensions (event: Event): void
	{
		(event.target as HTMLImageElement).removeAttribute('width');
		(event.target as HTMLImageElement).removeAttribute('height');
	}

	$: siteName = userSettings === null ? site.name : getSiteName(userSettings, site, allSites, alternateAvailability);
	$: iconUrl = site.iconUrl == null ? '/img/icons/circleQuestion.svg' :
		userSettings === null ? site.iconUrl :
		getSiteIconUrl(userSettings, site, allSites, alternateAvailability);
</script>

{#if link}
	{@const homeUrl = userSettings === null ? site.homeUrl : getSiteHomeUrl(userSettings, site, allSites, alternateAvailability)}
	<ExternalLink href={homeUrl}>
		<img
			class="site-icon"
			class:invert={shouldInvertIcon}
			src={iconUrl}
			alt={siteName}
			title={siteName}
			loading="lazy"
			width="24"
			on:load={disableFixedImgDimensions} />
	</ExternalLink>
{:else}
	<img
		class="site-icon"
		class:invert={shouldInvertIcon}
		src={iconUrl}
		alt={siteName}
		title={siteName}
		loading="lazy"
		width="24"
		on:load={disableFixedImgDimensions} />
{/if}

<style lang="scss">
	img
	{
		vertical-align: middle;
	}

	.site-icon
	{
		width: calc(24px * var(--size-multiplier));
		height: calc(24px * var(--size-multiplier));
	}

	.invert
	{
		filter: invert(100%);
	}
</style>
