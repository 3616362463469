export function createDateString (timestamp: bigint): string
{
	const date = new Date(Number(timestamp));
	const year = date.getFullYear().toString().padStart(4, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	return `${year}-${month}-${day}`;
}

function getMonthDiff (left: Date, right: Date): number
{
	// Credit to https://stackoverflow.com/a/2536445
	let res = 12 * (left.getFullYear() - right.getFullYear());
	res -= right.getMonth();
	res += left.getMonth();
	return res;
}

export function toRelativeTimeString (timestamp: bigint): string
{
	const now = new Date();
	const date = new Date(Number(timestamp));

	let diff = Math.floor((now.valueOf() - date.valueOf()) / 60000);
	if (diff < 1)
	{
		return 'just now';
	}
	else if (diff < 60)
	{
		let msg = `${diff.toString()} minute`;
		if (diff !== 1)
		{
			msg += 's';
		}
		msg += ' ago';
		return msg;
	}
	diff = Math.floor(diff / 60);
	if (diff < 24)
	{
		let msg = `${diff.toString()} hour`;
		if (diff !== 1)
		{
			msg += 's';
		}
		msg += ' ago';
		return msg;
	}
	diff = Math.floor(diff / 24);
	if (diff <= 28 || (now.getMonth() === date.getMonth() && now.getFullYear() === date.getFullYear()))
	{
		let msg = `${diff.toString()} day`;
		if (diff !== 1)
		{
			msg += 's';
		}
		msg += ' ago';
		return msg;
	}

	// Months and years can be weird, so don't just keep coverting diff into new units
	const monthDiff = Math.max(1, getMonthDiff(now, date));
	if (monthDiff < 12)
	{
		let msg = `${monthDiff.toString()} month`;
		if (monthDiff !== 1)
		{
			msg += 's';
		}
		msg += ' ago';
		return msg;
	}
	const yearDiff = Math.max(1, Math.floor(monthDiff / 12));
	let msg = `${yearDiff.toString()} year`;
	if (yearDiff !== 1)
	{
		msg += 's';
	}
	msg += ' ago';
	return msg;
}
