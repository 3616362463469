<ul class="inline-list conjunction-list">
	<slot />
</ul>

<style lang="scss">
	.conjunction-list > :global(li)::after
	{
		content: ', ';
	}

	.conjunction-list > :global(li):last-child::after,
	.conjunction-list > :global(li):first-child:last-child::after
	{
		content: none;
	}

	.conjunction-list > :global(li):nth-last-child(2)::after
	{
		content: ', and ';
	}

	.conjunction-list > :global(li):first-child:nth-last-child(2)::after
	{
		content: ' and ';
	}
</style>
