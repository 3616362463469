<script context="module" lang="ts">
	export type ThreeStateButtonState = 'off' | 'on-yes' | 'on-no';
</script>

<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import { assertNever } from '../../assertions';
    import { createErrorDialog } from '../../dialogs';
    import IconSquarePlus from '../svg-icons/IconSquarePlus.svelte';
    import IconSquareMinus from '../svg-icons/IconSquareMinus.svelte';
    import IconSquareOutline from '../svg-icons/IconSquareOutline.svelte';
    import type { IconComponent } from './IconButton.svelte';
    import type { SiteId } from '../../../../shared/database';
    import SiteIcon from '../SiteIcon.svelte';
    import type { SiteData } from '../../../../shared/common';

	export let alt: string;
	export let disabled = false;
	export let type: HTMLButtonElement['type'] = 'button';
	export let state: ThreeStateButtonState = 'off';
	export let icon: IconComponent | SiteId | undefined = undefined;
	export let allSites: SiteData['sites'] | undefined = undefined;

	const dispatcher = createEventDispatcher<{
		change: {
			state: ThreeStateButtonState;
		};
	}>();

	function updateState (): void
	{
		switch (state)
		{
			case 'off':
				state = 'on-yes';
				break;

			case 'on-yes':
				state = 'on-no';
				break;

			case 'on-no':
				state = 'off';
				break;

			default:
				assertNever(state, `Unknown ThreeStateButton state "${state}"`);
		}
		dispatcher('change', {
			state: state
		});
	}
</script>

<button
	type={type}
	title={alt}
	class="display-inline-table"
	class:secondary={state === 'off'}
	class:off={state === 'off'}
	class:required={state === 'on-yes'}
	class:forbidden={state === 'on-no'}
	disabled={disabled}
	on:click={updateState}>
	<div class="display-table-row">
		<div class="display-table-cell three-state-icon-wrapper">
			{#if icon}
				{#if typeof icon === 'string'}
					{#if allSites !== undefined}
						<SiteIcon
							site={allSites[icon]}
							allSites={allSites}
							userSettings={null}
							alternateAvailability={[]}
							link={false} />
					{:else}
						{@const _ = void createErrorDialog(`Prop "allSites" must be defined if prop "icon" is a SiteId`)}
						{''}
					{/if}
				{:else}
					<svelte:component this={icon} alt={alt} />
				{/if}
			{:else if state === 'off'}
				<IconSquareOutline alt={alt} />
			{:else if state === 'on-yes'}
				<IconSquarePlus alt={alt} />
			{:else if state === 'on-no'}
				<IconSquareMinus alt={alt} />
			{:else}
				{@const _ = void createErrorDialog(`Unknown ThreeStateButton state "${state}"`)}
				{''}
			{/if}
		</div>
		<div class="display-table-cell">
			<slot />
		</div>
	</div>
</button>

<style lang="scss">
	@mixin three-state-dark-mode
	{
		--button-three-state-off-text-color: #D1D1D1;
		--button-three-state-off-bg-color: #181818;
		--button-three-state-off-border-color: #808080;
		--button-three-state-off-hover-bg-color: #393939;
		--button-three-state-off-hover-border-color: #2E2E2E;
	}

	:root,
	:root.light
	{
		--button-three-state-off-text-color: #333;
		--button-three-state-off-bg-color: white;
		--button-three-state-off-border-color: #E1E5EC;
		--button-three-state-off-hover-bg-color: white;
		--button-three-state-off-hover-border-color: #CCC;
	}

	@media screen and (prefers-color-scheme: dark)
	{
		:root
		{
			@include three-state-dark-mode();
		}
	}

	:root.dark
	{
		@include three-state-dark-mode();
	}

	.display-table-cell
	{
		padding: calc(5px * var(--size-multiplier)) calc(10px * var(--size-multiplier));
		vertical-align: middle;
	}

	.three-state-icon-wrapper > :global(svg)
	{
		margin-top: -2px;
	}

	button
	{
		border-width: 1px;
		border-style: solid;
		padding: 0;
	}

	button.off
	{
		color: var(--button-three-state-off-text-color);
		background-color: var(--button-three-state-off-bg-color);
		border: 1px solid var(--button-three-state-off-border-color);
	}

	button.off:hover,
	button.off:active
	{
		background-color: var(--button-three-state-off-hover-bg-color);
		border-color: var(--button-three-state-off-hover-border-color);
	}
</style>
