import { assertNever } from './assertions';
import { TagSet } from './tags';
import { normalizeForComparison } from './text';

export interface LangError
{
	message: string;
	startOffset: number;
	endOffset: number;
}

export class Token
{
	constructor (
		readonly kind: 'keyword' | 'whitespace' | 'bare-word' | 'string' | 'symbol',
		readonly value: string,
		readonly startOffset: number,
		readonly startLine: number,
		readonly startColumn: number,
		readonly endOffset: number,
		readonly endLine: number,
		readonly endColumn: number,
		readonly rawValue: string = value
	)
	{}

	toString (): string
	{
		return `<${this.kind}> ${JSON.stringify(this.value)}`;
	}
}

const bareWordCharPartialPattern = '[^\\s"\\(\\)\\\\]';
const bareWordCharPattern = new RegExp(`^${bareWordCharPartialPattern}$`, 'u');

export class Lexer
{
	#offset = 0;
	#line = 1;
	#column = 1;
	readonly #errors: LangError[] = [];
	readonly #tokens: Token[] = [];
	readonly #code: readonly string[];

	constructor (code: string)
	{
		this.#code = code.split('');
	}

	#peek (): string | undefined
	{
		return this.#code[this.#offset];
	}

	#consume (): string | undefined
	{
		const char = this.#code[this.#offset++];
		if (char !== undefined)
		{
			if (char === '\n')
			{
				this.#line++;
				this.#column = 1;
			}
			else if (char === '\t')
			{
				this.#column += 4;
			}
			else
			{
				this.#column++;
			}
		}
		return char;
	}

	#consumeWhile (predicate: (char: string) => boolean): string
	{
		let res = '';
		let char = this.#peek();
		while (char !== undefined && predicate(char))
		{
			res += char;
			this.#consume();
			char = this.#peek();
		}
		return res;
	}

	#prevTokenAsString (): string
	{
		if (this.#tokens.length === 0)
		{
			return '<start of file>';
		}
		return this.#tokens[this.#tokens.length - 1].toString();
	}

	#peekAsString (): string
	{
		return this.#peek() ?? '<end of file>';
	}

	#whitespace (): string
	{
		return this.#consumeWhile(char => /^\s$/u.test(char));
	}

	#bareWord (): string | null
	{
		const word = this.#consumeWhile(char => bareWordCharPattern.test(char));
		if (word === '')
		{
			if (this.#peek() !== undefined)
			{
				this.#errors.push({
					message: `Expected keyword or bare word after ${this.#prevTokenAsString()} but found "${this.#peekAsString()}"`,
					startOffset: this.#offset,
					endOffset: this.#offset + 1
				});
			}
			return null;
		}
		return word;
	}

	#nextToken (): void
	{
		let startOffset = this.#offset;
		let startLine = this.#line;
		let startColumn = this.#column;

		const whitespace = this.#whitespace();
		if (whitespace !== '')
		{
			this.#tokens.push(new Token(
				'whitespace',
				whitespace,
				startOffset,
				startLine,
				startColumn,
				this.#offset,
				this.#line,
				this.#column
			));
			startOffset = this.#offset;
			startLine = this.#line;
			startColumn = this.#column;
		}

		const first = this.#peek();
		if (first === '(' || first === ')')
		{
			this.#consume();
			this.#tokens.push(new Token(
				'symbol',
				first,
				startOffset,
				startLine,
				startColumn,
				this.#offset,
				this.#line,
				this.#column
			));
		}
		else if (first === '"')
		{
			const startOffset = this.#offset;
			this.#consume();
			let rawValue = '"';
			let contents = '';

			let next = this.#peek();
			let escapeNext = false;
			while (next !== undefined)
			{
				rawValue += next;
				this.#consume();
				if (escapeNext)
				{
					contents += next;
					escapeNext = false;
				}
				else if (next === '"')
				{
					break;
				}
				else if (next === '\\')
				{
					escapeNext = true;
				}
				else
				{
					contents += next;
				}
				next = this.#peek();
			}

			if (next === undefined || escapeNext)
			{
				this.#errors.push({
					message: '<end of file> encountered before string terminator (")',
					startOffset: startOffset,
					endOffset: this.#offset
				});
			}
			this.#tokens.push(new Token(
				'string',
				contents,
				startOffset,
				startLine,
				startColumn,
				this.#offset,
				this.#line,
				this.#column,
				rawValue
			));
		}
		else
		{
			const word = this.#bareWord();
			if (word !== null)
			{
				const lowerWord = word.toLowerCase();
				const isKeyword = lowerWord === 'not' || lowerWord === 'and' || lowerWord === 'or';
				this.#tokens.push(new Token(
					isKeyword ? 'keyword' : 'bare-word',
					isKeyword ? lowerWord : word,
					startOffset,
					startLine,
					startColumn,
					this.#offset,
					this.#line,
					this.#column,
					word
				));
			}
			else
			{
				// Prevent getting stuck in an infinite loop
				this.#consume();
			}
		}
	}

	lex (): { tokens: Token[]; errors: LangError[] }
	{
		while (this.#peek() !== undefined)
		{
			this.#nextToken();
		}
		return {
			tokens: this.#tokens,
			errors: this.#errors
		};
	}
}

export interface NullExpression
{
	readonly kind: 'null';
}

export interface TagExpression
{
	readonly kind: 'tag';
	readonly value: string;
}

export interface UnaryExpression
{
	readonly kind: 'unary';
	readonly operator: 'not';
	readonly child: Expression;
}

export interface BinaryExpression
{
	readonly kind: 'binary';
	readonly operator: 'and' | 'or';
	readonly left: Expression;
	readonly right: Expression;
}

export type Expression = NullExpression | TagExpression | UnaryExpression | BinaryExpression;

export function normalizeTag (tag: string): string
{
	return normalizeForComparison(tag.trim().replace(/\s+/gu, ' '));
}

// const word = this.#consumeWhile(char => /^[^\s"\\\(\)]$/u.test(char));
// const bareWordCharPartialPattern = '[^\\s"\\(\\)\\\\]';

const bareWordPattern = new RegExp(`^${bareWordCharPartialPattern}+(?:\\s+${bareWordCharPartialPattern}+)*$`, 'u');

export function tagCanBeBareWord (tag: string): boolean
{
	tag = tag.toLowerCase().trim();
	return bareWordPattern.test(tag) && tag !== 'and' && tag !== 'or' && tag !== 'not';
}

export class Parser
{
	#index = 0;
	readonly #errors: LangError[] = [];
	readonly #tokens: readonly Token[];

	constructor (tokens: readonly Token[])
	{
		if (tokens.length === 0)
		{
			throw new RangeError('Parser cannot parse an empty token array');
		}
		this.#tokens = tokens;
	}

	#currentToken (): Token | undefined
	{
		return this.#tokens[this.#index];
	}

	#whitespace (): boolean
	{
		let res = false;
		while (this.#index < this.#tokens.length && this.#tokens[this.#index].kind === 'whitespace')
		{
			this.#index++;
			res = true;
		}
		return res;
	}

	#groupExpression (logErrors: boolean): Expression
	{
		const next = this.#currentToken();
		if (!next)
		{
			if (logErrors)
			{
				const prevToken = this.#tokens[this.#tokens.length - 1];
				this.#errors.push({
					message: 'Unexpected end of file (expected <whitespace>, <bare-word>, <string>, <keyword> "not", or <symbol> "(")',
					startOffset: prevToken.startOffset,
					endOffset: prevToken.endOffset
				});
			}
			return {
				kind: 'null'
			};
		}
		this.#index++;
		if (next.kind === 'symbol')
		{
			switch (next.value)
			{
				case '(':
				{
					const numErrors = this.#errors.length;
					const child = this.#expression(logErrors);
					if (numErrors !== this.#errors.length)
					{
						logErrors = false;
					}
					const terminator = this.#currentToken();
					if (!terminator)
					{
						if (logErrors)
						{
							const prevToken = this.#tokens[this.#tokens.length - 1];
							this.#errors.push({
								message: 'Unexpected end of file (expected <whitespace>, <keyword> "and", <keyword> "or", or <symbol> ")")',
								startOffset: prevToken.startOffset,
								endOffset: prevToken.endOffset
							});
						}
					}
					else if (terminator.kind !== 'symbol' || terminator.value !== ')')
					{
						if (logErrors)
						{
							this.#errors.push({
								message: `Unexpected token ${terminator.toString()} (expected <whitespace>, <keyword> "and", <keyword> "or", or <symbol> ")")`,
								startOffset: terminator.startOffset,
								endOffset: terminator.endOffset
							});
						}
					}
					else
					{
						this.#index++;
					}
					return child;
				}

				default:
				{
					if (logErrors)
					{
						this.#errors.push({
							message: `Unexpected token ${next.toString()} (expected <whitespace>, <bare-word>, <string>, <keyword> "not", or <symbol> "(")`,
							startOffset: next.startOffset,
							endOffset: next.endOffset
						});
					}
					return this.#groupExpression(false);
				}
			}
		}
		else if (next.kind === 'string')
		{
			return {
				kind: 'tag',
				value: normalizeTag(next.value)
			};
		}
		else if (next.kind === 'bare-word')
		{
			let value = next.value;
			let i = this.#index;
			while (i < this.#tokens.length && this.#tokens[i].kind === 'whitespace')
			{
				i++;
				if (i >= this.#tokens.length || this.#tokens[i].kind !== 'bare-word')
				{
					break;
				}
				value += ' ' + this.#tokens[i].value;
				i++;
				this.#index = i;
			}
			return {
				kind: 'tag',
				value: normalizeTag(value)
			};
		}
		else if (next.kind === 'keyword')
		{
			if (logErrors)
			{
				this.#errors.push({
					message: `Unexpected token ${next.toString()} (expected <whitespace>, <bare-word>, <string>, <keyword> "not", or <symbol> "(")`,
					startOffset: next.startOffset,
					endOffset: next.endOffset
				});
			}
			return {
				kind: 'null'
			};
		}
		let msg = `Parser design error: Unexpected token ${next.toString()} in GroupExpression position`;
		msg += `\nIndex = ${this.#index - 1}\nToken = ${next.toString()}`;
		msg += `\nAll Tokens:\n${this.#tokens.map((token, index) => `${index}: ${token.toString()}`).join('\n')}`;
		throw new Error(msg);
	}

	#unaryExpression (logErrors: boolean): Expression
	{
		const operatorToken = this.#currentToken();
		const operator = operatorToken?.value;
		if (
			!operatorToken ||
			!operator ||
			operatorToken.kind !== 'keyword' ||
			operator !== 'not'
		)
		{
			return this.#groupExpression(logErrors);
		}
		this.#index++;
		const seenWhitespace = this.#whitespace();
		if (!seenWhitespace && logErrors)
		{
			const token = this.#currentToken();
			const locationToken = token ?? operatorToken;
			this.#errors.push({
				message: `Unary operators must always be followed by whitespace (found <${token?.kind ?? 'end of file'}>)`,
				startOffset: locationToken.startOffset,
				endOffset: locationToken.endOffset
			});
			logErrors = false;
		}
		return {
			kind: 'unary',
			operator: operator,
			child: this.#unaryExpression(logErrors)
		};
	}

	#binaryExpression (
		previousBinaryOperator: BinaryExpression['operator'] | null,
		logErrors: boolean
	): Expression
	{
		const left = this.#unaryExpression(logErrors);
		let seenWhitespace = this.#whitespace();
		const operatorToken = this.#currentToken();
		const operator = operatorToken?.value;
		if (
			!seenWhitespace ||
			!operatorToken ||
			!operator ||
			operatorToken.kind !== 'keyword' ||
			(
				operator !== 'and' &&
				operator !== 'or'
			)
		)
		{
			return left;
		}
		if (previousBinaryOperator !== null && operator !== previousBinaryOperator && logErrors)
		{
			this.#errors.push({
				message: 'Ambiguous operator precedence - use brackets when mixing "and" and "or" so that evaluation order is unambiguous (i.e. "(A and B) or C" vs "A and (B or C)")',
				startOffset: operatorToken.startOffset,
				endOffset: operatorToken.endOffset
			});
		}
		this.#index++;
		seenWhitespace = this.#whitespace();
		if (!seenWhitespace && logErrors)
		{
			const token = this.#currentToken();
			if (token)
			{
				this.#errors.push({
					message: `Unexpected token ${token.toString()} (expected <whitespace>, <bare-word>, <string>, <keyword> "not", or <symbol> "(")`,
					startOffset: token.startOffset,
					endOffset: token.endOffset
				});
			}
			else
			{
				this.#errors.push({
					message: `Missing right operand (expected <whitespace>, <bare-word>, <string>, <keyword> "not", or <symbol> "(")`,
					startOffset: operatorToken.startOffset,
					endOffset: operatorToken.endOffset
				});
			}
			logErrors = false;
		}
		const right = this.#binaryExpression(operator, logErrors);
		return {
			kind: 'binary',
			operator: operator,
			left: left,
			right: right
		};
	}

	#expression (logErrors: boolean): Expression
	{
		this.#whitespace();
		const expr = this.#binaryExpression(null, logErrors);
		this.#whitespace();
		return expr;
	}

	parse (): { expression: Expression; errors: LangError[] }
	{
		const expr = this.#expression(true);
		const token = this.#currentToken();
		if (token)
		{
			this.#errors.push({
				message: `Unexpected token ${token.toString()} (expected <whitespace>, <keyword> "and", <keyword> "or", or <end of file>)`,
				startOffset: token.startOffset,
				endOffset: token.endOffset
			});
		}
		return {
			expression: expr,
			errors: this.#errors
		};
	}
}

function evaluateNode (node: Expression, tags: ReadonlySet<string>): boolean
{
	switch (node.kind)
	{
		case 'null':
			// This node exists just so we have something to return in the Parser for some error conditions -
			// if it appears here the Parser has done something wrong
			throw new Error(`Unexpected null node (this is probably a Parser design fault)`);

		case 'tag':
			return tags.has(node.value);

		case 'unary':
			switch (node.operator)
			{
				case 'not':
					return !evaluateNode(node.child, tags);

				default:
					return assertNever(node, `Unknown unary operator "${(node as any).operator}"`);
			}

		case 'binary':
			switch (node.operator)
			{
				case 'and':
					return evaluateNode(node.left, tags) && evaluateNode(node.right, tags);

				case 'or':
					return evaluateNode(node.left, tags) || evaluateNode(node.right, tags);

				default:
					return assertNever(node, `Unknown binary operator "${(node as any).operator}"`);
			}

		default:
			return assertNever(node, `Unknown node kind "${(node as any).kind}"`);
	}
}

export function evaluate (expression: Expression, tags: readonly string[]): boolean
{
	return evaluateNode(expression, new TagSet(tags));
}
