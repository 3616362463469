<script lang="ts">
	import { onDestroy, onMount } from 'svelte';
	import { getCellWidthsStore } from './DisjointGrid.svelte';

	export let column: number;

	const cellWidths = getCellWidthsStore(column);

	let id: symbol | undefined;
	let container: HTMLElement | undefined;

	let raf: number | undefined;
	function updateWidth (): void
	{
		if (raf === undefined)
		{
			raf = requestAnimationFrame(() =>
			{
				raf = undefined;
				if (id && container)
				{
					const recordedWidth = $cellWidths.get(id);
					if (
						typeof recordedWidth !== 'number' ||
							container.offsetWidth !== recordedWidth
					)
					{
						cellWidths.update(map =>
						{
							map.set(id!, (container!).offsetWidth);
							return map;
						});
					}
				}
			});
		}
	}

	let observer: ResizeObserver | undefined;

	onMount(() =>
	{
		if (!container)
		{
			throw new Error('Bound element not available in onMount');
		}
		id = Symbol('Unique Cell ID');
		updateWidth();
		observer = new ResizeObserver(updateWidth);
		observer.observe(container);
	});

	onDestroy(() =>
	{
		if (!container)
		{
			throw new Error('Bound element not available in onDestroy');
		}
		else if (!observer)
		{
			throw new Error('Variable defined in onMount not available in onDestroy');
		}
		observer.unobserve(container);
		observer.disconnect();
	});

	$: largestWidth = Array.from($cellWidths.values()).reduce((best, current) => Math.max(best, current), 0);
</script>

<div style:width={`${largestWidth}px`}>
	<div bind:this={container}>
		<slot />
	</div>
</div>

<style lang="scss">
	div
	{
		display: inline-block;
	}
</style>
