export function arrayChunk<T> (arr: readonly T[], size: number): T[][]
{
	const res: T[][] = [];
	for (let i = 0, j = size; i < arr.length; i = j, j += size)
	{
		res.push(arr.slice(i, j));
	}
	return res;
}

// The set difference A \ B (also written A − B) is the set of all things that belong to A but not B.
export function arrayDiff<T> (
	left: readonly T[],
	right: readonly T[],
	equals: (a: T, b: T) => boolean = (a, b) => a === b
): T[]
{
	const res: T[] = [];
	for (const item of left)
	{
		if (right.every(item2 => !equals(item, item2)))
		{
			res.push(item);
		}
	}
	return res;
}
