<script lang="ts">
	import { hyphenateSync } from 'hyphen/en';
	import ExternalLink from '../input/ExternalLink.svelte';
	import type { StoryAndChapterMap } from './LogViewer.svelte';
    import { getStoryTitle } from '../../settings';
    import type { Site, UserSettings } from '../../../../shared/database';

	export let storyAndChapterData: StoryAndChapterMap | undefined;
	export let site: Site | undefined;
	export let userSettings: UserSettings;
</script>

{#if storyAndChapterData === undefined || site === undefined}
	<em><span class="no-break">[u</span>n&shy;known stor<span class="no-break">y]</span></em>
{:else}
	<ExternalLink href={storyAndChapterData.story.indexUrl}>
		{hyphenateSync(getStoryTitle(userSettings, site, storyAndChapterData.story, storyAndChapterData.options))}
	</ExternalLink>
{/if}
