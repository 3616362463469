<script lang="ts">
	import { getCurrentTab, type TabId } from './TabGroup.svelte';
	import { createEventDispatcher, type ComponentType, type SvelteComponentTyped } from 'svelte';
	import IconButton from '../../input/IconButton.svelte';
	import { getOnSelect } from './TabList.svelte';

	export let id: TabId;
	export let buttonClasses: readonly string[] = [];
	export let selectedButtonClasses: readonly string[] = buttonClasses;
	export let disabled = false;
	export let icon: ComponentType<SvelteComponentTyped<{ alt: string }>> | null = null;
	export let alt: string | null = null;

	const dispatcher = createEventDispatcher<{
		select: {
			tabId: TabId;
		};
	}>();

	const currentTab = getCurrentTab();
	const onSelect = getOnSelect();

	function doPick (): void
	{
		if ($currentTab !== id)
		{
			$currentTab = id;
			dispatcher('select', {
				tabId: id
			});
			onSelect();
		}
	}

	$: buttonIsDisabled = disabled || $currentTab === id;
	$: classes = $currentTab === id ? selectedButtonClasses : buttonClasses;
	$: classesObject = icon === null || alt === null ? null : classes.reduce<Record<string, true>>(
		(record, className) =>
		{
			record[className] = true;
			return record;
		},
		{}
	);
</script>

{#if icon === null || alt === null || classesObject === null}
	<button
		type="button"
		class={classes.join(' ')}
		disabled={buttonIsDisabled}
		on:click={doPick}>
		<slot />
	</button>
{:else}
	<IconButton
		icon={icon}
		alt={alt}
		classes={classesObject}
		disabled={buttonIsDisabled}
		on:click={doPick} />
{/if}
