<script lang="ts">
    import { derived, writable, type Readable } from 'svelte/store';
    import { normalizeForComparison } from '../../text';
    import type { TagState } from '../ReadingListFilters.svelte';
    import IconTrashCan from '../svg-icons/IconTrashCan.svelte';
    import AutofillTextInput from './AutofillTextInput.svelte';
	import { globalDisable } from '../../state';
    import { createEventDispatcher, onDestroy } from 'svelte';
    import { getStoryKey, type StoryKey } from '../../keying';
    import type { StoryData } from '../../../../shared/common';
    import ComplexTagFilterTextArea from './ComplexTagFilterTextArea.svelte';
    import { evaluate, normalizeTag, type Expression, tagCanBeBareWord } from '../../complexTagFilterLang';
    import { TagMap, TagSet } from '../../tags';

	export let storyData: readonly StoryData[];
	export let allTags: Readable<TagSet>;

	export const usingComplexTagFiltering = writable(false);
	const complexExpression = writable(null as Expression | null);

	const dispatcher = createEventDispatcher<{
		filter: {
			acceptedItemIds: readonly StoryKey[];
		};
	}>();

	$: sortedAllTags = Array.from($allTags).sort((a, b) => a.localeCompare(b));

	const useOrForRequiredTags = writable(false);
	const requiredTags = writable(new TagSet());
	const forbiddenTags = writable(new TagSet());
	$: sortedRequiredTags = Array.from($requiredTags).sort((a, b) => a.localeCompare(b));
	$: sortedForbiddenTags = Array.from($forbiddenTags).sort((a, b) => a.localeCompare(b));

	const equivalentComplexSearchString = derived(
		[useOrForRequiredTags, requiredTags, forbiddenTags],
		([useOrForRequiredTags, required, forbidden]) =>
		{
			let res = '';
			if (required.size === 0 && forbidden.size === 0)
			{
				return res;
			}
			else if (required.size !== 0)
			{
				if (useOrForRequiredTags && forbidden.size !== 0 && required.size > 1)
				{
					res += '(';
				}
				res += Array.from(required).
					map(tag =>
					{
						if (tagCanBeBareWord(tag))
						{
							return tag.trim();
						}
						return `"${tag.replace(/\\/gu, '\\\\').replace(/"/gu, '\\"')}"`;
					}).
					join(useOrForRequiredTags ? ' or ' : ' and ');
				if (useOrForRequiredTags && forbidden.size !== 0 && required.size > 1)
				{
					res += ')';
				}
				if (forbidden.size !== 0)
				{
					res += ' and ';
				}
			}

			if (forbidden.size !== 0)
			{
				res += 'not ';
				if (forbidden.size > 1)
				{
					res += '(';
				}
				res += Array.from(forbidden).
					map(tag =>
					{
						if (tagCanBeBareWord(tag))
						{
							return tag.trim();
						}
						return `"${tag.replace(/\\/gu, '\\\\').replace(/"/gu, '\\"')}"`;
					}).
					join(' or ');
				if (forbidden.size > 1)
				{
					res += ')';
				}
			}

			return res;
		}
	);

	const acceptedItemIds = derived(
		[useOrForRequiredTags, requiredTags, forbiddenTags, usingComplexTagFiltering, complexExpression],
		([useOrForRequiredTags, required, forbidden, usingComplexTagFiltering, complexExpression]) =>
		{
			let filtered: readonly StoryData[];
			if (usingComplexTagFiltering)
			{
				if (complexExpression === null)
				{
					filtered = storyData;
				}
				else
				{
					filtered = storyData.filter(data => evaluate(complexExpression, data.options.tags));
				}
			}
			else
			{
				filtered = storyData.filter(data =>
				{
					const storyTags = data.options.tags.map(normalizeTag);
					let res = storyTags.every(tag => !forbidden.has(tag));
					if (res && required.size !== 0)
					{
						if (useOrForRequiredTags)
						{
							res = storyTags.some(tag => required.has(tag));
						}
						else
						{
							res = Array.from(required).every(tag => storyTags.includes(normalizeTag(tag)));
						}
					}
					return res;
				});
			}
			return filtered.map(getStoryKey);
		}
	);
	const unsub = acceptedItemIds.subscribe(value => dispatcher('filter', {
		acceptedItemIds: value
	}));
	onDestroy(unsub);

	export function addRequiredTag (tag: string): void
	{
		$requiredTags.add(tag);
		$forbiddenTags.delete(tag);
		$requiredTags = $requiredTags;
		$forbiddenTags = $forbiddenTags;
	}

	export function addForbiddenTag (tag: string): void
	{
		$requiredTags.delete(tag);
		$forbiddenTags.add(tag);
		$requiredTags = $requiredTags;
		$forbiddenTags = $forbiddenTags;
	}

	export function removeTagFromFilter (tag: string): void
	{
		$requiredTags.delete(tag);
		$forbiddenTags.delete(tag);
		$requiredTags = $requiredTags;
		$forbiddenTags = $forbiddenTags;
	}

	export const tagStateMap = derived(
		[requiredTags, forbiddenTags, allTags],
		([required, forbidden, allTags]) =>
		{
			const map = new TagMap<TagState>();
			for (const tag of allTags)
			{
				let state: TagState = null;
				if (required.has(tag))
				{
					state = 'required';
				}
				else if (forbidden.has(tag))
				{
					state = 'forbidden';
				}
				map.set(tag, state);
			}
			return map;
		}
	);
</script>

{#if $usingComplexTagFiltering}
	<ComplexTagFilterTextArea
		defaultString={$equivalentComplexSearchString}
		bind:expression={$complexExpression} />
{:else}
	{#if $requiredTags.size !== 0}
		<div class="p-like">
			<ul class="inline-list">
				{#each sortedRequiredTags as tag (tag)}
					<li>
						<button
							type="button"
							class="required"
							on:click={() => removeTagFromFilter(tag)}>
							{tag}
							<IconTrashCan alt={`Remove required tag "${tag}"`} style="display: inline;" />
						</button>
					</li>
				{/each}
			</ul>
		</div>
	{/if}
	<div class="p-like">
		<AutofillTextInput
			options={sortedAllTags}
			placeholder="Include tags..."
			disabled={$globalDisable}
			on:submit={event => (addRequiredTag(event.detail.value), event.detail.clear())} />
	</div>

	{#if $forbiddenTags.size !== 0}
		<div class="p-like">
			<ul class="inline-list">
				{#each sortedForbiddenTags as tag (tag)}
					<li>
						<button
							type="button"
							class="forbidden"
							on:click={() => removeTagFromFilter(tag)}>
							{tag}
							<IconTrashCan alt={`Remove forbidden tag "${tag}"`} style="display: inline;" />
						</button>
					</li>
				{/each}
			</ul>
		</div>
	{/if}
	<div class="p-like">
		<AutofillTextInput
			options={sortedAllTags}
			placeholder="Exclude tags..."
			disabled={$globalDisable}
			on:submit={event => (addForbiddenTag(event.detail.value), event.detail.clear())} />
	</div>

	<div class="p-like">
		<label>
			<input
				type="checkbox"
				bind:checked={$useOrForRequiredTags} />
			Use "Or" Matching
		</label>
	</div>
{/if}

<div class="p-like">
	<label>
		<input
			type="checkbox"
			checked={$usingComplexTagFiltering}
			on:change={event => $usingComplexTagFiltering = event.currentTarget.checked} />
		Use Complex Tag Filtering
	</label>
</div>
