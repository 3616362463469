<script lang="ts">
	import { onDestroy, onMount } from 'svelte';
	import { getCellWidthsMap } from './DynamicGrid.svelte';

	export let order: number;

	const map = getCellWidthsMap();
	let container: HTMLElement | undefined;

	let raf: number | undefined;
	function updateWidth (): void
	{
		if (raf === undefined)
		{
			raf = requestAnimationFrame(() =>
			{
				raf = undefined;
				if (container && $map.get(order) !== container.offsetWidth)
				{
					map.update(map =>
					{
						map.set(order, (container!).offsetWidth);
						return map;
					});
				}
			});
		}
	}

	let observer: ResizeObserver | undefined;

	onMount(() =>
	{
		if (!container)
		{
			throw new Error('Bound element not available in onMount');
		}
		updateWidth();
		observer = new ResizeObserver(updateWidth);
		observer.observe(container);
		window.addEventListener('resize', updateWidth);
	});

	onDestroy(() =>
	{
		if (!container)
		{
			throw new Error('Bound element not available in onDestroy');
		}
		else if (!observer)
		{
			throw new Error('Variable defined in onMount not available in onDestroy');
		}
		observer.unobserve(container);
		observer.disconnect();
		window.removeEventListener('resize', updateWidth);
	});
</script>

<div>
	<div bind:this={container}>
		<slot />
	</div>
</div>

<style lang="scss">
	div
	{
		display: inline-block;
	}
</style>
