<script lang="ts">
    import type { SiteData } from '../../../shared/common';
	import type { SiteId } from '../../../shared/database';

	export let numUnloadedStoriesBySite: Readonly<Record<SiteId, number>> | undefined;
	export let sites: Readonly<SiteData['sites']>;

	$: entries = numUnloadedStoriesBySite === undefined ? undefined : Object.entries(numUnloadedStoriesBySite).sort(([a], [b]) => a.localeCompare(b));
	$: allLoaded = entries?.every(([, n]) => n === 0);
</script>

{#if allLoaded}
	Done.
{:else if entries === undefined}
	Loading stories...
{:else}
	Loading:
	{#each entries as [site, n] (site)}
		{#if n !== 0}
			<br />
			{n} {n === 1 ? 'story' : 'stories'} from {sites[site].name}...
		{/if}
	{/each}
{/if}
