<script lang="ts">
	import type { Writable } from 'svelte/store';
	import type { SiteData, StoryData } from '../../../shared/common';
	import { getStoryKey, type StoryKey } from '../keying';
	import { bidirectionalDerived, globalDisable, pSites } from '../state';
	import Story, { type FilterInteractions } from './Story.svelte';
	import type { Log, Site, UserSettings } from '../../../shared/database';
	import type { PartialReadingListData } from './ReadingListSwitcher.svelte';
    import type { WrapEventArgs } from '../utilTypes';
    import type { EventArgsMap } from './ReadingProgress.svelte';
    import type { TagSet } from '../tags';

	export let dummyStoryData: StoryData;
	export let currentStoryData: Writable<Map<StoryKey, StoryData>>;
	export let currentListId: bigint;
	export let allListData: Writable<Map<bigint, PartialReadingListData>>;
	export let allTags: Writable<TagSet>;
	export let site: Site;
	export let allSites: SiteData['sites'];
	export let userSettings: UserSettings;
	export let filterInteractions: FilterInteractions;
	export let isLastStory: boolean;

	type $$Events = WrapEventArgs<EventArgsMap>;

	const storyData = bidirectionalDerived(
		[currentStoryData],
		map => map.get(getStoryKey(dummyStoryData)) ?? dummyStoryData,
		(storyData, map) =>
		{
			map.set(getStoryKey(storyData), storyData);
			return [map];
		}
	);
</script>

<li>
	<Story
		site={site}
		allSites={allSites}
		storyData={storyData}
		currentListId={currentListId}
		allListData={allListData}
		allTags={allTags}
		userSettings={userSettings}
		filterInteractions={filterInteractions}
		isLastStory={isLastStory}
		on:logCreated />
</li>
