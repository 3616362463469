<script lang="ts">
	import { onDestroy } from 'svelte';
	import { apiGetAuthState } from '../api';
    import { rafLoop } from '../async';
	import { createErrorDialog } from '../dialogs';
	import { gdprAllowCookies, reactiveMatchMedia, theme, type Theme } from '../state';
	import Info from './Info.svelte';
	import ReadingListSwitcher from './ReadingListSwitcher.svelte';
	import IconButton from './input/IconButton.svelte';
	import HamburgerMenu from './layout/HamburgerMenu.svelte';
	import IconAsterisk from './svg-icons/IconAsterisk.svelte';
	import IconMoon from './svg-icons/IconMoon.svelte';
	import IconSun from './svg-icons/IconSun.svelte';
    import ExternalLink from './input/ExternalLink.svelte';

	$: {
		document.documentElement.classList.toggle('light', $theme === 'light');
		document.documentElement.classList.toggle('dark', $theme === 'dark');
	}

	const pAuthState = apiGetAuthState();
	const useHamburgerMenu = reactiveMatchMedia(
		'screen and (pointer: none), screen and (pointer: coarse), screen and (max-width: 932px)'
	);
	let menuIsOpen = false;

	async function setTheme (value: Theme): Promise<void>
	{
		if (value === null)
		{
			$theme = value;
			return;
		}
		const allowed = await gdprAllowCookies('Changing the site theme');
		if (allowed)
		{
			$theme = value;
		}
	}
</script>

<main>
	<div class="content">
		<header class="global-button-container">
			{#if $useHamburgerMenu || menuIsOpen}
				<HamburgerMenu alt="Theme and Authentication" align="right" bind:isOpen={menuIsOpen}>
					<div class="p-like no-top-margin">
						{#await pAuthState}
							<a class="button-like" href="/auth/login">Log In</a>
							<a class="button-like" href="/auth/logout">Log Out</a>
						{:then state}
							{#if state.isLoggedIn}
								<a class="button-like" href="/auth/logout">Log Out</a>
							{:else}
								<a class="button-like" href="/auth/login">Log In</a>
							{/if}
						{:catch e}
							{@const _ = createErrorDialog(e.message)}
							<a class="button-like" href="/auth/login">Log In</a>
							<a class="button-like" href="/auth/logout">Log Out</a>
						{/await}
					</div>
					<div class="p-like no-bottom-margin">
						<IconButton
							icon={IconAsterisk}
							alt="Switch to device default theme"
							classes={{
								secondary: typeof $theme === 'string'
							}}
							disabled={typeof $theme !== 'string'}
							on:click={() => setTheme(null)} />
						<IconButton
							icon={IconSun}
							alt="Switch to light theme"
							classes={{
								secondary: $theme !== 'light'
							}}
							disabled={$theme === 'light'}
							on:click={() => setTheme('light')} />
						<IconButton
							icon={IconMoon}
							alt="Switch to dark theme"
							classes={{
								secondary: $theme !== 'dark'
							}}
							disabled={$theme === 'dark'}
							on:click={() => setTheme('dark')} />
					</div>
				</HamburgerMenu>
			{:else}
				{#await pAuthState}
					<a class="button-like" href="/auth/login">Log In</a>
					<a class="button-like" href="/auth/logout">Log Out</a>
				{:then state}
					{#if state.isLoggedIn}
						<a class="button-like" href="/auth/logout">Log Out</a>
					{:else}
						<a class="button-like" href="/auth/login">Log In</a>
					{/if}
				{:catch e}
					{@const _ = createErrorDialog(e.message)}
					<a class="button-like" href="/auth/login">Log In</a>
					<a class="button-like" href="/auth/logout">Log Out</a>
				{/await}
				<IconButton
					icon={IconAsterisk}
					alt="Switch to OS default theme"
					classes={{
						secondary: typeof $theme === 'string'
					}}
					disabled={typeof $theme !== 'string'}
					on:click={() => $theme = null} />
				<IconButton
					icon={IconSun}
					alt="Switch to light theme"
					classes={{
						secondary: $theme !== 'light'
					}}
					disabled={$theme === 'light'}
					on:click={() => $theme = 'light'} />
				<IconButton
					icon={IconMoon}
					alt="Switch to dark theme"
					classes={{
						secondary: $theme !== 'dark'
					}}
					disabled={$theme === 'dark'}
					on:click={() => $theme = 'dark'} />
			{/if}
		</header>

		{#await pAuthState}
			<p>Checking user authorization...</p>
		{:then state}
			{#if state.isAuthorized}
				<ReadingListSwitcher authState={state} />
			{:else}
				<Info userSettings={null} userHasAccess={false} />
			{/if}
		{:catch e}
			{@const _ = createErrorDialog(e.message)}
		{/await}
	</div>

	<footer>
		<ul class="inline-list">
			<li><a href="/about/terms-of-service">Terms of Service</a></li>
			<li><a href="/about/privacy-policy">Privacy Policy</a></li>
		</ul>
	</footer>
</main>

<style lang="scss">
	.global-button-container
	{
		float: right;

		@media screen and (min-width: 721px)
		{
			margin-right: 4rem;
		}
	}

	$horizontalPadding: 1rem;
	$maxWidth: calc(900px + 2 * $horizontalPadding);

	main
	{
		min-height: 100vh;
		display: grid;
		grid-template-rows: 1fr auto;
	}

	.content
	{
		box-sizing: border-box;
		padding: 2rem $horizontalPadding;
		width: 100%;
		max-width: $maxWidth;
	}

	footer
	{
		padding: 1em 0;
		width: 100%;
		text-align: center;
		background-color: var(--input-bg-color);
	}

	footer li
	{
		display: inline-block;
		margin: 0 var(--margin-medium);
	}

	@media (min-width: $maxWidth)
	{
		.content
		{
			margin-left: auto;
			margin-right: auto;
		}
	}

	.no-top-margin
	{
		margin-top: 0;
	}

	.no-bottom-margin
	{
		margin-bottom: 0;
	}
</style>
