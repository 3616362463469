import { NormalizedMap, NormalizedSet } from './collections';
import { normalizeTag } from './complexTagFilterLang';

export class TagSet extends NormalizedSet
{
	protected override normalize (tag: string): string
	{
		return normalizeTag(tag);
	}
}

export class TagMap<T> extends NormalizedMap<T>
{
	protected override normalize (tag: string): string
	{
		return normalizeTag(tag);
	}
}
