<script lang="ts">
	import type { AriaRole } from 'svelte/elements';
	import IconButton, { type IconComponent } from '../input/IconButton.svelte';
	import IconBars from '../svg-icons/IconBars.svelte';
	import { closeDialogOnClickOut } from '../../dialogs';

	export let alt: string;
	export let align: 'left' | 'right';
	export let icon: IconComponent = IconBars;
	export let role: AriaRole = 'menu';
	export let classes: Readonly<Record<string, boolean>> | undefined = undefined;
	export let isOpen = false;
	export let buttonActive = false;
	export let disabled = false;

	let dialog: HTMLDialogElement | undefined;

	export function open (): void
	{
		isOpen = true;
	}

	export function close (): void
	{
		isOpen = false;
	}

	function openDialog (): void
	{
		if (dialog)
		{
			dialog.showModal();
			isOpen = true;
		}
	}

	$: {
		if (dialog)
		{
			if (isOpen && !dialog.open)
			{
				dialog.showModal();
			}
			else if (!isOpen && dialog.open)
			{
				dialog.close();
			}
		}
	}
</script>

<IconButton
	icon={icon}
	alt={alt}
	classes={classes ?? {
		secondary: buttonActive || !isOpen
	}}
	disabled={disabled}
	on:click={openDialog} />
<dialog
	role={role}
	style:left={align === 'left' ? '0' : ''}
	style:right={align === 'right' ? '0' : ''}
	style:border-left-style={align === 'left' ? 'none' : ''}
	style:border-right-style={align === 'right' ? 'none' : ''}
	bind:this={dialog}
	on:click={closeDialogOnClickOut}
	on:close={() => isOpen = false}>
	<slot />
</dialog>

<style lang="scss">
	dialog
	{
		position: fixed;
		left: initial;
		top: 0;
		box-sizing: border-box;
		margin: 0;
		border-top-style: none;
		border-bottom-style: none;
		max-width: 50vw;
		max-height: none;
		height: 100vh;
		overflow-x: hidden;
		overflow-y: auto;
	}
</style>
