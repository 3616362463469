<script context="module" lang="ts">
	export type ViewBoxString = `0 0 ${number} ${number}`;
</script>

<script lang="ts">
	import { onMount } from 'svelte';
	import { getUniqueSvgId } from '../../state';

	export let alt: string;
	export let viewBox: ViewBoxString;
	export let style: string = '';

	let titleId: string;

	onMount(() =>
	{
		titleId = getUniqueSvgId();
	});
</script>

<svg
	class="icon"
	style={style}
	role="img"
	xmlns="http://www.w3.org/2000/svg"
	viewBox={viewBox}
	aria-labelledby={titleId}>
	<title id={titleId}>{alt}</title>
	<slot />
</svg>
