<script context="module" lang="ts">
	export interface SubmitEventArgs
	{
		value: string;
		clear: () => void;
	}
</script>

<script lang="ts">
    import { createEventDispatcher, onDestroy } from 'svelte';
    import { normalizeForComparison } from '../../text';
    import { rafLoop } from '../../async';
    import IconButton from './IconButton.svelte';
    import IconPlus from '../svg-icons/IconPlus.svelte';
    import { createGlobalDialog } from '../../dialogs';

	export let options: readonly string[];
	export let inputValue = '';
	export let placeholder = '';
	export let disabled = false;
	export let forceListOpen = false;
	export let allowUnmatched = false;

	const dispatcher = createEventDispatcher<{
		submit: SubmitEventArgs;
	}>();

	export function clear (): void
	{
		inputValue = '';
	}

	let inputFocused = false;
	let dialogFocused = false;
	$: focused = inputFocused || dialogFocused;
	$: cmpInputValue = normalizeForComparison(inputValue);
	$: startsWithOptions = cmpInputValue === '' ? options : options.filter(option => normalizeForComparison(option).startsWith(cmpInputValue));
	$: includesOptions = cmpInputValue === '' ? [] : options.filter(option =>
	{
		option = normalizeForComparison(option);
		return option.includes(cmpInputValue) && !option.startsWith(cmpInputValue);
	});
	$: filteredOptions = startsWithOptions.concat(includesOptions);

	let selectedOptionIndex = 0;
	$: {
		if (selectedOptionIndex >= filteredOptions.length)
		{
			selectedOptionIndex = 0;
		}
	}

	function submit (): void
	{
		const submittedValue = inputValue.trim().replace(/\s+/gu, ' ');
		if (!allowUnmatched)
		{
			const cmpValue = normalizeForComparison(submittedValue);
			if (!options.some(option => normalizeForComparison(option) === cmpValue))
			{
				createGlobalDialog(`"${submittedValue}" is not one of the permitted options`);
				return;
			}
		}
		if (submittedValue !== '')
		{
			dispatcher('submit', {
				value: submittedValue,
				clear: clear
			});
		}
	}

	let dialogElement: HTMLDialogElement | undefined;
	let optionList: HTMLElement | undefined;

	function bringSelectedOptionIntoView (): void
	{
		if (!dialogElement || !optionList || filteredOptions.length === 0)
		{
			return;
		}
		const element = optionList.children[selectedOptionIndex];
		const dialogRect = dialogElement.getBoundingClientRect();
		const elementRect = element.getBoundingClientRect();
		const top = elementRect.top - dialogRect.top;
		if (top < 0)
		{
			dialogElement.scrollBy(0, top);
		}
		else
		{
			const topPlusHeight = top + elementRect.height;
			const diff = topPlusHeight - dialogElement.offsetHeight;
			if (diff > 0)
			{
				dialogElement.scrollBy(0, diff);
			}
		}
	}


	function onKeyDown (event: KeyboardEvent): void
	{
		if (event.key === 'Tab')
		{
			event.preventDefault();
			if (filteredOptions.length !== 0)
			{
				const selectedOption = filteredOptions[selectedOptionIndex];
				inputValue = selectedOption;
				submit();
			}
		}
		else if (event.key === 'Enter')
		{
			event.preventDefault();
			submit();
		}
		else if (event.key === 'ArrowDown')
		{
			event.preventDefault();
			selectedOptionIndex++;
			if (selectedOptionIndex >= filteredOptions.length)
			{
				selectedOptionIndex = 0;
			}
			bringSelectedOptionIntoView();
		}
		else if (event.key === 'ArrowUp')
		{
			event.preventDefault();
			selectedOptionIndex--;
			if (selectedOptionIndex < 0)
			{
				selectedOptionIndex = Math.max(0, filteredOptions.length - 1);
			}
			bringSelectedOptionIntoView();
		}
	}

	let inputElement: HTMLInputElement | undefined;

	function selectOption (option: string): void
	{
		inputValue = option;
		submit();
		inputElement?.focus();
	}

	const stop = rafLoop(() =>
	{
		if (!inputElement || !dialogElement)
		{
			return;
		}
		const rect = inputElement.getBoundingClientRect();
		dialogElement.style.left = `${rect.left}px`;
		dialogElement.style.top = `${rect.top + rect.height}px`;
	});
	onDestroy(stop);
</script>

<ul class="inline-list">
	<li>
		<input
			type="text"
			placeholder={placeholder}
			disabled={disabled}
			bind:this={inputElement}
			bind:value={inputValue}
			on:focus|capture={() => inputFocused = true}
			on:blur={() => inputFocused = false}
			on:keydown|stopPropagation={onKeyDown} />
	</li>
	<li>
		<IconButton
			icon={IconPlus}
			alt="Add Tag"
			disabled={disabled || inputValue === ''}
			on:click={submit} />
	</li>
</ul>
{#if options.length !== 0}
	<dialog
		open={forceListOpen || (focused && filteredOptions.length !== 0)}
		bind:this={dialogElement}
		on:focusin|capture={() => dialogFocused = true}
		on:focusout={() => dialogFocused = false}
		on:mousedown|stopPropagation|preventDefault={() => {}}>
		{#if filteredOptions.length === 0}
			<p>Input does not match any options.</p>
		{:else}
			<ol bind:this={optionList}>
				{#each filteredOptions as option, index}
					<li>
						<button
							type="button"
							class="autofill-item"
							class:secondary={index !== selectedOptionIndex}
							disabled={disabled}
							on:click={() => selectOption(option)}>
							{option}
						</button>
					</li>
				{/each}
			</ol>
		{/if}
	</dialog>
{/if}

<style lang="scss">
	dialog
	{
		position: fixed;
		margin: 0;
		border-width: 1px;
		padding: var(--margin-medium);
		max-width: 50vh;
		max-height: min(16em, 50vh);
		background-color: var(--input-bg-color);
		overflow-x: hidden;
		overflow-y: auto;
		z-index: 1;
	}

	dialog > *
	{
		margin: 0;
		padding: 0;
	}

	ol
	{
		list-style-type: none;
	}

	.autofill-item
	{
		width: 100%;
		text-align: left;
		overflow-wrap: break-word;
		white-space: break-spaces;
	}
</style>
