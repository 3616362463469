<script context="module" lang="ts">
	import { createEventDispatcher, getContext, onDestroy } from 'svelte';
	import type { Writable } from 'svelte/store';

	const tabContextKey = Symbol('Tab Context Key');

	export type TabId = undefined | null | boolean | number | bigint | string | symbol;

	export function getCurrentTab (): Writable<TabId>
	{
		return getContext(tabContextKey);
	}
</script>

<script lang="ts">
	import { setContext } from 'svelte';
	import { writable } from 'svelte/store';
	import type { UnwrapEvents } from '../../../utilTypes';

	type T = $$Generic<TabId>;

	export let activeTab: T;

	const currentTabStore = writable(activeTab);
	setContext(tabContextKey, currentTabStore);

	interface $$Events
	{
		activeTabChange: CustomEvent<{
			activeTab: T;
		}>;
	}

	const dispatcher = createEventDispatcher<UnwrapEvents<$$Events>>();

	function updateActiveTab (value: T): void
	{
		activeTab = value;
		dispatcher('activeTabChange', {
			activeTab: activeTab
		});
	}

	const unsub = currentTabStore.subscribe(updateActiveTab);
	onDestroy(unsub);

	function onActiveTabChange (): void
	{
		if (activeTab !== $currentTabStore)
		{
			$currentTabStore = activeTab;
			updateActiveTab(activeTab);
		}
	}

	// Only run code when the property updates, not currentTabStore
	$: (void activeTab, onActiveTabChange());
</script>

<div class="tab-group">
	<slot />
</div>
