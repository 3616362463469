<script context="module" lang="ts">
	export interface StoryAndChapterMap
	{
		story: Story;
		options: StoryData['options'];
		chapterMap: Map<string, ChapterData>;
	}
</script>

<script lang="ts">
	import { createEventDispatcher, onMount } from 'svelte';
	import type { ChapterData, SiteData, StoryData, UndoData } from '../../../../shared/common';
	import type { Log, ReadingList, Story, GroupedChapter, LogActionType, UserSettings } from '../../../../shared/database';
	import { ApiError, apiGetStoryData, apiUndoLogs } from '../../api';
	import { assertNever } from '../../assertions';
	import ComplexTextSearch from '../ComplexTextSearch.svelte';
	import ExternalLink from '../input/ExternalLink.svelte';
	import { insertionSort } from '../../sort';
	import { createDateString } from '../../time';
	import { getStoryKey, type StoryKey } from '../../keying';
	import { createErrorDialog } from '../../dialogs';
	import { derived, writable, type Readable, type Writable } from 'svelte/store';
	import { isReadingListData, type PartialReadingListData } from '../ReadingListSwitcher.svelte';
	import { globalDisable, useMobileLayout } from '../../state';
	import IconButton from '../input/IconButton.svelte';
	import IconClockRotateAcw from '../svg-icons/IconClockRotateAcw.svelte';
	import LogStory from './LogStory.svelte';
	import LogAction from './LogAction.svelte';

	export let logs: Log[];
	export let allListData: Writable<Map<bigint, PartialReadingListData>>;
	export let currentStoryData: Writable<Map<StoryKey, StoryData>>;
	export let sites: Readonly<SiteData['sites']>;
	export let userSettings: UserSettings;
	export let disableUndo = false;

	$: readingLists = new Map(
		Array.from($allListData.values()).
			filter(isReadingListData).
			map(listData => [listData.readingList.listId, listData.readingList])
	);

	const selfLoadedStoryData = writable(new Map<StoryKey, StoryData>());
	const allStoryData = derived(
		[currentStoryData, selfLoadedStoryData],
		([map1, map2]) =>
		{
			const res = new Map<StoryKey, StoryData>();
			for (const [key, value] of map1)
			{
				res.set(key, value);
			}
			for (const [key, value] of map2)
			{
				res.set(key, value);
			}
			return res;
		}
	);

	const alreadyGetting = new Set<string>();

	async function populateMissingStoryData (log: Log): Promise<void>
	{
		const key = getStoryKey(log);
		if (alreadyGetting.has(key) || $allStoryData.has(key))
		{
			return;
		}
		alreadyGetting.add(key);
		try
		{
			const data = await apiGetStoryData(log.site, log.storyId);
			selfLoadedStoryData.update(map =>
			{
				map.set(key, data);
				return map;
			});
		}
		catch (e)
		{
			alreadyGetting.delete(key);
			if (!(e instanceof ApiError))
			{
				throw e;
			}
			createErrorDialog(e.message);
		}
	}

	$: storyAndChapterDataMap = Array.from($allStoryData).reduce(
		(map, [key, storyData]) =>
		{
			map.set(key, {
				story: storyData.story,
				options: storyData.options,
				chapterMap: new Map(storyData.chapters.map(chapterData => [chapterData.chapter.chapterId, chapterData]))
			});
			return map;
		},
		new Map<StoryKey, StoryAndChapterMap>()
	);

	onMount(async () =>
	{
		const promises: Promise<void>[] = [];
		for (const log of logs)
		{
			if (!$allStoryData.has(getStoryKey(log)))
			{
				promises.push(populateMissingStoryData(log));
			}
		}
		await Promise.all(promises);
	});

	export function addLog (log: Log): void
	{
		insertionSort(
			logs,
			log,
			'desc',
			(a, b) => Number(a.timestamp - b.timestamp)
		);
		if (!$allStoryData.has(getStoryKey(log)))
		{
			populateMissingStoryData(log);
		}
		logs = logs;
	}

	function getHumanReadableActionType (actionType: LogActionType): string
	{
		switch (actionType)
		{
			case 'add':
				return 'Add to Reading List';

			case 'move':
				return 'Move to New Reading List';

			case 'change':
				return 'Update Reading Progress';

			case 'remove':
				return 'Delete from Reading List';

			default:
				assertNever(actionType, `Unknown LogActionType ${actionType}`);
		}
	}

	async function anchorReadingStateAt (anchor: Readonly<Log>): Promise<void>
	{
		if (disableUndo)
		{
			return;
		}

		await globalDisable.runAction(async () =>
		{
			const undoData = await apiUndoLogs(anchor.logId);
			logs = logs.filter(log2 => log2.site !== anchor.site || log2.storyId !== anchor.storyId || log2.timestamp <= anchor.timestamp);
			const key = getStoryKey(undoData.storyData);
			if (undoData.oldListId !== null && undoData.oldListId !== undoData.newListId)
			{
				const oldList = $allListData.get(undoData.oldListId);
				if (oldList)
				{
					allListData.update(map =>
					{
						oldList.stories.delete(key);
						return map;
					});
				}
			}
			if (undoData.newListId !== null)
			{
				const newList = $allListData.get(undoData.newListId);
				if (newList)
				{
					allListData.update(map =>
					{
						newList.stories.set(key, undoData.storyData);
						return map;
					});
				}
			}
		});
	}

	let acceptedItemIds: bigint[] | undefined;
	$: displayedLogs = logs.
		filter(log => acceptedItemIds === undefined || acceptedItemIds?.includes(log.logId));
	$: showRevertButtonMap = logs.reduce(
		(map, log) =>
		{
			map.set(log.logId, !log.isFinalForStory);
			return map;
		},
		new Map<bigint, boolean>()
	);
	$: showRevertColumn = !disableUndo && Array.from(showRevertButtonMap.values()).some(x => x);
</script>

{#if logs.length === 0}
	<p>No logs found for this reading list.</p>
{:else}
	<ComplexTextSearch
		placeholder="Filter logs by story title..."
		items={logs}
		itemToSearchableStrings={log => $allStoryData.get(getStoryKey(log))?.story.title}
		getItemId={log => log.logId}
		on:filter={event => acceptedItemIds = event.detail.acceptedItemIds} />
	<table>
		<thead>
			<tr>
				<th scope="col">Date</th>
				{#if !$useMobileLayout}
					<th scope="col">Action Type</th>
				{/if}
				<th scope="col">Story</th>
				<th scope="col">Change</th>
				{#if showRevertColumn}
					<th scope="col">Revert</th>
				{/if}
			</tr>
		</thead>
		<tbody>
			{#each displayedLogs as log (log.logId)}
				{@const storyAndChapterData = storyAndChapterDataMap.get(getStoryKey(log))}
				{@const oldReadingList = log.oldListId !== null ? readingLists.get(log.oldListId) : undefined}
				{@const newReadingList = log.newListId !== null ? readingLists.get(log.newListId) : undefined}
				<tr>
					<td class="date-cell">{createDateString(log.timestamp)}</td>
					{#if !$useMobileLayout}
						<td>{getHumanReadableActionType(log.actionType)}</td>
					{/if}
					<td>
						<LogStory
							storyAndChapterData={storyAndChapterData}
							site={storyAndChapterData ? sites[storyAndChapterData.story.site] : undefined}
							userSettings={userSettings} />
					</td>
					<td>
						<LogAction
							log={log}
							storyAndChapterData={storyAndChapterData}
							oldReadingList={oldReadingList}
							newReadingList={newReadingList} />
					</td>
					{#if showRevertColumn}
						<td class="revert-cell">
							{#if showRevertButtonMap.get(log.logId)}
								<IconButton
									icon={IconClockRotateAcw}
									alt="Revert to here"
									disabled={$globalDisable}
									on:click={() => anchorReadingStateAt(log)} />
							{/if}
						</td>
					{/if}
				</tr>
			{/each}
		</tbody>
	</table>
{/if}

<style lang="scss">
	th,
	td
	{
		padding: 0.5rem;
	}

	th
	{
		vertical-align: middle;
	}

	.date-cell
	{
		text-align: center;
	}

	.revert-cell
	{
		text-align: center;
	}
</style>
