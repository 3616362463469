export class ExternallyResolvablePromise<T>
{
	#resolve!: (value: T) => void;
	#reject!: (err?: any) => void;
	readonly promise = new Promise<T>((resolve, reject) =>
	{
		this.#resolve = resolve;
		this.#reject = reject;
	});

	get resolve (): (value: T) => void
	{
		return this.#resolve;
	}

	get reject (): (err?: any) => void
	{
		return this.#reject;
	}
}
