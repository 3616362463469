import { derived, readable, writable, type Readable, type Writable, get, type Subscriber, type Unsubscriber } from 'svelte/store';
import { apiGetSiteData, apiGetUserSettings } from './api';
import type { StoryStatus } from '../../shared/database';
import { createGlobalDialog } from './dialogs';

export type Theme = 'light' | 'dark' | null;
const localStorageThemeKey = 'theme';
export const theme = writable(localStorage.getItem(localStorageThemeKey) as Theme);
theme.subscribe(value =>
{
	if (typeof value === 'string')
	{
		localStorage.setItem(localStorageThemeKey, value);
	}
	else
	{
		localStorage.removeItem(localStorageThemeKey);
	}
});
export const effectiveTheme = derived(theme, themeValue =>
{
	if (themeValue === null)
	{
		themeValue = matchMedia('screen and (prefers-color-scheme: dark)').matches ? 'dark' : 'light';
	}
	return themeValue;
});

export interface DisableManagerLike extends Readable<boolean>
{
	runAction: <T> (action: () => Promise<T>) => Promise<T>;
	valueOf (): boolean;
}

export class DisableManager implements DisableManagerLike
{
	#count = writable(0);
	readonly #shouldDisable = derived(this.#count, count => count > 0);
	readonly subscribe = this.#shouldDisable.subscribe;

	static all (managers: DisableManager[]): DisableManagerLike
	{
		const combined = derived(
			managers,
			values => values.some(value => value)
		);
		return {
			subscribe: combined.subscribe,
			runAction: async action =>
			{
				for (const manager of managers)
				{
					manager.#count.update(count => count + 1);
				}
				try
				{
					return await action();
				}
				finally
				{
					for (const manager of managers)
					{
						manager.#count.update(count => count - 1);
					}
				}
			},
			valueOf: () => get(combined)
		};
	}

	async runAction<T> (action: () => Promise<T>): Promise<T>
	{
		this.#count.update(count => count + 1);
		try
		{
			return await action();
		}
		finally
		{
			this.#count.update(count => count - 1);
		}
	}

	valueOf (): boolean
	{
		return get(this.#shouldDisable);
	}
}

export const pSites = readable(apiGetSiteData());
export const globalDisable = new DisableManager();
const localStorageNsfwKey = 'show-nsfw';
export const showNsfwContent = writable(((): boolean =>
{
	const strValue = localStorage.getItem(localStorageNsfwKey);
	if (strValue === null)
	{
		return false;
	}
	const jsonValue = JSON.parse(strValue) as unknown;
	if (typeof jsonValue !== 'boolean')
	{
		throw new RangeError(`Type of key "${localStorageNsfwKey}" in localStorage must be \`boolean\` (was ${jsonValue === null ? 'null' : Array.isArray(jsonValue) ? 'array' : typeof jsonValue})`);
	}
	return jsonValue;
})());
let initialLoad = true;
showNsfwContent.subscribe(value =>
{
	if (initialLoad)
	{
		initialLoad = false;
		return;
	}
	localStorage.setItem(localStorageNsfwKey, JSON.stringify(value));
});

type Stores = [Writable<any>, ...Writable<any>[]];
type StoresValues<T> = {
	[P in keyof T]: T[P] extends Readable<infer U> ? U : never;
};

export function bidirectionalDerived <TStores extends Stores, TDerived> (
	stores: TStores,
	fromSource: (...sourceValues: StoresValues<TStores>) => TDerived,
	toSource: (value: TDerived, ...sourceValues: StoresValues<TStores>) => StoresValues<TStores>
): Writable<TDerived>
{
	if (stores.length === 0)
	{
		throw new RangeError('Cannot create a derived store from a set of 0 stores');
	}
	const d = derived(stores, x => fromSource(...x as any));
	const res: Writable<TDerived> = {
		...d,
		set: value =>
		{
			const values = toSource(value, ...stores.map(store => get(store)) as any);
			if (values.length !== stores.length)
			{
				throw new RangeError(`toSource must return ${stores.length} item(s) but ${values.length} were returned`);
			}
			for (let i = 0; i < stores.length; i++)
			{
				stores[i].set(values[i]);
			}
		},
		update: updater => res.set(updater(get(d)))
	};
	return res;
}

let nextSvgId = 0;

export function getUniqueSvgId (): string
{
	return `svg-${nextSvgId++}`;
}

function setUpMatchMediaListeners (matcher: MediaQueryList, weakStore: WeakRef<Writable<boolean>>): void
{
	const onChange = () =>
	{
		const store = weakStore.deref();
		if (store)
		{
			store.set(matcher.matches);
		}
		else
		{
			matcher.removeEventListener('change', onChange);
		}
	};
	matcher.addEventListener('change', onChange);
}

export function reactiveMatchMedia (query: string): Readable<boolean>
{
	const matcher = matchMedia(query);
	const res = writable(matcher.matches);
	setUpMatchMediaListeners(matcher, new WeakRef(res));
	return res;
}

export const useMobileLayout = reactiveMatchMedia('screen and (pointer: none), screen and (pointer: coarse), screen and (max-width: 720px)');

const reactiveVariables = ({
	'screen and (pointer: none), screen and (pointer: coarse), screen and (max-width: 720px)': [
		'--small-cover-art-width',
		'--small-cover-art-height'
	]
} as const) satisfies Partial<Readonly<Record<string, readonly string[]>>>;
type ArrayValues<T> = T extends Partial<Readonly<Record<string, readonly (infer U)[]>>> ? U : never;
const perfReactiveVariables = Object.entries(reactiveVariables).reduce(
	(record, [mediaQuery, cssVars]) =>
	{
		for (const cssVar of cssVars)
		{
			record[cssVar] = mediaQuery;
		}
		return record;
	},
	{} as Record<ArrayValues<typeof reactiveVariables>, string>
);

export function reactiveCssVariable (name: keyof typeof perfReactiveVariables): Readable<string>
{
	const mediaQuery = perfReactiveVariables[name];
	const matchesStore = reactiveMatchMedia(mediaQuery);
	return derived(
		matchesStore,
		() => getComputedStyle(document.documentElement).getPropertyValue(name)
	);
}

export const allStoryStatuses: Readonly<Record<StoryStatus, string>> = {
	'abandoned': 'Dropped',
	'completed': 'Complete',
	'hiatus': 'On Hiatus',
	'likely-abandoned': 'Probably Dropped',
	'ongoing': 'Ongoing',
	'stub': 'Preview of Paid Content',
	'unknown': 'Unknown'
};

const localStorageAllowCookies = 'gdpr-allow-cookies';

export function gdprAllowCookies (actionDesc: string): Promise<boolean>
{
	return new Promise((resolve, reject) =>
	{
		const value = localStorage.getItem(localStorageAllowCookies);
		if (value === 'true')
		{
			resolve(true);
		}
		else
		{
			createGlobalDialog(
				(parent, closeDialog) =>
				{
					// Re-check in case this dialog was enqueued
					const value = localStorage.getItem(localStorageAllowCookies);
					if (value === 'true')
					{
						closeDialog();
						resolve(true);
					}
					else
					{
						const p = document.createElement('p');
						p.textContent = `${actionDesc} requires cookies to be allowed.`;
						parent.appendChild(p);
						const div = document.createElement('div');
						div.className = 'p-like';
						const btnYes = document.createElement('button');
						btnYes.type = 'button';
						btnYes.textContent = 'Accept Cookies';
						btnYes.addEventListener('click', () =>
						{
							closeDialog();
							localStorage.setItem(localStorageAllowCookies, 'true');
							resolve(true);
						});
						div.appendChild(btnYes);
						const btnNo = document.createElement('button');
						btnNo.type = 'button';
						btnNo.textContent = 'Decline Cookies';
						btnNo.addEventListener('click', () =>
						{
							closeDialog();
							localStorage.removeItem(localStorageAllowCookies);
							resolve(false);
						});
						div.appendChild(btnNo);
						parent.appendChild(div);
					}
				},
				true
			);
		}
	});
}
