<script lang="ts">
	import type { ChapterData, SiteData, StoryData } from '../../../shared/common';
	import type { AlternateAvailability, GroupedChapter, Site, UserSettings } from '../../../shared/database';
	import { getEarliestGroupedChapter, getGroupedChapterPublishedDate, getGroupedChapterUrl } from '../settings';
	import ExternalLink from './input/ExternalLink.svelte';

	export let chapterData: ChapterData;
	export let userSettings: UserSettings;
	export let entrySettings: StoryData['options'];
	export let site: Site;
	export let alternateAvailability: readonly AlternateAvailability[];
	export let deleted: boolean;

	$: earliestGroupedChapter = getEarliestGroupedChapter(entrySettings, chapterData);
</script>

{#if earliestGroupedChapter === undefined}
	<p>Cannot find chapter.</p>
{:else if deleted}
	{earliestGroupedChapter.title}
{:else}
	<ExternalLink href={getGroupedChapterUrl(userSettings, site, earliestGroupedChapter, alternateAvailability)}>
		{earliestGroupedChapter.title}
	</ExternalLink>
{/if}
