<script lang="ts">
	import { createEventDispatcher, onMount } from 'svelte';
	import type { LinkedServiceId, ReadingList, UserSettings } from '../../../shared/database';
	import { ApiError, apiCreateReadingList, apiDeleteReadingList, apiGetUserSettings, apiPurgeData, apiRenameReadingList, apiSetUserSetting, type AuthState } from '../api';
	import { insertionSort } from '../sort';
	import { createErrorDialog } from '../dialogs';
	import { normalizeForComparison } from '../text';
	import SubmittableTextInput from './input/SubmittableTextInput.svelte';
	import ExternalLink from './input/ExternalLink.svelte';
	import { gdprAllowCookies, globalDisable, useMobileLayout } from '../state';
	import type { Writable } from 'svelte/store';
	import IconButton, { type IconComponent } from './input/IconButton.svelte';
	import IconPlus from './svg-icons/IconPlus.svelte';
	import IconChevronUp from './svg-icons/IconChevronUp.svelte';
	import IconChevronDown from './svg-icons/IconChevronDown.svelte';
	import IconEdit from './svg-icons/IconEdit.svelte';
	import IconTrashCan from './svg-icons/IconTrashCan.svelte';
	import IconWarning from './svg-icons/IconWarning.svelte';
	import DisjointGrid from './layout/disjoint-grid/DisjointGrid.svelte';
	import DisjointGridCell from './layout/disjoint-grid/DisjointGridCell.svelte';
	import { showNsfwContent } from '../state';
    import cookie from 'cookiejs';
    import SiteIcon from './SiteIcon.svelte';

	export let sortedReadingLists: ReadingList[];
	export let pUserSettings: Writable<Promise<UserSettings>>;
	export let authState: AuthState;

	const dispatcher = createEventDispatcher<{
		createReadingListRequest: {
			name: string;
		};
		readingListMoveRequest: {
			listId: bigint;
			increment: -1 | 1;
		};
		readingListRenameRequest: {
			listId: bigint;
			newName: string;
		};
		readingListDeletionRequest: {
			listId: bigint;
		};
	}>();

	function tryDeleteList (listId: bigint): void
	{
		dispatcher('readingListDeletionRequest', {
			listId: listId
		});
	}

	function moveList (readingList: ReadingList, increment: 1 | -1): void
	{
		dispatcher('readingListMoveRequest', {
			listId: readingList.listId,
			increment: increment
		});
	}

	let newListName = '';

	function createList (): void
	{
		dispatcher('createReadingListRequest', {
			name: newListName
		});
	}

	function renameList (readingList: ReadingList, newName: string): void
	{
		dispatcher('readingListRenameRequest', {
			listId: readingList.listId,
			newName: newName
		});
	}

	async function setShowNsfwContent (value: boolean): Promise<void>
	{
		const allowed = await gdprAllowCookies('Changing the "NSFW Content" setting');
		if (allowed)
		{
			$showNsfwContent = value;
		}
	}

	async function setWebnovelSitePreference (selectPref: string): Promise<void>
	{
		if (selectPref !== 'webnovel' && selectPref !== 'chereads' && selectPref !== '')
		{
			throw new RangeError(`Unknown Webnovel/Chereads site preference ${selectPref}`);
		}
		await globalDisable.runAction(async () =>
		{
			const pref = selectPref === '' ? null : selectPref;
			await apiSetUserSetting('webnovelSitePreference', pref);
			$pUserSettings = $pUserSettings.then(userSettings => ({
				...userSettings,
				webnovelSitePreference: pref
			}));
		});
	}

	async function setMangaDexLanguagePreference (selectPref: string): Promise<void>
	{
		if (selectPref !== 'default' && selectPref !== 'en')
		{
			throw new RangeError(`Unknown Webnovel/Chereads site preference ${selectPref}`);
		}
		globalDisable.runAction(async () =>
		{
			const pref = selectPref === 'en';
			await apiSetUserSetting('mangaDexUseEnglishTitles', pref);
			$pUserSettings = $pUserSettings.then(userSettings => ({
				...userSettings,
				mangaDexUseEnglishTitles: pref
			}));
		});
	}

	let showDangerousOptions = false;
	let localPurgeConfirmationDialog: HTMLDialogElement;
	let localPurgeConfirmBtn: HTMLButtonElement;
	let localPurgeCancelBtn: HTMLButtonElement;
	let purgeConfirmationDialog: HTMLDialogElement;
	let purgeConfirmBtn: HTMLButtonElement;
	let purgeCancelBtn: HTMLButtonElement;

	function purgeLocalStorage ()
	{
		localStorage.clear();
		cookie.clear();
	}

	async function purgeLocalData (): Promise<void>
	{
		globalDisable.runAction(async () =>
		{
			const pConfirmed = new Promise(resolve =>
			{
				const clear = () =>
				{
					localPurgeConfirmBtn.removeEventListener('click', onConfirm);
					localPurgeCancelBtn.removeEventListener('click', onCancel);
				};
				const onConfirm = () =>
				{
					resolve(true);
					clear();
				};
				const onCancel = () =>
				{
					resolve(false);
					clear();
				};
				localPurgeConfirmBtn.addEventListener('click', onConfirm);
				localPurgeCancelBtn.addEventListener('click', onCancel);
			});
			localPurgeConfirmationDialog.showModal();
			try
			{
				const confirmed = await pConfirmed;
				if (confirmed)
				{
					try
					{
						localPurgeConfirmBtn.disabled = true;
						localPurgeCancelBtn.disabled = true;
						purgeLocalStorage();
						location.assign(`${location.origin}/auth/logout`);
					}
					finally
					{
						localPurgeConfirmBtn.disabled = false;
						localPurgeCancelBtn.disabled = false;
					}
				}
			}
			finally
			{
				localPurgeConfirmationDialog.close();
			}
		});
	}

	async function purgeAllData (): Promise<void>
	{
		globalDisable.runAction(async () =>
		{
			const pConfirmed = new Promise(resolve =>
			{
				const clear = () =>
				{
					purgeConfirmBtn.removeEventListener('click', onConfirm);
					purgeCancelBtn.removeEventListener('click', onCancel);
				};
				const onConfirm = () =>
				{
					resolve(true);
					clear();
				};
				const onCancel = () =>
				{
					resolve(false);
					clear();
				};
				purgeConfirmBtn.addEventListener('click', onConfirm);
				purgeCancelBtn.addEventListener('click', onCancel);
			});
			purgeConfirmationDialog.showModal();
			try
			{
				const confirmed = await pConfirmed;
				if (confirmed)
				{
					try
					{
						purgeConfirmBtn.disabled = true;
						purgeCancelBtn.disabled = true;
						purgeLocalStorage();
						await apiPurgeData();
						location.assign(`${location.origin}/auth/logout`);
					}
					finally
					{
						purgeConfirmBtn.disabled = false;
						purgeCancelBtn.disabled = false;
					}
				}
			}
			finally
			{
				purgeConfirmationDialog.close();
			}
		});
	}

	function toggleDanger (): void
	{
		showDangerousOptions = !showDangerousOptions;
	}

	const linkedServiceIconUrlMap: Readonly<Record<LinkedServiceId, string>> = {
		'patreon': '/img/sites/patreon/icon.svg'
	};

	const linkedServiceNameMap: Readonly<Record<LinkedServiceId, string>> = {
		'patreon': 'Patreon'
	};

	function disableFixedImgDimensions (event: Event): void
	{
		(event.target as HTMLImageElement).removeAttribute('width');
		(event.target as HTMLImageElement).removeAttribute('height');
	}
</script>

<div>
	<div class="p-like">
		<input type="text" placeholder="Name..." disabled={$globalDisable} bind:value={newListName} />
		<IconButton
			icon={IconPlus}
			alt="Add New Reading List"
			disabled={$globalDisable || newListName.length === 0 || sortedReadingLists.some(rl => normalizeForComparison(rl.name) === normalizeForComparison(newListName))}
			on:click={createList} />
	</div>
	<DisjointGrid>
		<ol class:display-table={!$useMobileLayout}>
			{#each sortedReadingLists as readingList (readingList.listId)}
				<li class="row-coloring" class:display-table-row={!$useMobileLayout}>
					{#if !$useMobileLayout}
						<div class="align-middle display-table-cell">
							{#if readingList.order !== 0n}
								<IconButton
									icon={IconChevronUp}
									alt={`Move ${readingList.name} Up`}
									disabled={$globalDisable}
									on:click={() => moveList(readingList, -1)} />
							{/if}
						</div>
						<div class="align-middle display-table-cell">
							{#if readingList.order !== BigInt(sortedReadingLists.length - 1)}
								<IconButton
									icon={IconChevronDown}
									alt={`Move ${readingList.name} Down`}
									disabled={$globalDisable}
									on:click={() => moveList(readingList, 1)} />
							{/if}
						</div>
					{/if}
					<div class="align-middle" class:display-table-cell={!$useMobileLayout}>
						<SubmittableTextInput
							value={readingList.name}
							placeholder="Reading list name..."
							disabled={$globalDisable}
							on:submit={event => renameList(readingList, event.detail.value)}>
							<IconButton
								type="submit"
								icon={IconEdit}
								alt="Rename Reading List"
								disabled={$globalDisable} />
						</SubmittableTextInput>
					</div>
					<div class="align-middle flow-root" class:display-table-cell={!$useMobileLayout}>
						{#if $useMobileLayout}
							<DisjointGridCell column={0}>
								{#if readingList.order !== 0n}
									<span class="button-container move-up-button-container-mobile">
										<IconButton
											icon={IconChevronUp}
											alt={`Move ${readingList.name} Up`}
											disabled={$globalDisable}
											on:click={() => moveList(readingList, -1)} />
									</span>
								{/if}
							</DisjointGridCell>
							<DisjointGridCell column={1}>
								{#if readingList.order !== BigInt(sortedReadingLists.length - 1)}
									<span class="button-container move-down-button-container-mobile">
										<IconButton
											icon={IconChevronDown}
											alt={`Move ${readingList.name} Down`}
											disabled={$globalDisable}
											on:click={() => moveList(readingList, 1)} />
									</span>
								{/if}
							</DisjointGridCell>
						{/if}
						<span class="button-container delete-button-container">
							<IconButton
								icon={IconTrashCan}
								alt={`Delete ${readingList.name}`}
								classes={{
									warning: true
								}}
								disabled={$globalDisable || sortedReadingLists.length <= 1}
								on:click={() => tryDeleteList(readingList.listId)} />
						</span>
					</div>
				</li>
			{/each}
		</ol>
	</DisjointGrid>
</div>

{#await $pUserSettings}
	<p>Loading user settings...</p>
{:then userSettings}
	<dl class:dl-grid={!$useMobileLayout}>
		<dt>NSFW Content</dt>
		<dd>
			<select
				disabled={$globalDisable}
				on:change={event => setShowNsfwContent(event.currentTarget.value === 'show')}>
				<option
					value="hide"
					selected={!$showNsfwContent}>
					Hide
				</option>
				<option
					value="show"
					selected={$showNsfwContent}>
					Show
				</option>
			</select>
		</dd>
		<dt>MangaDex Title Language Preference</dt>
		<dd>
			<select
				disabled={$globalDisable}
				on:change={event => setMangaDexLanguagePreference(event.currentTarget.value)}>
				<option
					value="default"
					selected={!userSettings.mangaDexUseEnglishTitles}>
					Default Locale
				</option>
				<option
					value="en"
					selected={userSettings.mangaDexUseEnglishTitles}>
					English
				</option>
			</select>
		</dd>
		<dt>Webnovel/Chereads Site Preference</dt>
		<dd>
			<select
				disabled={$globalDisable}
				on:change={event => setWebnovelSitePreference(event.currentTarget.value)}>
				<option
					value=""
					selected={userSettings.webnovelSitePreference === null}>
					No Preference
				</option>
				<option
					value="chereads"
					selected={userSettings.webnovelSitePreference === 'chereads'}>
					Chereads
				</option>
				<option
					value="webnovel"
					selected={userSettings.webnovelSitePreference === 'webnovel'}>
					Webnovel
				</option>
			</select>
		</dd>
	</dl>
{:catch e}
	{@const _ = createErrorDialog(e.message)}
{/await}

<div>
	<h3>Linked Accounts</h3>
	<table>
		<thead>
			<tr>
				<th scope="col">Service</th>
				<th scope="col">Is Linked</th>
				<th scope="col">Account Name</th>
				<th scope="col">Action</th>
			</tr>
		</thead>
		<tbody>
			{#each authState.linkedServices as linkedService (linkedService)}
				<tr>
					<td>
						<img
							class="site-icon"
							src={linkedServiceIconUrlMap[linkedService]}
							alt={linkedServiceNameMap[linkedService]}
							title={linkedServiceNameMap[linkedService]}
							loading="lazy"
							width="24"
							on:load={disableFixedImgDimensions} />
						{linkedServiceNameMap[linkedService]}
					</td>
					<td>
						{#if Object.hasOwn(authState.linkedAccounts, linkedService)}
							Yes
						{:else}
							No
						{/if}
					</td>
					<td>
						{#if Object.hasOwn(authState.linkedAccounts, linkedService)}
							{authState.linkedAccounts[linkedService]}
						{:else}
							<em class="no-break">N/A</em>
						{/if}
					</td>
					<td>
						{#if Object.hasOwn(authState.linkedAccounts, linkedService)}
							<ExternalLink className="button-like warning" href="/auth/patreon/login">Revoke Access</ExternalLink>
						{:else}
							<ExternalLink className="button-like" href="/auth/patreon/login">Link Account</ExternalLink>
						{/if}
					</td>
				</tr>
			{/each}
		</tbody>
	</table>
</div>

<div class="p-like">
	<!-- svelte-ignore a11y-label-has-associated-control -->
	<label
		on:click={toggleDanger}
		on:keypress={event => event.key === 'Enter' && toggleDanger()}>
		<IconButton
			icon={IconWarning}
			alt="Warning symbol"
			classes={{
				warning: showDangerousOptions,
				secondary: !showDangerousOptions
			}} />
		{showDangerousOptions ? 'Hide' : 'Show'}
		Dangerous Options
	</label>
</div>

{#if showDangerousOptions}
	<div class="p-like">
		<button
			type="button"
			class="warning"
			disabled={$globalDisable}
			on:click={purgeLocalData}>
			Purge Local Data
		</button>
		<dialog bind:this={localPurgeConfirmationDialog}>
			<p>
				<strong class="warning">WARNING:</strong> You are about to delete all of your local data, including:
			</p>
			<ul>
				<li>Some user settings</li>
				<li>Login information</li>
			</ul>
			<p>Are you <strong><em>sure</em></strong> you want to do this?</p>
			<div class="p-like">
				<button type="button" class="warning" bind:this={localPurgeConfirmBtn}>Yes, Purge All My Local Data</button>
				<button type="button" class="secondary" bind:this={localPurgeCancelBtn}>No, Return Me to Safety</button>
			</div>
		</dialog>
	</div>

	<div class="p-like">
		<button
			type="button"
			class="warning"
			disabled={$globalDisable}
			on:click={purgeAllData}>
			Purge All Data
		</button>
		<dialog bind:this={purgeConfirmationDialog}>
			<p>
				<strong class="warning">WARNING:</strong> You are about to delete all of your data, including:
			</p>
			<ul>
				<li>Reading lists</li>
				<li>Reading progress</li>
				<li>User settings</li>
				<li>Activity logs</li>
				<li>Login information</li>
				<li>Account details kept by our <ExternalLink href="https://auth0.com/" target="_blank">third-party authentication provider</ExternalLink></li>
			</ul>
			<p>Are you <strong><em>sure</em></strong> you want to do this?</p>
			<div class="p-like">
				<button type="button" class="warning" bind:this={purgeConfirmBtn}>Yes, Purge All My Data</button>
				<button type="button" class="secondary" bind:this={purgeCancelBtn}>No, Return Me to Safety</button>
			</div>
		</dialog>
	</div>
{/if}

<style lang="scss">
	th,
	td
	{
		padding: 0.5rem;
	}

	th
	{
		vertical-align: middle;
	}

	ol
	{
		margin: calc(var(--margin-large) - var(--margin-small)) auto;
		padding: 0;
		width: fit-content;
		list-style-type: none;
	}

	li:not(.display-table-row),
	li.display-table-row > .display-table-cell
	{
		padding: var(--margin-medium) 0;
	}

	li:not(.display-table-row)
	{
		padding-left: var(--margin-medium);
		padding-right: var(--margin-medium);
	}

	li.display-table-row > .display-table-cell
	{
		padding-left: var(--margin-medium);
	}

	li.display-table-row > .display-table-cell:last-child
	{
		padding-right: var(--margin-medium);
	}

	li:not(.display-table-row) > *:not(:last-child)
	{
		padding-bottom: var(--margin-medium);
	}

	.dl-grid > dt,
	.align-middle
	{
		vertical-align: middle;
	}

	.button-container
	{
		display: inline-block;
	}

	.button-container:not(:last-child)
	{
		padding-right: var(--margin-small);
	}

	.delete-button-container
	{
		float: right;
	}

	.flow-root
	{
		display: flow-root;
	}
</style>
