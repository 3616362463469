<script lang="ts">
    import { onDestroy, onMount } from 'svelte';
    import { rafLoop } from '../../async';
    import { Lexer, type Expression, type Token, Parser, type LangError } from '../../complexTagFilterLang';

	export let expression: Expression | null = null;
	export let defaultString: string | null = null;

	function htmlEscape (str: string): string
	{
		return str.replace(
			/&<>'"/gu,
			char =>
			{
				switch (char)
				{
					case '&':
						return '&amp';

					case '<':
						return '&lt;';

					case '>':
						return '&gt;';

					case '"':
						return '&quot;';

					case '\'':
						return '&apos;';

					default:
						return char;
				}
			}
		);
	}

	let input: HTMLTextAreaElement | undefined;
	let textRenderer: HTMLElement | undefined;
	let errorOverlay: HTMLElement | undefined;
	let errorDisplay: HTMLDialogElement | undefined;
	let html = '';
	let errorBreakdown: LangError[] = [];
	let expressionApplicationTimeout: ReturnType<typeof setTimeout> | undefined;
	const expressionTimeoutAmount = 1000;

	function populateErrorOverlay (inputValue: string, errorOverlay: HTMLElement): void
	{
		const hasErrorAtIndex = inputValue.split('').map((_, index) => errorBreakdown.some(error => index >= error.startOffset && index <= error.endOffset));

		const regions = hasErrorAtIndex.reduce(
			(regions, errorHere, index) =>
			{
				const currentRegion = regions.length === 0 ? null : regions[regions.length - 1];
				if (errorHere)
				{
					if (!currentRegion)
					{
						regions.push({
							startOffset: index,
							endOffset: -1
						});
					}
				}
				else if (currentRegion?.endOffset === -1)
				{
					currentRegion.endOffset = index - 1;
				}
				return regions;
			},
			[] as { startOffset: number; endOffset: number; }[]
		);
		if (regions.length !== 0 && regions[regions.length - 1].endOffset === -1)
		{
			regions[regions.length - 1].endOffset = hasErrorAtIndex.length;
		}

		let textOffset = 0;
		for (const region of regions)
		{
			const precedingTextNode = document.createTextNode(inputValue.substring(0, region.startOffset));
			errorOverlay.appendChild(precedingTextNode);
			const errorElement = document.createElement('span');
			errorElement.textContent = inputValue.substring(region.startOffset, region.endOffset);
			errorOverlay.appendChild(errorElement);
			textOffset = region.endOffset;
		}
		const textNode = document.createTextNode(inputValue.substring(textOffset));
		errorOverlay.appendChild(textNode);
	}

	function onInput (): void
	{
		if (!input || !textRenderer || !errorOverlay)
		{
			return;
		}

		errorOverlay.textContent = '';
		errorBreakdown = [];
		html = htmlEscape(input.value); // Just in case something throws, the user will still see *something*
		clearTimeout(expressionApplicationTimeout);
		expressionApplicationTimeout = setTimeout(() =>
		{
			expressionApplicationTimeout = undefined;
			expression = null;
		}, expressionTimeoutAmount);

		const lexer = new Lexer(input.value);
		const lexResult = lexer.lex();
		const tokens = lexResult.tokens;
		html = '';
		for (const token of tokens)
		{
			html += `<span class="${htmlEscape(token.kind)}">${htmlEscape(token.rawValue)}</span>`;
		}
		if (lexResult.errors.length !== 0)
		{
			errorBreakdown = lexResult.errors;
			populateErrorOverlay(input.value, errorOverlay);
		}

		if (tokens.length !== 0 && lexResult.errors.length === 0)
		{
			const parser = new Parser(tokens);
			const parseResult = parser.parse();
			clearTimeout(expressionApplicationTimeout);
			expressionApplicationTimeout = setTimeout(() =>
			{
				expressionApplicationTimeout = undefined;
				expression = parseResult.errors.length === 0 ? parseResult.expression : null;
			}, expressionTimeoutAmount);
			if (parseResult.errors.length !== 0)
			{
				errorBreakdown = parseResult.errors;
				populateErrorOverlay(input.value, errorOverlay);
			}
		}
	}

	let errorText = '';

	const unsub = rafLoop(() =>
	{
		if (!input || !textRenderer || !errorOverlay || !errorDisplay)
		{
			return;
		}

		const inputRect = input.getBoundingClientRect();
		for (const renderer of [textRenderer, errorOverlay])
		{
			renderer.style.left = `${inputRect.left}px`;
			renderer.style.top = `${inputRect.top}px`;
			renderer.style.width = `${inputRect.width}px`;
			renderer.style.height = `${inputRect.height}px`;
		}

		errorText = '';
		const start = Math.min(input.selectionStart, input.selectionEnd);
		const end = Math.max(input.selectionStart, input.selectionEnd);
		for (const error of errorBreakdown)
		{
			if (start <= error.endOffset && end >= error.startOffset)
			{
				errorText += '\n' + error.message;
			}
		}
		errorText = errorText.trim();
		errorDisplay.textContent = errorText;
		errorDisplay.style.left = `${inputRect.left}px`;
		errorDisplay.style.bottom = `${(window.innerHeight - inputRect.bottom) + inputRect.height}px`;
		errorDisplay.style.maxWidth = `${inputRect.width}px`;
	});
	onDestroy(unsub);

	onMount(() =>
	{
		if (input && defaultString)
		{
			input.value = defaultString;
			onInput();
		}
	});
</script>

<div
	contenteditable
	class="text-renderer syntax-highlighting"
	bind:this={textRenderer}>
	{@html html}
</div>
<textarea
	spellcheck="false"
	autocapitalize="false"
	autocorrect="false"
	placeholder="Enter tag query..."
	bind:this={input}
	on:input={onInput} />
<div
	contenteditable
	class="error-overlay"
	bind:this={errorOverlay} />
<dialog
	class="error-display"
	open={errorText.length !== 0}
	bind:this={errorDisplay} />
<p><a href="/help/ctf">Read Tutorial</a></p>

<style lang="scss">
	textarea,
	.text-renderer,
	.error-overlay,
	.error-display
	{
		box-sizing: border-box;
		font-family: var(--monospace-font-family);
		white-space: pre-wrap;
		overflow-wrap: break-word;
	}

	textarea,
	.text-renderer,
	.error-overlay
	{
		width: 100%;
		height: 8em;
	}

	textarea,
	.error-overlay
	{
		color: transparent;
		background-color: transparent;
	}

	textarea
	{
		position: relative; // Needed for z-index to work
		resize: vertical;
		z-index: 1;
	}

	.text-renderer,
	.error-overlay,
	.error-display
	{
		position: fixed;
	}

	.text-renderer,
	.error-overlay
	{
		pointer-events: none;
		user-select: none;
	}

	.error-overlay
	{
		z-index: 2;
	}

	.error-overlay > :global(span)
	{
		text-decoration: underline wavy #F14C4C;
	}

	.error-display
	{
		margin: 0;
		border-color: var(--error-color);
		z-index: 3;
	}
</style>
