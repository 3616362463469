/// <reference path="../../shared/ambient.d.ts" />

import App from './components/App.svelte';
import { createErrorDialog } from './dialogs';
import './complexTagFilterLang';

function handleError (e: unknown): void
{
	createErrorDialog(dialog =>
	{
		const h1 = document.createElement('h1');
		h1.className = 'warning';
		h1.style.fontSize = '1.5rem';
		h1.style.fontWeight = 'bold';
		h1.style.textAlign = 'center';
		h1.style.marginTop = '0';
		h1.textContent = 'Unhandled Exception';
		dialog.appendChild(h1);
		let p = document.createElement('p');
		p.style.whiteSpace = 'pre-wrap';
		p.style.fontFamily = 'monospace';
		p.textContent = String(e);
		dialog.appendChild(p);
		p = document.createElement('p');
		p.style.marginBottom = '0';
		p.textContent = 'See browser console for a stack trace (if one is available).';
		dialog.appendChild(p);
	});
}

window.addEventListener('error', event => handleError(event.error));
window.addEventListener('unhandledrejection', event => handleError(event.reason));

const svelteContainer = document.getElementById('svelte-container');
if (svelteContainer === null)
{
	throw new Error('Could not find #svelte-container');
}
const app = new App({
	target: svelteContainer,
	props: {}
});

export default app;
