<script lang="ts">
	import { hyphenateSync } from 'hyphen/en';
	import type { ChapterData } from '../../../../shared/common';
	import type { GroupedChapter, Log, ReadingList } from '../../../../shared/database';
	import ExternalLink from '../input/ExternalLink.svelte';
	import type { StoryAndChapterMap } from './LogViewer.svelte';
    import { getEarliestGroupedChapter } from '../../settings';

	export let log: Log;
	export let storyAndChapterData: StoryAndChapterMap | undefined;
	export let oldReadingList: ReadingList | undefined;
	export let newReadingList: ReadingList | undefined;
</script>

{#if log.actionType === 'add'}
	<em><span class="no-break">[n</span>o read&shy;ing lis<span class="no-break">t]</span></em>
	→
	{newReadingList?.name ?? '[un\u00ADknown read\u00ADing list]'}
{:else if log.actionType === 'move'}
	{oldReadingList?.name ?? '[un\u00ADknown read\u00ADing list]'}
	→
	{newReadingList?.name ?? '[un\u00ADknown read\u00ADing list]'}
{:else if log.actionType === 'change'}
	{@const oldChapterData = storyAndChapterData && log.oldChapterId !== null ? storyAndChapterData.chapterMap.get(log.oldChapterId) : undefined}
	{@const newChapterData = storyAndChapterData && log.newChapterId !== null ? storyAndChapterData.chapterMap.get(log.newChapterId) : undefined}
	{#if oldChapterData === undefined}
		<em><span class="no-break">[n</span>o prog&shy;res<span class="no-break">s]</span></em>
	{:else}
		{@const earliestGroupedChapter = storyAndChapterData ? getEarliestGroupedChapter(storyAndChapterData.options, oldChapterData) : undefined}
		{#if earliestGroupedChapter}
			<ExternalLink href={earliestGroupedChapter.url}>
				{hyphenateSync(earliestGroupedChapter.title)}
			</ExternalLink>
		{:else}
			<em><span class="no-break">[c</span>ouold not find grouped chap$shy;te<span class="no-break">r]</span></em>
		{/if}
	{/if}
	→
	{#if newChapterData === undefined}
		<em><span class="no-break">[u</span>n&shy;known chap&shy;te<span class="no-break">r]</span></em>
	{:else}
		{@const earliestGroupedChapter = storyAndChapterData ? getEarliestGroupedChapter(storyAndChapterData.options, newChapterData) : undefined}
		{#if earliestGroupedChapter}
			<ExternalLink href={earliestGroupedChapter.url}>
				{hyphenateSync(earliestGroupedChapter.title)}
			</ExternalLink>
		{:else}
			<em><span class="no-break">[c</span>ouold not find grouped chap$shy;te<span class="no-break">r]</span></em>
		{/if}
	{/if}
{:else}
	{oldReadingList?.name ?? '[un\u00ADknown read\u00ADing list]'}
	→
	<em><span class="no-break">[n</span>o read&shy;ing lis<span class="no-break">t]</span></em>
{/if}
