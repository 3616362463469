<script lang="ts">
	import { createEventDispatcher, onDestroy, onMount } from 'svelte';
	import type { SiteData, StoryData, UndoData } from '../../../shared/common';
	import type { ReadingList, ReadingList as ReadingListType, SiteId, StoryStatus, UserSettings } from '../../../shared/database';
	import { ApiError, apiAddStoryToReadingList, apiGetLogs, type AbortManager } from '../api';
	import { createErrorDialog } from '../dialogs';
	import ComplexTextSearch from './ComplexTextSearch.svelte';
	import LogViewer from './logs/LogViewer.svelte';
	import SortControl, { type ChangeEventDetail } from './SortControl.svelte';
	import type { SortDirection } from './SortDirectionControl.svelte';
	import { getStoryKey, type StoryKey } from '../keying';
	import { sortSites } from '../sort';
	import { getSiteName, getStoryTitle } from '../settings';
	import SiteIcon from './SiteIcon.svelte';
	import type { PartialReadingListData, ReadingListData } from './ReadingListSwitcher.svelte';
	import { writable, type Readable, type Writable, derived } from 'svelte/store';
	import { allStoryStatuses, bidirectionalDerived, globalDisable, showNsfwContent } from '../state';
	import StoryListEntry from './StoryListEntry.svelte';
	import IconButton from './input/IconButton.svelte';
	import IconPaste from './svg-icons/IconPaste.svelte';
	import IconBook from './svg-icons/IconBook.svelte';
	import IconFilter from './svg-icons/IconFilter.svelte';
	import IconEdit from './svg-icons/IconEdit.svelte';
	import DynamicGrid from './layout/dynamic-grid/DynamicGrid.svelte';
	import DynamicGridCell from './layout/dynamic-grid/DynamicGridCell.svelte';
    import StoryLoading from './StoryLoading.svelte';
    import { normalizeForComparison } from '../text';
    import { arrayDiff } from '../arrays';
    import NsfwFilterReport from './NsfwFilterReport.svelte';
    import ThreeStateButton from './input/ThreeStateButton.svelte';
    import ReadingListFilters from './ReadingListFilters.svelte';
    import type { TagSet } from '../tags';
    import { rafLoop } from '../async';

	export let allListData: Writable<Map<bigint, PartialReadingListData>>;
	export let allStoryData: Writable<Map<bigint, ReadingListData['stories']>>;
	export let allTags: Writable<TagSet>;
	export let completionData: Readable<Map<bigint, boolean>>;
	export let userSettings: UserSettings;
	export let listId: bigint;
	export let numStories: Partial<Readonly<Record<SiteId, number>>> | undefined;
	export let siteData: SiteData;
	export let subscribeUpdateMessages: readonly string[];
	export let subscribeAbortManager: AbortManager | undefined;
	export let subscribeStartTime: number | undefined;

	const dispatcher = createEventDispatcher<{
		requestSubscribe: {
			listId: bigint;
			url: string;
		};
	}>();

	$: allowAbort = subscribeAbortManager?.allowed;

	$: currentReadingList = (() =>
	{
		const crl = $allListData.get(listId);
		if (crl?.readingList === undefined)
		{
			throw new RangeError('Bad component init: currentReadingList is undefined');
		}
		return crl.readingList;
	})();
	const currentStoryData = bidirectionalDerived(
		[allStoryData],
		allStoryData =>
		{
			const csd = allStoryData.get(listId);
			if (csd === undefined)
			{
				throw new RangeError('Bad component init: currentStoryData is undefined');
			}
			return csd;
		},
		(currentStoryData, allStoryData) =>
		{
			allStoryData.set(listId, currentStoryData);
			return [allStoryData];
		}
	);

	// This is a hack to make sure the contenteditable this is bound to always has a height
	let inputUrl = '\u2060';

	function subscribeToStory (): void
	{
		let safeInputUrl = inputUrl.replace(/\u2060/gu, '');
		try
		{
			new URL(safeInputUrl);
		}
		catch
		{
			try
			{
				const tryUrl = `https://${safeInputUrl}`;
				new URL(tryUrl);
				safeInputUrl = tryUrl;
			}
			catch
			{
				createErrorDialog(`Invalid URL "${safeInputUrl}"`);
				return;
			}
		}

		dispatcher('requestSubscribe', {
			listId: currentReadingList.listId,
			url: safeInputUrl
		});
		inputUrl = '\u2060';
	}

	let showLogs = false;
	let showFilters = false;
	let logViewer: LogViewer | undefined;

	$: currentStoryDataBySite = Array.from($currentStoryData.values()).reduce(
		(record, storyData) =>
		{
			let n = record[storyData.story.site];
			if (n === undefined)
			{
				n = 1;
			}
			else
			{
				n++;
			}
			record[storyData.story.site] = n;
			return record;
		},
		{} as Partial<Record<SiteId, number>>
	);
	$: numUnloadedStoriesBySite = numStories === undefined ? undefined : Object.fromEntries(
		Object.entries(numStories).map(([site, n]) => [site, n - (currentStoryDataBySite[site] ?? 0)])
	);

	let filteredAndSortedStoryKeys = writable(Array.from($currentStoryData.keys()));
	$: filteredAndSortedStoryData = $filteredAndSortedStoryKeys.map(key => $currentStoryData.get(key)).filter((storyData): storyData is StoryData => storyData !== undefined);

	const csdUnsub = currentStoryData.subscribe(csd =>
	{
		if (!showFilters)
		{
			$filteredAndSortedStoryKeys = Array.from(csd.keys());
		}
	});
	onDestroy(csdUnsub);

	let readingListFilters: ReadingListFilters | undefined;

	let currentTime = Date.now();
	const rafUnsub = rafLoop(() =>
	{
		currentTime = Date.now();
	});
	onDestroy(rafUnsub);

	$: displayAbortButton = $allowAbort && subscribeStartTime !== undefined && (currentTime - subscribeStartTime) >= 10000;
</script>

<article>
	<form class="p-like align-top" on:submit|preventDefault|stopPropagation={subscribeToStory}>
		<span
			class="url-input"
			contenteditable
			placeholder="Story URL..."
			data-content-length={String(inputUrl.length)}
			bind:textContent={inputUrl} />
		<IconButton
			icon={IconPaste}
			alt="Paste URL"
			classes={{
				secondary: true
			}}
			disabled={$globalDisable}
			on:click={async () => inputUrl = await navigator.clipboard.readText()} />
		<div class="p-like">
			<input type="submit" disabled={$globalDisable} value="Subscribe" />
		</div>
	</form>

	{#if displayAbortButton && subscribeAbortManager}
		<div class="p-like">
			<!-- Ignoring $globalDisable on purpose -->
			<button
				type="button"
				on:click={() => $allowAbort && subscribeAbortManager?.controller.abort()}>
				Abort
			</button>
		</div>
	{/if}

	{#if subscribeUpdateMessages.length !== 0}
		<ol class="p-like subscribe-loading-list" reversed>
			{#each subscribeUpdateMessages as message}
				<li>{message}</li>
			{/each}
		</ol>
	{/if}

	<hr />

	<div class="p-like">
		<IconButton
			icon={IconBook}
			alt={showLogs ? 'Hide Logs' : 'Show Logs'}
			classes={{
				secondary: !showLogs
			}}
			disabled={$globalDisable}
			on:click={() => showLogs = !showLogs} />
		<IconButton
			icon={IconFilter}
			alt={showFilters ? 'Hide Filters' : 'Show Filters'}
			classes={{
				secondary: !showFilters
			}}
			disabled={$globalDisable}
			on:click={() => showFilters = !showFilters} />
	</div>

	{#if showLogs}
		{#await apiGetLogs(currentReadingList.listId)}
			<p>Loading logs...</p>
		{:then logs}
			<div>
				<LogViewer
					logs={logs}
					allListData={allListData}
					currentStoryData={currentStoryData}
					sites={siteData.sites}
					userSettings={userSettings}
					disableUndo={$globalDisable}
					bind:this={logViewer} />
			</div>
		{:catch e}
			{@const _ = createErrorDialog(e.message)}
		{/await}
	{/if}

	<ReadingListFilters
		visible={showFilters}
		currentStoryData={currentStoryData}
		allTags={allTags}
		siteData={siteData}
		userSettings={userSettings}
		acceptableOverall={filteredAndSortedStoryKeys}
		bind:this={readingListFilters} />

	{#if showLogs || showFilters}
		<hr />
	{/if}

	{#if $currentStoryData.size === 0}
		{#if $completionData.get(currentReadingList.listId)}
			<p>Reading list is empty.</p>
		{:else}
			<p><StoryLoading numUnloadedStoriesBySite={numUnloadedStoriesBySite} sites={siteData.sites} /></p>
		{/if}
	{:else}
		{@const displayedStoryData = filteredAndSortedStoryData.filter(
			storyData => ($showNsfwContent || !(storyData.options.optNsfw ?? storyData.story.isNsfw))
		)}
		{@const nsfwHiddenStories = arrayDiff(filteredAndSortedStoryData, displayedStoryData)}
		{#if displayedStoryData.length === 0}
			{#if $completionData.get(currentReadingList.listId)}
				<p>No stories match the current filter settings.</p>
			{:else}
				<p><StoryLoading numUnloadedStoriesBySite={numUnloadedStoriesBySite} sites={siteData.sites} /></p>
			{/if}
			<NsfwFilterReport hiddenStories={nsfwHiddenStories} userSettings={userSettings} sites={siteData.sites} />
		{:else}
			{#if !$completionData.get(currentReadingList.listId)}
				<p><StoryLoading numUnloadedStoriesBySite={numUnloadedStoriesBySite} sites={siteData.sites} /></p>
			{/if}
			<NsfwFilterReport hiddenStories={nsfwHiddenStories} userSettings={userSettings} sites={siteData.sites} />

			<!--
				If this #if check isn't here we get a "cannot access property on undefined" error when
				initial page load happens on the Settings tab, and then you switch to a reading list.
				TypeScript type inference thinks this check is unnecessary but it is wrong.
			-->
			{#if readingListFilters}
				<ol class="story-list">
					{#each displayedStoryData as dummyStoryData, index (getStoryKey(dummyStoryData))}
						<StoryListEntry
							dummyStoryData={dummyStoryData}
							currentStoryData={currentStoryData}
							currentListId={currentReadingList.listId}
							allListData={allListData}
							allTags={allTags}
							site={siteData.sites[dummyStoryData.story.site]}
							allSites={siteData.sites}
							userSettings={userSettings}
							filterInteractions={readingListFilters.interactions}
							isLastStory={index === displayedStoryData.length - 1}
							on:logCreated={event => logViewer?.addLog(event.detail.log)} />
					{/each}
				</ol>
			{:else}
				<p>Loading filters...</p>
			{/if}
		{/if}
	{/if}
</article>

<style lang="scss">
	.subscribe-loading-list
	{
		padding-left: 2ch;
		list-style-type: '> ';
	}

	.story-list
	{
		margin-bottom: 0;
		padding: 0;
		list-style-type: none;
	}

	.align-top > *
	{
		vertical-align: top;
	}

	.url-input
	{
		max-width: calc(100% - 62px);
	}
</style>
