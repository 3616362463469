import type { Site, SiteId, Story } from '../../shared/database';

export interface PartialStory
{
	readonly site: SiteId;
	readonly storyId: string;
}

export interface PartialStoryData
{
	readonly story: PartialStory;
}

export type StoryKey = `${SiteId}/${string}`;

export function getStoryKey (story: PartialStory | PartialStoryData): StoryKey;
export function getStoryKey (site: SiteId, storyId: string): StoryKey;
export function getStoryKey (a: PartialStory | PartialStoryData | SiteId, b?: string): StoryKey
{
	let site: SiteId;
	let storyId: string;
	if (typeof a === 'string')
	{
		site = a;
		storyId = b!;
	}
	else if ('story' in a)
	{
		site = a.story.site;
		storyId = a.story.storyId;
	}
	else
	{
		site = a.site;
		storyId = a.storyId;
	}
	return `${site}/${storyId}`;
}
