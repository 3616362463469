import { normalizeForComparison } from './text';

export class NormalizedSet implements Set<string>
{
	readonly #set = new Set<string>();
	readonly #canonical = new Map<string, string>();
	readonly [Symbol.toStringTag] = 'Set';

	constructor (iterable?: Iterable<string> | null)
	{
		if (iterable)
		{
			for (const item of iterable)
			{
				this.add(item);
			}
		}
	}

	get size (): number
	{
		return this.#set.size;
	}

	protected normalize (item: string): string
	{
		return normalizeForComparison(item);
	}

	add (item: string): this
	{
		const norm = this.normalize(item);
		this.#set.add(norm);
		this.#canonical.set(norm, item);
		return this;
	}

	clear (): void
	{
		this.#set.clear();
		this.#canonical.clear();
	}

	delete (item: string): boolean
	{
		const norm = this.normalize(item);
		const res = this.#set.delete(norm);
		this.#canonical.delete(norm);
		return res;
	}

	forEach (callbackfn: (item: string, item2: string, set: NormalizedSet) => void, thisArg?: any): void
	{
		this.#set.forEach((value, value2) =>
		{
			callbackfn.call(
				thisArg,
				this.#canonical.get(value) ?? value,
				this.#canonical.get(value2) ?? value2,
				this
			);
		});
	}

	has (item: string): boolean
	{
		return this.#set.has(this.normalize(item));
	}

	[Symbol.iterator] (): IterableIterator<string>
	{
		return this.#canonical.values();
	}

	* entries (): IterableIterator<[string, string]>
	{
		for (const value of this.#canonical.values())
		{
			yield [value, value];
		}
	}

	keys (): IterableIterator<string>
	{
		return this.#canonical.values();
	}

	values (): IterableIterator<string>
	{
		return this.#canonical.values();
	}
}

export class NormalizedMap<T> implements Map<string, T>
{
	readonly #map = new Map<string, T>();
	readonly #canonical = new Map<string, string>();
	readonly [Symbol.toStringTag] = 'Map';

	constructor (iterable?: Iterable<readonly [string, T]> | null)
	{
		if (iterable)
		{
			for (const [key, value] of iterable)
			{
				this.set(key, value);
			}
		}
	}

	get size (): number
	{
		return this.#map.size;
	}

	protected normalize (key: string): string
	{
		return normalizeForComparison(key);
	}

	clear (): void
	{
		this.#map.clear();
	}

	delete (key: string): boolean
	{
		const norm = this.normalize(key);
		const res = this.#map.delete(norm);
		this.#canonical.delete(norm);
		return res;
	}

	forEach (callbackfn: (value: T, key: string, map: NormalizedMap<T>) => void, thisArg?: any): void
	{
		this.#map.forEach((value, key) =>
		{
			callbackfn.call(
				thisArg,
				value,
				this.#canonical.get(key) ?? key,
				this
			)
		});
	}

	get (key: string): T | undefined
	{
		return this.#map.get(this.normalize(key));
	}

	has (key: string): boolean
	{
		return this.#map.has(this.normalize(key));
	}

	set (key: string, value: T): this
	{
		const norm = this.normalize(key);
		this.#map.set(norm, value);
		this.#canonical.set(norm, key);
		return this;
	}

	* [Symbol.iterator] (): IterableIterator<[string, T]>
	{
		for (const [key, value] of this.#map)
		{
			yield [this.#canonical.get(key) ?? key, value];
		}
	}

	entries (): IterableIterator<[string, T]>
	{
		return this[Symbol.iterator]();
	}

	keys (): IterableIterator<string>
	{
		return this.#canonical.values();
	}

	values (): IterableIterator<T>
	{
		return this.#map.values();
	}
}
