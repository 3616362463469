<script context="module" lang="ts">
	const onSelectKey = Symbol('TabList On Select');

	export function getOnSelect (): () => void
	{
		return getContext(onSelectKey);
	}
</script>

<script lang="ts">
	import { getContext, setContext } from 'svelte';

	import HamburgerMenu from '../HamburgerMenu.svelte';
	import type { IconComponent } from '../../input/IconButton.svelte';
	import { getCurrentTab, type TabId } from './TabGroup.svelte';

	export let collapse = false;
	export let collapseIcon: IconComponent | undefined = undefined;
	export let collapseAlt = '';
	export let collapseAlign: 'left' | 'right' = 'left';
	export let collapseButtonActive = false;
	export let collapseButtonClasses: readonly string[] = [];
	export let collapseSelectedButtonClasses: readonly string[] = collapseButtonClasses;
	export let collapseDisabled = false;
	export let collapsedItemIsSelected: ((tabId: TabId) => boolean) | undefined = undefined;
	export let dialogIsOpen = false;
	export let containerClasses: readonly string[] = [];

	let menu: HamburgerMenu | undefined;

	const onSelect = () =>
	{
		menu?.close();
	};
	setContext(onSelectKey, onSelect);

	const currentTab = getCurrentTab();

	$: collapseClasses = collapsedItemIsSelected?.($currentTab) ? collapseSelectedButtonClasses : collapseButtonClasses;
</script>

<div class={'tab-picker ' + containerClasses.join(' ')}>
	<slot />
	{#if collapse}
		<HamburgerMenu
			icon={collapseIcon}
			alt={collapseAlt}
			align={collapseAlign}
			buttonActive={collapseButtonActive}
			classes={Object.fromEntries(collapseClasses.map(className => [className, true]))}
			disabled={collapseDisabled}
			bind:isOpen={dialogIsOpen}
			bind:this={menu}>
			<slot name="collapsible" />
		</HamburgerMenu>
	{:else}
		<slot name="collapsible" />
	{/if}
</div>

<style lang="scss">
	.wrap
	{
		white-space: normal;
	}
</style>
