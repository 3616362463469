<script context="module" lang="ts">
	const cellWidthSymbol = Symbol('Cell Width');

	function getCellWidthsMap (): Map<number, Writable<Map<symbol, number>>>
	{
		return getContext(cellWidthSymbol);
	}

	export function getCellWidthsStore (group: number): Writable<Map<symbol, number>>
	{
		const map = getCellWidthsMap();
		let store = map.get(group);
		if (!store)
		{
			store = writable(new Map());
			map.set(group, store);
		}
		return store;
	}
</script>

<script lang="ts">
	import { getContext, setContext } from 'svelte';
	import { writable, type Writable } from 'svelte/store';

	const cellWidthMap = new Map<string, Writable<number>>();
	setContext(cellWidthSymbol, cellWidthMap);
</script>

<slot />
