<script context="module" lang="ts">
	export interface EventArgsMap
	{
		logCreated: {
			log: Log;
		};
	}
</script>

<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import type { ChapterData, StoryData } from '../../../shared/common';
	import type { Group, GroupedChapter, Log, Site, Story, UserSettings } from '../../../shared/database';
	import { ApiError, apiUpdateStoryLastReadChapter } from '../api';
	import QuickChapterLink from './QuickChapterLink.svelte';
	import ExternalLink from './input/ExternalLink.svelte';
	import ComplexTextSearch from './ComplexTextSearch.svelte';
	import SortDirectionControl, { type SortDirection } from './SortDirectionControl.svelte';
	import { toRelativeTimeString } from '../time';
	import { createErrorDialog } from '../dialogs';
	import { getChapterEarliestPublishedDate, getChaptersInDescendingOrder, getGroupedChapterPublishedDate, getGroupedChapterUrl } from '../settings';
	import type { Writable } from 'svelte/store';
	import { globalDisable, useMobileLayout, type DisableManagerLike } from '../state';
	import type { StoryKey } from '../keying';
	import IconButton from './input/IconButton.svelte';
	import IconMinimize from './svg-icons/IconMinimize.svelte';
	import IconMaximize from './svg-icons/IconMaximize.svelte';
	import IconSync from './svg-icons/IconSync.svelte';
	import IconSquareOutline from './svg-icons/IconSquareOutline.svelte';
	import IconSquareCheck from './svg-icons/IconSquareCheck.svelte';
    import IconEdit from './svg-icons/IconEdit.svelte';

	export let site: Site;
	export let storyData: Writable<StoryData>;
	export let storyDisable: DisableManagerLike;
	export let userSettings: UserSettings;
	export let allowEdits: Writable<boolean>;
	export let isLastStory: boolean;

	const dispatcher = createEventDispatcher<EventArgsMap>();

	function emitLogCreated (log: Log): void
	{
		dispatcher('logCreated', {
			log: log
		});
	}

	let expanded = false;
	let readChaptersAreContiguous = true;

	$: chaptersInDescendingOrder = getChaptersInDescendingOrder($storyData, site);

	$: lastReadChapter = chaptersInDescendingOrder.find(chapter => chapter.read);
	$: {
		if (lastReadChapter === undefined)
		{
			readChaptersAreContiguous = true;
		}
		else
		{
			const index = chaptersInDescendingOrder.indexOf(lastReadChapter);
			if (index === -1)
			{
				readChaptersAreContiguous = true;
			}
			else
			{
				readChaptersAreContiguous = chaptersInDescendingOrder.slice(index + 1).every(chapterData => chapterData.read);
			}
		}
	}
	$: firstUnreadChapter = chaptersInDescendingOrder.slice().reverse().find(chapter => !chapter.read);
	$: latestChapter = chaptersInDescendingOrder[0];

	async function markAllAsRead (): Promise<void>
	{
		await storyDisable.runAction(async () =>
		{
			const toChange: ChapterData[] = [];
			for (const data of chaptersInDescendingOrder)
			{
				if (!data.read)
				{
					toChange.push(data);
				}
			}
			if (toChange.length !== 0)
			{
				try
				{
					const log = await apiUpdateStoryLastReadChapter(
						$storyData.story.site,
						$storyData.story.storyId,
						latestChapter.chapter.chapterId
					);
					storyData.update(storyData =>
					{
						for (const data of toChange)
						{
							data.read = true;
						}
						return storyData;
					});
					if (log)
					{
						emitLogCreated(log);
					}
				}
				catch (e)
				{
					if (!(e instanceof ApiError))
					{
						throw e;
					}
					createErrorDialog(e.message);
				}
			}
		});
	}

	async function markBackfillAsRead (): Promise<void>
	{
		if (lastReadChapter)
		{
			await markUpToChapterAsRead(lastReadChapter);
		}
	}

	async function markUpToChapterAsRead (referenceChapterData: ChapterData): Promise<void>
	{
		await storyDisable.runAction(async () =>
		{
			const toChange: { data: ChapterData; targetValue: boolean }[] = [];
			for (const data of chaptersInDescendingOrder)
			{
				if (data.chapter.order <= referenceChapterData.chapter.order && !data.read)
				{
					toChange.push({
						data: data,
						targetValue: true
					});
				}
				else if (data.chapter.order > referenceChapterData.chapter.order && data.read)
				{
					toChange.push({
						data: data,
						targetValue: false
					});
				}
			}
			if (toChange.length !== 0)
			{
				try
				{
					const log = await apiUpdateStoryLastReadChapter(
						$storyData.story.site,
						$storyData.story.storyId,
						referenceChapterData.chapter.chapterId
					);
					storyData.update(storyData =>
					{
						for (const { data, targetValue } of toChange)
						{
							data.read = targetValue;
						}
						return storyData;
					});
					if (log)
					{
						emitLogCreated(log);
					}
				}
				catch (e)
				{
					if (!(e instanceof ApiError))
					{
						throw e;
					}
					createErrorDialog(e.message);
				}
			}
		});
	}

	function toggleExpansion (): void
	{
		expanded = !expanded;
	}

	let expandedSortDirection: SortDirection = 'desc';
	let acceptedChapterIds: string[] | undefined;
	$: displayedChapters = (() =>
	{
		let res = expandedSortDirection === 'desc' ? chaptersInDescendingOrder :
			chaptersInDescendingOrder.slice().reverse();
		if (acceptedChapterIds)
		{
			res = res.filter(chapterData => acceptedChapterIds?.includes(chapterData.chapter.chapterId));
		}
		return res;
	})();
</script>

<div>
	{#if latestChapter !== undefined}
		<IconButton
			icon={expanded ? IconMinimize : IconMaximize}
			alt={expanded ? 'Close Expanded View' : 'Open Expanded View'}
			classes={{
				secondary: true
			}}
			disabled={$globalDisable || $storyDisable}
		on:click={toggleExpansion} />
	{/if}
	<IconButton
		icon={IconEdit}
		alt={$allowEdits ? 'Disable Edit Mode' : 'Enable Edit Mode'}
		classes={{
			secondary: !$allowEdits
		}}
		disabled={$globalDisable || $storyDisable}
		on:click={() => $allowEdits = !$allowEdits} />
</div>

{#if latestChapter === undefined}
	{#if $storyData.chapters.length === 0}
		<p>Story has no chapters.</p>
	{:else}
		<p>Story has no free chapters. Enter Edit mode and enable "Show Premium Chapters"{$storyData.roles.length !== 0 ? ', and then enable the relevant roles' : ''}.</p>
	{/if}
{:else if expanded}
	<div class="p-like">
		<SortDirectionControl
			direction={expandedSortDirection}
			disabled={$globalDisable || $storyDisable}
			on:change={event => expandedSortDirection = event.detail.direction} />
		<ComplexTextSearch
			placeholder="Filter chapters by title..."
			items={chaptersInDescendingOrder}
			itemToSearchableStrings={chapterData => chapterData.groupedChapters.map(groupedChapter => groupedChapter.title)}
			getItemId={chapterData => chapterData.chapter.chapterId}
			inline
			on:filter={event => acceptedChapterIds = event.detail.acceptedItemIds} />
	</div>
	<ol class="p-like display-table" reversed={expandedSortDirection === 'desc'}>
		{#each displayedChapters as chapterData (chapterData.chapter.chapterId)}
			{@const useWarningButton = !!lastReadChapter && chapterData.chapter.order < lastReadChapter.chapter.order && !chapterData.read}
			{@const icon = chapterData === lastReadChapter ? IconSync :
				lastReadChapter === undefined || chapterData.chapter.order > lastReadChapter.chapter.order ? IconSquareOutline :
				chapterData.read ? IconSquareCheck :
				IconSquareOutline}
			{@const alt = chapterData === lastReadChapter ? 'Current Chapter' :
				lastReadChapter === undefined || chapterData.chapter.order > lastReadChapter.chapter.order ? 'New Chapter' :
				chapterData.read ? 'Read Chapter' :
				'New Out-of-Order Chapter'}
			<li class="display-table-row" class:grouped={site.usesGroupedChapters && chapterData.groupedChapters.length >= 2}>
				<div class="display-table-cell chapter-checkbox-container">
					<IconButton
						icon={icon}
						alt={alt}
						classes={{
							warning: useWarningButton
						}}
						disabled={$globalDisable || $storyDisable || (chapterData === lastReadChapter && readChaptersAreContiguous)}
						on:click={() => markUpToChapterAsRead(chapterData)} />
				</div>
				{#if site.usesGroupedChapters}
					{@const groupedChapters = chapterData.groupedChapters.sort((a, b) => Number(getGroupedChapterPublishedDate($storyData.options, b) - getGroupedChapterPublishedDate($storyData.options, a)))}
					<div class="display-table-cell">
						{#if groupedChapters.length >= 2}
							<ul class="grouped-chapter-list">
								{#each groupedChapters as groupedChapter (groupedChapter.groupId)}
									{@const group = $storyData.groups.find(group => group.groupId === groupedChapter.groupId)}
									<li class="align-top">
										{#if $storyData.deleted}
											{groupedChapter.title}
										{:else}
											<ExternalLink href={getGroupedChapterUrl(userSettings, site, groupedChapter, $storyData.story.attrAlternateAvailability)}>
												{groupedChapter.title}
											</ExternalLink>
										{/if}
										{#if group !== undefined}
											<span class="small new-line-on-touch-devices paren-wrap">
												{#if group.url !== null}
													<ExternalLink href={group.url}>{group.name}</ExternalLink>
												{:else}
													{group.name}
												{/if}
											</span>
										{:else}
											<em class="small new-line-on-touch-devices paren-wrap">[no group]</em>
										{/if}
										<em class="small no-break new-line-on-touch-devices">({toRelativeTimeString(getGroupedChapterPublishedDate($storyData.options, groupedChapter))})</em>
									</li>
								{/each}
							</ul>
						{:else}
							{@const groupedChapter = chapterData.groupedChapters[0]}
							{@const group = $storyData.groups.find(group => group.groupId === groupedChapter.groupId)}
							{#if $storyData.deleted}
								{groupedChapter.title}
							{:else}
								<ExternalLink href={getGroupedChapterUrl(userSettings, site, groupedChapter, $storyData.story.attrAlternateAvailability)}>
									{groupedChapter.title}
								</ExternalLink>
							{/if}
							{#if group !== undefined}
								<span class="small new-line-on-touch-devices paren-wrap">
									{#if group.url !== null}
										<ExternalLink href={group.url}>{group.name}</ExternalLink>
									{:else}
										{group.name}
									{/if}
								</span>
							{:else}
								<em class="small new-line-on-touch-devices paren-wrap">[no group]</em>
							{/if}
							<em class="small no-break new-line-on-touch-devices">({toRelativeTimeString(getGroupedChapterPublishedDate($storyData.options, groupedChapter))})</em>
						{/if}
					</div>
				{:else}
					{@const groupedChapter = chapterData.groupedChapters[0]}
					<div class="display-table-cell">
						{#if $storyData.deleted}
							{groupedChapter.title}
						{:else}
							<ExternalLink href={getGroupedChapterUrl(userSettings, site, groupedChapter, $storyData.story.attrAlternateAvailability)}>
								{groupedChapter.title}
							</ExternalLink>
						{/if}
						<em class="small no-break relative-time">({toRelativeTimeString(getGroupedChapterPublishedDate($storyData.options, groupedChapter))})</em>
					</div>
				{/if}
			</li>
		{/each}
	</ol>
{:else}
	{@const hasUpdates = firstUnreadChapter !== undefined || !readChaptersAreContiguous}
	{#if !readChaptersAreContiguous}
		<p><span class="warning">WARNING:</span> Out-of-order update.</p>
	{/if}
	<dl class="p-like" class:dl-grid={!$useMobileLayout} class:final-div={isLastStory && !hasUpdates}>
		{#if latestChapter !== undefined && latestChapter !== lastReadChapter && latestChapter !== firstUnreadChapter}
			<dt>Latest</dt>
			<dd>
				<QuickChapterLink
					chapterData={latestChapter}
					userSettings={userSettings}
					entrySettings={$storyData.options}
					site={site}
					alternateAvailability={$storyData.story.attrAlternateAvailability}
					deleted={$storyData.deleted} />
				<em class="small no-break relative-time">({toRelativeTimeString(getChapterEarliestPublishedDate($storyData.options, latestChapter))})</em>
			</dd>
		{/if}
		{#if firstUnreadChapter !== undefined}
			{@const labelText = readChaptersAreContiguous ?
				firstUnreadChapter === latestChapter ? 'Next & Latest' : 'Next' :
				'First Out-of-Order'
			}
			<dt>{labelText}</dt>
			<dd>
				<QuickChapterLink
					chapterData={firstUnreadChapter}
					userSettings={userSettings}
					entrySettings={$storyData.options}
					site={site}
					alternateAvailability={$storyData.story.attrAlternateAvailability}
					deleted={$storyData.deleted} />
				<em class="small no-break relative-time">({toRelativeTimeString(getChapterEarliestPublishedDate($storyData.options, firstUnreadChapter))})</em>
			</dd>
		{/if}
		{#if lastReadChapter !== undefined && lastReadChapter !== firstUnreadChapter}
			<dt>Current</dt>
			<dd>
				<QuickChapterLink
					chapterData={lastReadChapter}
					userSettings={userSettings}
					entrySettings={$storyData.options}
					site={site}
					alternateAvailability={$storyData.story.attrAlternateAvailability}
					deleted={$storyData.deleted} />
				<em class="small no-break relative-time">({toRelativeTimeString(getChapterEarliestPublishedDate($storyData.options, lastReadChapter))})</em>
			</dd>
		{/if}
	</dl>
	{#if hasUpdates}
		<div class="normal-button-container p-like" class:final-div={isLastStory}>
			{#if firstUnreadChapter !== undefined}
				<button type="button" disabled={$globalDisable || $storyDisable} on:click={markAllAsRead}>Mark All as Read</button>
			{/if}
		</div>
	{/if}
{/if}

<style lang="scss">
	ol
	{
		padding-left: 0;
		list-style-type: none;
	}

	li
	{
		width: fit-content;
	}

	li:not(:last-child) > .display-table-cell
	{
		padding-bottom: var(--margin-medium);
	}

	li > :global(*)
	{
		vertical-align: top;
	}

	.final-div
	{
		margin-bottom: 0;
	}

	.chapter-checkbox-container
	{
		padding-right: var(--margin-medium);
	}

	.align-top > :global(*)
	{
		vertical-align: top;
	}

	.paren-wrap::before
	{
		content: '(';
	}

	.paren-wrap > :global(:last-child)
	{
		margin-right: -0.5ch;
	}

	.paren-wrap::after
	{
		content: ')';
	}

	@media screen and (pointer: none), screen and (pointer: coarse), screen and (max-width: 720px)
	{
		.chapter-checkbox-container
		{
			margin-right: var(--margin-small);
		}

		.new-line-on-touch-devices
		{
			display: block;
			margin-top: var(--margin-small);
		}
	}

	.normal-button-container > button:not(:first-child)
	{
		margin-left: var(--margin-medium);
	}

	.grouped
	{
		background-color: var(--grouped-chapters-bg-color);
		padding-right: var(--margin-medium);
	}

	.grouped-chapter-list
	{
		margin: 0;
		padding: 0 0 0 var(--margin-large);
		width: fit-content;
	}

	.grouped-chapter-list > li:first-child
	{
		margin-top: 0;
	}

	.grouped-chapter-list > li:last-child
	{
		margin-bottom: 0;
	}

	.small
	{
		vertical-align: middle;
		font-size: 0.8rem;
	}
</style>
