<script lang="ts">
    import { onDestroy } from 'svelte';
	import { getCurrentTab, type TabId } from './TabGroup.svelte';

	export let id: TabId;

	const currentTab = getCurrentTab();
	let keepInDom = $currentTab === id;
	const unsub = currentTab.subscribe(currentTab =>
	{
		keepInDom ||= currentTab === id;
	});
	onDestroy(unsub);
</script>

<div class:hidden={$currentTab !== id}>
	{#if keepInDom || $currentTab === id}
		<slot />
	{/if}
</div>

<style lang="scss">
	.hidden
	{
		display: none;
	}
</style>
