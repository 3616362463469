export type DialogMouseEvent = MouseEvent & {
	currentTarget: EventTarget & HTMLDialogElement;
};

export function closeDialogOnClickOut (event: DialogMouseEvent): void
{
	const dialog = event.currentTarget;
	const rect = dialog.getBoundingClientRect();
	const clickedInsideDialog = event.clientX >= rect.left &&
		event.clientX <= rect.right &&
		event.clientY >= rect.top &&
		event.clientY <= rect.bottom;
	if (!clickedInsideDialog)
	{
		dialog.close();
	}
}

let activeGlobalDialog: HTMLDialogElement | undefined;
const globalDialogQueue: HTMLDialogElement[] = [];


export function createGlobalDialog (
	body: string | ((parent: HTMLElement, closeDialog: () => void) => void),
	preventCloseFromClickOut?: boolean
): void
{
	const dialog = document.createElement('dialog');
	dialog.style.zIndex = '2';
	dialog.addEventListener('close', () =>
	{
		document.body.removeChild(dialog);
		const nextDialog = globalDialogQueue.shift();
		if (nextDialog)
		{
			activeGlobalDialog = nextDialog;
			setTimeout(() => nextDialog.showModal(), 256);
		}
		else
		{
			activeGlobalDialog = undefined;
		}
	});
	if (!preventCloseFromClickOut)
	{
		dialog.addEventListener('click', event => closeDialogOnClickOut(event as DialogMouseEvent));
	}
	if (typeof body === 'string')
	{
		dialog.textContent = body;
	}
	else
	{
		body(dialog, () => dialog.close());
	}
	document.body.appendChild(dialog);

	if (activeGlobalDialog === undefined)
	{
		activeGlobalDialog = dialog;
		dialog.showModal();
	}
	else
	{
		globalDialogQueue.push(dialog);
	}
}


export function createErrorDialog (body: string | ((parent: HTMLElement, closeDialog: () => void) => void)): void
{
	createGlobalDialog((parent, closeDialog) =>
	{
		const pre = document.createElement('pre');
		pre.style.margin = '0';
		pre.style.whiteSpace = 'pre-wrap';
		if (typeof body === 'string')
		{
			pre.textContent = body;
		}
		else
		{
			body(pre, closeDialog);
		}
		parent.appendChild(pre);
	});
}
