import type { Site, UserSettings } from '../../shared/database';
import { normalizeForComparison } from './text';
import { getSiteName } from './settings';

export function insertionSort<T> (
	array: T[],
	valueToInsert: T,
	currentSortOrder: 'asc' | 'desc',
	comparer: (a: T, b: T) => number
): void
{
	for (let i = 0; i < array.length; i++)
	{
		const existing = array[i];
		const cmp = comparer(valueToInsert, existing);
		if (
			(currentSortOrder === 'desc' && cmp >= 0) ||
			cmp <= 0
		)
		{
			array.splice(i, 0, valueToInsert);
			return;
		}
	}
	array.push(valueToInsert);
}

export function sortSites (
	userSetings: UserSettings | null,
	sites: Site[]
): Site[]
{
	return sites.sort((a, b) => normalizeForComparison(a.name).localeCompare(normalizeForComparison(b.name)));
}
