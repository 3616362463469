import type { ChapterData, SiteData, StoryData } from '../../shared/common';
import type { AlternateAvailability, GroupedChapter, Site, SiteId, Story, UserSettings } from '../../shared/database';

export function getSiteName (
	userSettings: UserSettings,
	site: Site,
	sites: SiteData['sites'],
	alternateAvailability: readonly AlternateAvailability[]
): string
{
	let res = site.name;
	if (site.considerAlternateAvailability)
	{
		if (site.site === 'chereads' || site.site === 'webnovel')
		{
			if (userSettings.webnovelSitePreference)
			{
				for (const info of alternateAvailability)
				{
					if (info.site === userSettings.webnovelSitePreference)
					{
						res = sites[info.site].name;
						break;
					}
				}
			}
		}
		else
		{
			throw new Error(`Site ${site.site} has considerAlternateAvailability set to true but getSiteName does not support that site`);
		}
	}
	return res;
}

export function getSiteHomeUrl (
	userSettings: UserSettings,
	site: Site,
	sites: SiteData['sites'],
	alternateAvailability: readonly AlternateAvailability[]
): string
{
	let res = site.homeUrl;
	if (site.considerAlternateAvailability)
	{
		if (site.site === 'chereads' || site.site === 'webnovel')
		{
			if (userSettings.webnovelSitePreference)
			{
				for (const info of alternateAvailability)
				{
					if (info.site === userSettings.webnovelSitePreference)
					{
						res = sites[info.site].homeUrl;
						break;
					}
				}
			}
		}
		else
		{
			throw new Error(`Site ${site.site} has considerAlternateAvailability set to true but getSiteHomeUrl does not support that site`);
		}
	}
	return res;
}

export function getSiteIconUrl (
	userSettings: UserSettings,
	site: Site,
	sites: SiteData['sites'],
	alternateAvailability: readonly AlternateAvailability[]
): string | null
{
	let res = site.iconUrl;
	if (site.considerAlternateAvailability)
	{
		if (site.site === 'chereads' || site.site === 'webnovel')
		{
			if (userSettings.webnovelSitePreference)
			{
				for (const info of alternateAvailability)
				{
					if (info.site === userSettings.webnovelSitePreference)
					{
						const iconUrl = sites[info.site].iconUrl;
						if (iconUrl !== null)
						{
							res = iconUrl;
							break;
						}
					}
				}
			}
		}
		else
		{
			throw new Error(`Site ${site.site} has considerAlternateAvailability set to true but getSiteIconUrl does not support that site`);
		}
	}
	return res;
}

export function getStoryTitle (
	userSettings: UserSettings,
	site: Site,
	storyData: StoryData
): string;
export function getStoryTitle (
	userSettings: UserSettings,
	site: Site,
	story: Story,
	options: StoryData['options']
): string;
export function getStoryTitle (
	userSettings: UserSettings,
	site: Site,
	a: Story | StoryData,
	b?: StoryData['options']
): string
{
	const story = 'site' in a ? a : a.story;
	const options = 'site' in a ? b : a.options;
	if (options === undefined)
	{
		throw new RangeError('Parameter "options" not specified');
	}
	else if (options.optCustomTitle)
	{
		return options.optCustomTitle;
	}
	else if (site.site === 'manga-dex' && userSettings.mangaDexUseEnglishTitles)
	{
		return story.altTitle ?? story.title;
	}
	return story.title;
}

const substitutionPattern = /\$\{(\p{ID_Start}\p{ID_Continue}*)\}/gu;

function resolveTemplating (template: string, substitutions: Readonly<Partial<Record<string, string>>>): string
{
	let match: RegExpMatchArray | null;
	let res = '';
	let offset = 0;
	while ((match = substitutionPattern.exec(template)) !== null)
	{
		if (match.index === undefined)
		{
			throw new Error('RegExpMatchArray.index was undefined');
		}
		res += template.substring(offset, match.index);
		const id = match[1];
		const sub = substitutions[id];
		if (typeof sub !== 'string')
		{
			throw new RangeError(`Substitution \${${id}} is not present in the substitutions object`);
		}
		res += sub;
		offset = match.index + match[0].length;
	}
	res += template.substring(offset);
	return res;
}

export function getStoryIndexUrl (
	userSettings: UserSettings,
	site: Site,
	story: Story
): string
{
	let res = story.indexUrl;
	if (site.considerAlternateAvailability)
	{
		if (site.site === 'chereads' || site.site === 'webnovel')
		{
			if (userSettings.webnovelSitePreference)
			{
				for (const info of story.attrAlternateAvailability)
				{
					if (info.site === userSettings.webnovelSitePreference)
					{
						res = resolveTemplating(info.indexUrlTemplate, {
							storyId: story.storyId
						});
						break;
					}
				}
			}
		}
		else
		{
			throw new Error(`Site ${site.site} has considerAlternateAvailability set to true but getStoryIndexUrl does not support that site`);
		}
	}
	return res;
}

export function getGroupedChapterUrl (
	userSettings: UserSettings,
	site: Site,
	groupedChapter: GroupedChapter,
	alternateAvailability: readonly AlternateAvailability[]
): string
{
	let res = groupedChapter.url;
	if (site.considerAlternateAvailability)
	{
		if (site.site === 'chereads' || site.site === 'webnovel')
		{
			if (userSettings.webnovelSitePreference)
			{
				for (const info of alternateAvailability)
				{
					if (info.site === userSettings.webnovelSitePreference)
					{
						res = resolveTemplating(info.chapterUrlTemplate, {
							storyId: groupedChapter.storyId,
							chapterId: groupedChapter.chapterId
						});
						break;
					}
				}
			}
		}
		else
		{
			throw new Error(`Site ${site.site} has considerAlternateAvailability set to true but getGroupedChapterUrl does not support that site`);
		}
	}
	return res;
}

export function getChaptersInDescendingOrder (
	storyData: StoryData,
	site: Site
): ChapterData[]
{
	let res = storyData.chapters.
		slice().
		sort((a, b) => b.chapter.order - a.chapter.order);
	if (site.usesPremiumChapters)
	{
		if (storyData.options.optShowPremiumChapters)
		{
			if (storyData.roles.length !== 0)
			{
				res = res.filter(chapterData => !chapterData.chapter.attrIsPremium || chapterData.chapter.attrRequiredRoleId === null || storyData.options.optRoles.includes(chapterData.chapter.attrRequiredRoleId));
			}
		}
		else if (storyData.roles.length !== 0)
		{
			res = res.filter(chapterData => !chapterData.chapter.attrIsPremium);
		}
		else
		{
			const firstPremiumIndex = res.slice().reverse().findIndex(
				chapterData => chapterData.chapter.attrIsPremium
			);
			if (firstPremiumIndex !== -1)
			{
				res = res.slice(res.length - firstPremiumIndex);
			}
		}
	}
	return res;
}

export function getGroupedChapterPublishedDate (
	entrySettings: StoryData['options'],
	groupedChapter: GroupedChapter
): bigint
{
	if (
		entrySettings.optShowPremiumChapters &&
		groupedChapter.earlyAccessPublishedDate !== null &&
		groupedChapter.earlyAccessPublishedDate < groupedChapter.publishedDate
	)
	{
		return groupedChapter.earlyAccessPublishedDate;
	}
	return groupedChapter.publishedDate;
}

export function getEarliestGroupedChapter (
	entrySettings: StoryData['options'],
	chapterData: ChapterData
): GroupedChapter | undefined
{
	return chapterData.groupedChapters.reduce(
		(earliest, current) =>
		{
			if (
				!earliest ||
				getGroupedChapterPublishedDate(entrySettings, current) < getGroupedChapterPublishedDate(entrySettings, earliest)
			)
			{
				return current;
			}
			return earliest;
		},
		undefined as GroupedChapter | undefined
	);
}

export function getChapterEarliestPublishedDate (
	entrySettings: StoryData['options'],
	chapterData: ChapterData
): bigint
{
	const earliest = getEarliestGroupedChapter(entrySettings, chapterData);
	if (earliest)
	{
		return getGroupedChapterPublishedDate(entrySettings, earliest);
	}
	return 0n;
}
