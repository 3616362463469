<script context="module" lang="ts">
	export type TagState = 'required' | 'forbidden' | null;
</script>

<script lang="ts">
    import { writable, type Readable, derived, type Writable, get } from 'svelte/store';
    import type { SiteData, StoryData } from '../../../shared/common';
    import type { SiteId, StoryStatus, UserSettings } from '../../../shared/database';
    import { getStoryKey, type StoryKey } from '../keying';
    import { sortSites } from '../sort';
    import { showNsfwContent, globalDisable, allStoryStatuses } from '../state';
    import { normalizeForComparison } from '../text';
    import { getChapterEarliestPublishedDate, getChaptersInDescendingOrder, getSiteName, getStoryTitle } from '../settings';
    import ComplexTextSearch from './ComplexTextSearch.svelte';
    import SiteIcon from './SiteIcon.svelte';
    import SortControl, { type ChangeEventDetail } from './SortControl.svelte';
    import type { SortDirection } from './SortDirectionControl.svelte';
    import ThreeStateButton, { type ThreeStateButtonState } from './input/ThreeStateButton.svelte';
    import DynamicGrid from './layout/dynamic-grid/DynamicGrid.svelte';
    import DynamicGridCell from './layout/dynamic-grid/DynamicGridCell.svelte';
    import { assertNever } from '../assertions';
    import TagFilter from './input/TagFilter.svelte';
    import type { FilterInteractions } from './Story.svelte';
    import type { UnwrapStore } from '../utilTypes';
    import type { TagSet } from '../tags';

	export let visible: boolean;
	export let currentStoryData: Readable<ReadonlyMap<StoryKey, StoryData>>;
	export let allTags: Readable<TagSet>;
	export let siteData: SiteData;
	export let userSettings: UserSettings;
	export let acceptableOverall: Writable<StoryKey[]>;

	$: storyChaptersInDescendingOrder = new Map(Array.from($currentStoryData).map(([key, storyData]) => [key, getChaptersInDescendingOrder(storyData, siteData.sites[storyData.story.site])]));

	const sortOptions = {
		lastUpdated: 'Last Updated',
		title: 'Title',
		numChapters: 'Number of Chapters',
		releaseDate: 'Release Date'
	};
	let selectedSortOptionId: keyof typeof sortOptions = 'lastUpdated';
	let sortDirection: SortDirection = 'desc';

	$: lastUpdatedMap = new Map<string, Date>(Array.from(storyChaptersInDescendingOrder).map(([key, chapters]) =>
	{
		return [
			key,
			chapters.reduce(
				(lastUpdated, chapterData) =>
				{
					const storyData = $currentStoryData.get(key);
					if (!storyData)
					{
						throw new RangeError(`"${key}" is not in StoryData map`);
					}
					const candidate = new Date(Number(getChapterEarliestPublishedDate(storyData.options, chapterData)));
					return candidate > lastUpdated ? candidate : lastUpdated;
				},
				new Date(0)
			)
		];
	}));
	$: releaseDateMap = new Map<string, Date>(Array.from(storyChaptersInDescendingOrder).map(([key, chapters]) =>
	{
		return [
			key,
			chapters.reduce<Date | null>(
				(lastUpdated, chapterData) =>
				{
					const storyData = $currentStoryData.get(key);
					if (!storyData)
					{
						throw new RangeError(`"${key}" is not in StoryData map`);
					}
					const candidate = new Date(Number(getChapterEarliestPublishedDate(storyData.options, chapterData)));
					return lastUpdated === null || candidate < lastUpdated ? candidate : lastUpdated;
				},
				null
			) ?? new Date(0)
		];
	}));

	const sortFunctions: Readonly<Record<keyof typeof sortOptions, (a: StoryData, b: StoryData) => number>> = {
		lastUpdated: (a, b) =>
		{
			if (a.story.attrHasTooManyChaptersBug !== b.story.attrHasTooManyChaptersBug)
			{
				if (a.story.attrHasTooManyChaptersBug)
				{
					return 1;
				}
				return -1;
			}
			const aKey = getStoryKey(a.story);
			const bKey = getStoryKey(b.story);
			const aDate = lastUpdatedMap.get(aKey) ?? new Date(0);
			const bDate = lastUpdatedMap.get(bKey) ?? new Date(0);
			return aDate.valueOf() - bDate.valueOf();
		},
		title: (a, b) =>
		{
			const aTitle = normalizeForComparison(getStoryTitle(userSettings, siteData.sites[a.story.site], a));
			const bTitle = normalizeForComparison(getStoryTitle(userSettings, siteData.sites[b.story.site], b));
			return aTitle.localeCompare(bTitle);
		},
		numChapters: (a, b) =>
		{
			const aLength = storyChaptersInDescendingOrder.get(getStoryKey(a))?.length ?? a.chapters.length;
			const bLength = storyChaptersInDescendingOrder.get(getStoryKey(b))?.length ?? b.chapters.length;
			return aLength - bLength;
		},
		releaseDate: (a, b) =>
		{
			const aKey = getStoryKey(a.story);
			const bKey = getStoryKey(b.story);
			const aDate = releaseDateMap.get(aKey) ?? new Date(0);
			const bDate = releaseDateMap.get(bKey) ?? new Date(0);
			return aDate.valueOf() - bDate.valueOf();
		}
	};

	const sortedStatuses = Object.entries(allStoryStatuses).sort(([, a], [, b]) => normalizeForComparison(a).localeCompare(normalizeForComparison(b)));

	let requiredSites = new Set<SiteId>();
	let forbiddenSites = new Set<SiteId>();

	function changeSiteStatusFilter (site: SiteId, state: ThreeStateButtonState): void
	{
		if ($globalDisable)
		{
			return;
		}

		if (state === 'off')
		{
			requiredSites.delete(site);
			forbiddenSites.delete(site);
		}
		else if (state === 'on-yes')
		{
			requiredSites.add(site);
			forbiddenSites.delete(site);
		}
		else if (state === 'on-no')
		{
			requiredSites.delete(site);
			forbiddenSites.add(site);
		}
		else
		{
			assertNever(state, `Unknown three-state button state "${state}"`);
		}
		requiredSites = requiredSites;
		forbiddenSites = forbiddenSites;
	}

	let requiredStatuses = new Set<StoryStatus>();
	let forbiddenStatuses = new Set<StoryStatus>();

	function changeStoryStatusFilter (status: StoryStatus, state: ThreeStateButtonState): void
	{
		if ($globalDisable)
		{
			return;
		}

		if (state === 'off')
		{
			requiredStatuses.delete(status);
			forbiddenStatuses.delete(status);
		}
		else if (state === 'on-yes')
		{
			requiredStatuses.add(status);
			forbiddenStatuses.delete(status);
		}
		else if (state === 'on-no')
		{
			requiredStatuses.delete(status);
			forbiddenStatuses.add(status);
		}
		else
		{
			assertNever(state, `Unknown three-state button state "${state}"`);
		}
		requiredStatuses = requiredStatuses;
		forbiddenStatuses = forbiddenStatuses;
	}

	function sortingChange (event: CustomEvent<ChangeEventDetail<keyof typeof sortOptions>>): void
	{
		selectedSortOptionId = event.detail.option;
		sortDirection = event.detail.direction;
	}

	function getSearchableStrings (data: StoryData): string[]
	{
		return [
			data.story.title,
			...(data.story.altTitle ? [data.story.altTitle] : []),
			...data.authors.map(author => author.name),
			data.story.site.replace(/-/gu, ''),
			siteData.sites[data.story.site].name,
			...siteData.aliases[data.story.site]
		];
	}

	let nsfwState: ThreeStateButtonState = 'off';
	let allowedIdsByTextSearch: readonly StoryKey[] | null = null;
	let allowedIdsByTagFilter: readonly StoryKey[] | null = null;

	let tagFilter: Writable<TagFilter | undefined> = writable(undefined);

	export const interactions = derived(
		[tagFilter],
		([tagFilter]): UnwrapStore<FilterInteractions> | undefined =>
		{
			if (tagFilter === undefined)
			{
				return undefined;
			}
			return {
				usingComplexTagFiltering: tagFilter.usingComplexTagFiltering,
				addRequiredTag: tagFilter.addRequiredTag,
				removeTagFromFilter: tagFilter.removeTagFromFilter,
				tagStateMap: tagFilter.tagStateMap
			};
		}
	);

	$: $acceptableOverall = (() =>
	{
		let tuples = Array.from($currentStoryData).
			filter((([, storyData]) => !forbiddenSites.has(storyData.story.site))).
			filter(([, storyData]) => !forbiddenStatuses.has(storyData.story.status));

		if (requiredSites.size !== 0)
		{
			tuples = tuples.filter(([, storyData]) => requiredSites.has(storyData.story.site));
		}

		if (requiredStatuses.size !== 0)
		{
			tuples = tuples.filter(([, storyData]) => requiredStatuses.has(storyData.story.status));
		}

		if (nsfwState !== 'off')
		{
			tuples = tuples.filter(([, storyData]) => (storyData.options.optNsfw ?? storyData.story.isNsfw) === (nsfwState === 'on-yes'));
		}

		if (allowedIdsByTextSearch !== null)
		{
			tuples = tuples.filter(([key]) => (allowedIdsByTextSearch as NonNullable<typeof allowedIdsByTextSearch>).includes(key));
		}

		if (allowedIdsByTagFilter !== null)
		{
			tuples = tuples.filter(([key]) => (allowedIdsByTagFilter as NonNullable<typeof allowedIdsByTagFilter>).includes(key));
		}

		tuples = tuples.sort(([, a], [, b]) => sortFunctions[selectedSortOptionId](a, b));
		if (sortDirection === 'desc')
		{
			tuples = tuples.reverse();
		}
		return tuples.map(([key]) => key);
	})();
</script>

<div class="container" style:display={visible ? '' : 'none'}>
	<div class="p-like">
		<DynamicGrid
			margin="var(--margin-large) 0"
			gap="var(--margin-medium)">
			{#each sortSites(userSettings, Object.values(siteData.sites)) as site, index (site.site)}
				{#if $showNsfwContent || !site.isNsfw}
					{@const siteName = getSiteName(userSettings, site, siteData.sites, [])}
					<DynamicGridCell order={index}>
						<ThreeStateButton
							alt={siteName}
							disabled={$globalDisable}
							on:change={event => changeSiteStatusFilter(site.site, event.detail.state)}>
							<SiteIcon
								site={site}
								allSites={siteData.sites}
								userSettings={null}
								alternateAvailability={[]}
								link={false} />
							{siteName}
						</ThreeStateButton>
					</DynamicGridCell>
				{/if}
			{/each}
		</DynamicGrid>
	</div>

	<div class="p-like">
		<DynamicGrid
			margin="var(--margin-large) 0"
			gap="var(--margin-medium)">
			{#each sortedStatuses as [status, text], index (status)}
				<DynamicGridCell order={index}>
					<ThreeStateButton
						alt={text}
						disabled={$globalDisable}
						on:change={event => changeStoryStatusFilter(status, event.detail.state)}>
						{text}
					</ThreeStateButton>
				</DynamicGridCell>
			{/each}
		</DynamicGrid>
	</div>

	{#if $showNsfwContent}
		<div class="p-like">
			<ul class="inline-list">
				<li>
					<ThreeStateButton
						alt="Filter by NSFW Status"
						disabled={$globalDisable}
						bind:state={nsfwState}>
						NSFW
					</ThreeStateButton>
				</li>
			</ul>
		</div>
	{/if}

	<TagFilter
		storyData={Array.from($currentStoryData.values())}
		allTags={allTags}
		bind:this={$tagFilter}
		on:filter={event => allowedIdsByTagFilter = event.detail.acceptedItemIds} />

	<div class="p-like">
		<ComplexTextSearch
			placeholder="Filter stories by title..."
			items={Array.from($currentStoryData.values())}
			itemToSearchableStrings={getSearchableStrings}
			getItemId={getStoryKey}
			on:filter={event => allowedIdsByTextSearch = event.detail.acceptedItemIds} />
	</div>

	<div class="p-like">
		<SortControl
			options={sortOptions}
			selectedOptionId={selectedSortOptionId}
			direction={sortDirection}
			disabled={$globalDisable}
			on:change={sortingChange} />
	</div>
</div>

<style lang="scss">
	.container
	{
		position: relative;
	}
</style>
