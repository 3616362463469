<script context="module" lang="ts">
	export interface PartialReadingListData
	{
		readingList: ReadingListType | undefined;
		stories: Map<StoryKey, StoryData>;
		storyDisablers: Map<StoryKey, DisableManager>;
		complete: boolean;
	}

	export interface ReadingListData
	{
		readingList: ReadingListType;
		stories: Map<StoryKey, StoryData>;
		storyDisablers: Map<StoryKey, DisableManager>;
		complete: boolean;
	}

	export function isReadingListData (x: PartialReadingListData | null | undefined): x is ReadingListData
	{
		return x?.readingList !== undefined;
	}
</script>

<script lang="ts">
	import { onDestroy, onMount } from 'svelte';
	import { ApiError, apiAcknowledgeStoryDeletion, apiAddStoryToReadingList, apiCreateReadingList, apiDeleteReadingList, apiGetReadingListData, apiGetUserSettings, apiMoveStoryToReadingList, apiRenameReadingList, apiSwapReadingListPositions, type AuthState, type AbortManager } from '../api';
	import ReadingList from './ReadingList.svelte';
	import type { StoryData, UndoData } from '../../../shared/common';
	import type { ReadingList as ReadingListType, SiteId, UserSettings } from '../../../shared/database';
	import { ExternallyResolvablePromise } from '../ExternallyResolvablePromise';
	import TabGroup, { type TabId } from './layout/tabs/TabGroup.svelte';
	import TabList from './layout/tabs/TabList.svelte';
	import TabChoice from './layout/tabs/TabChoice.svelte';
	import TabContent from './layout/tabs/TabContent.svelte';
	import { assertNever } from '../assertions';
	import Settings from './Settings.svelte';
	import { createErrorDialog, createGlobalDialog } from '../dialogs';
	import Info from './Info.svelte';
	import { bidirectionalDerived, globalDisable, pSites, reactiveMatchMedia, useMobileLayout, showNsfwContent, DisableManager } from '../state';
	import { derived, writable } from 'svelte/store';
	import { getStoryKey, type StoryKey } from '../keying';
	import IconInfo from './svg-icons/IconInfo.svelte';
	import IconGear from './svg-icons/IconGear.svelte';
	import IconBookOpen from './svg-icons/IconBookOpen.svelte';
    import { TagSet } from '../tags';

	export let authState: AuthState;

	const allListData = writable(new Map<bigint, PartialReadingListData>());
	const allStoryData = bidirectionalDerived(
		[allListData],
		allListData => new Map(Array.from(allListData).map(([listId, data]) => [listId, data.stories])),
		(storyMap, allListData) =>
		{
			for (const [listId, stories] of storyMap)
			{
				const listData = allListData.get(listId);
				if (listData)
				{
					listData.stories = stories;
				}
			}
			return [allListData];
		}
	);
	const allTags = writable(new TagSet());
	const numStories = writable(new Map<bigint, Partial<Readonly<Record<SiteId, number>>>>());
	const completionData = derived(
		allListData,
		allListData => new Map(Array.from(allListData).map(([listId, data]) => [listId, data.complete]))
	);

	const pUserSettings = writable(apiGetUserSettings());

	async function switchDisplayedReadingList (
		targetListId: bigint | undefined,
		displayLoadedList: boolean
	): Promise<void>
	{
		if (displayLoadedList && targetListId !== undefined)
		{
			const data = $allListData.get(targetListId);
			if (data && data.complete)
			{
				$currentTabId = targetListId;
				return;
			}
		}

		try
		{
			let currentListData: PartialReadingListData | null = null;
			if (displayLoadedList && targetListId !== undefined)
			{
				const data = $allListData.get(targetListId);
				if (data)
				{
					currentListData = data;
				}
			}
			if (currentListData === null)
			{
				currentListData = {
					readingList: targetListId === undefined ? undefined : $allListData.get(targetListId)?.readingList,
					stories: new Map(),
					storyDisablers: new Map(),
					complete: false
				};
			}
			if (targetListId !== undefined)
			{
				allListData.update(map =>
				{
					if (map !== null && currentListData !== null)
					{
						map.set(targetListId, currentListData);
					}
					return map;
				});
			}

			for await (const streamedData of apiGetReadingListData(targetListId))
			{
				switch (streamedData.kind)
				{
					case 'current-reading-list':
						if (targetListId === undefined || currentListData.readingList === undefined)
						{
							currentListData.readingList = streamedData.readingList;
							if (displayLoadedList)
							{
								$currentTabId = streamedData.readingList.listId;
							}
							allListData.update(map =>
							{
								if (currentListData !== null)
								{
									map.set(streamedData.readingList.listId, currentListData);
								}
								return map;
							});
							changeDocumentTitle();
						}
						break;

					case 'num-stories':
						numStories.update(map =>
						{
							if (currentListData?.readingList === undefined)
							{
								throw new Error('Protocol error: numStories appeared before currentReadingList');
							}
							map.set(currentListData.readingList.listId, streamedData.numStoriesBySite);
							return map;
						});
						break;

					case 'all-reading-lists':
						allListData.update(map =>
						{
							for (const readingList of streamedData.readingLists)
							{
								if (!map.has(readingList.listId))
								{
									map.set(readingList.listId, {
										readingList: readingList,
										stories: new Map(),
										storyDisablers: new Map(),
										complete: false
									});
								}
							}
							return map;
						});
						break;

					case 'all-tags':
						allTags.update(set =>
						{
							for (const tag of streamedData.tags)
							{
								set.add(tag);
							}
							return set;
						});
						break;

					case 'story':
					{
						const key = getStoryKey(streamedData.storyData);
						currentListData.stories.set(key, streamedData.storyData);
						currentListData.storyDisablers.set(key, new DisableManager());
						allListData.update(x => x);
						if (streamedData.storyData.deleted && !streamedData.storyData.userAcknowledgedDeletion)
						{
							(async () =>
							{
								const siteData = await $pSites;
								createGlobalDialog(
									(parent, closeDialog) =>
									{
										const p = document.createElement('p');
										p.textContent = `"${streamedData.storyData.story.title}" (${key}) has been deleted, either by its author or a ${siteData.sites[streamedData.storyData.story.site].name} moderator. Your reading progress will remain saved so that if you find the story on another website you know where you were up to.`;
										parent.appendChild(p);
										const div = document.createElement('div');
										div.className = 'p-like';
										const btnOk = document.createElement('button');
										btnOk.type = 'button';
										btnOk.textContent = 'OK';
										btnOk.addEventListener('click', async () =>
										{
											closeDialog();
											try
											{
												await apiAcknowledgeStoryDeletion(streamedData.storyData.story.site, streamedData.storyData.story.storyId);
												streamedData.storyData.userAcknowledgedDeletion = true;
												allListData.update(x => x);
											}
											catch (e)
											{
												if (!(e instanceof ApiError))
												{
													throw e;
												}
												createErrorDialog(e.message);
											}
										});
										div.appendChild(btnOk);
										parent.appendChild(div);
									},
									true
								);
							})();
						}
						break;
					}

					case 'error':
						createErrorDialog(`Server Error: ${streamedData.message}`);
						break;

					default:
						assertNever(streamedData, `Unknown data kind ${(streamedData as any).kind}`);
				}
			}

			currentListData.complete = true;
			allListData.update(x => x);
		}
		catch (e)
		{
			if (!(e instanceof ApiError))
			{
				throw e;
			}
			createErrorDialog(e.message);
		}
	}

	const infoTabId = 'info';
	const settingsTabId = 'settings';
	const currentTabId = writable<bigint | typeof infoTabId | typeof settingsTabId | null>(null);
	let replacedState = false;
	const currentTabIdUnsub = currentTabId.subscribe(value =>
	{
		changeDocumentTitle();
		const params = new URLSearchParams(location.search);
		const savedTab = params.get('tab');
		if (
			value !== null &&
				(
					savedTab === null ||
						savedTab !== String(value)
				)
		)
		{
			let fn;
			if (replacedState)
			{
				fn = history.pushState.bind(history);
			}
			else
			{
				replacedState = true;
				fn = history.replaceState.bind(history);
			}
			let url = `${location.origin}${location.pathname}`;
			if (value !== null)
			{
				url += `?tab=${encodeURIComponent(String(value))}`;
			}
			fn(
				{
					tabId: String(value)
				},
				'',
				url
			);
		}
	});

	onMount(async () =>
	{
		let erpFinishedLoading = new ExternallyResolvablePromise<void>();
		let nextPopId = 0;
		window.addEventListener('popstate', async event =>
		{
			const popId = nextPopId++;
			await erpFinishedLoading.promise;
			if (popId === nextPopId - 1)
			{
				const tabId: unknown = event.state.tabId;
				if (typeof tabId === 'string')
				{
					if (tabId === infoTabId || tabId === settingsTabId)
					{
						$currentTabId = tabId;
					}
					else if (/^\d+/.test(tabId))
					{
						const listId = BigInt(tabId);
						$currentTabId = listId;
						erpFinishedLoading = new ExternallyResolvablePromise<void>();
						try
						{
							await switchDisplayedReadingList(listId, true);
						}
						finally
						{
							erpFinishedLoading.resolve();
						}
					}
				}
			}
		});

		try
		{
			const params = new URLSearchParams(location.search);
			const savedTab = params.get('tab');
			if (savedTab === null || savedTab === infoTabId || savedTab === settingsTabId)
			{
				if (savedTab !== null)
				{
					$currentTabId = savedTab;
				}
				await switchDisplayedReadingList(undefined, savedTab === null);
			}
			else if (/^\d+$/u.test(savedTab))
			{
				$currentTabId = BigInt(savedTab);
				await switchDisplayedReadingList($currentTabId, true);
			}
			else
			{
				await switchDisplayedReadingList(undefined, true);
			}
		}
		catch (e)
		{
			createErrorDialog(e instanceof Error ? e.message : String(e));
		}
		finally
		{
			erpFinishedLoading.resolve();
		}
	});

	onDestroy(currentTabIdUnsub);

	let subscribeUpdateMessages: string[] = [];
	let subscribeAbortManager: AbortManager | undefined;
	let subscribeStartTime: number | undefined;

	async function subscribeToStory (listId: bigint, url: string): Promise<void>
	{
		await globalDisable.runAction(async () =>
		{
			try
			{
				subscribeAbortManager = {
					controller: new AbortController(),
					allowed: writable(false)
				};
				subscribeStartTime = Date.now();
				const data = await apiAddStoryToReadingList(
					listId,
					url,
					update => subscribeUpdateMessages = [update, ...subscribeUpdateMessages],
					subscribeAbortManager
				);
				if (data !== null)
				{
					subscribeUpdateMessages = [];
					allListData.update(map =>
					{
						const listData = map.get(listId);
						if (listData)
						{
							const key = getStoryKey(data);
							listData.stories.set(key, data);
							listData.storyDisablers.set(key, new DisableManager());
						}
						return map;
					});
					if (data.story.isNsfw && !$showNsfwContent)
					{
						createGlobalDialog(`"${data.story.title}" was successfully subscribed to, but is hidden because your browser is configured to hide NSFW content. If this is a false positive, mark the story as SFW using this reading list's Edit mode, which will always show NSFW stories. To show all NSFW content, change the "NSFW Content" setting in the "Settings" tab.`);
					}
				}
			}
			catch (e)
			{
				if (e instanceof ApiError)
				{
					const match = /Unsupported site ([\s\S]+)$/u.exec(e.message);
					if (match)
					{
						createErrorDialog(`Unsupported site: ${match[1]}`);
					}
					else
					{
						createErrorDialog(e.message);
					}
					return;
				}
				throw e;
			}
			finally
			{
				subscribeUpdateMessages = [];
				subscribeAbortManager = undefined;
				subscribeStartTime = undefined;
			}
		});
	}

	async function createReadingList (name: string): Promise<void>
	{
		await globalDisable.runAction(async () =>
		{
			try
			{
				const newList = await apiCreateReadingList(name);
				allListData.update(map =>
				{
					map.set(newList.listId, {
						readingList: newList,
						stories: new Map(),
						storyDisablers: new Map(),
						complete: true
					});
					return map;
				});
			}
			catch (e)
			{
				if (!(e instanceof ApiError))
				{
					throw e;
				}
				createErrorDialog(e.message);
			}
		});
	}

	async function moveReadingList (listId: bigint, increment: -1 | 1): Promise<void>
	{
		await globalDisable.runAction(async () =>
		{
			try
			{
				const readingList = $allListData.get(listId)?.readingList;
				if (!readingList)
				{
					throw new RangeError(`List ID ${listId} is not in the set of known reading list IDs`);
				}
				const newOrder = readingList.order + BigInt(increment);
				if (newOrder >= 0n && newOrder < $allListData.size)
				{
					const partner = Array.from($allListData.values()).find(
						otherReadingList => newOrder === otherReadingList.readingList?.order
					)?.readingList;
					if (partner)
					{
						await apiSwapReadingListPositions(readingList.listId, partner.listId);
						partner.order = readingList.order;
						readingList.order = newOrder;
						allListData.update(x => x);
					}
				}
			}
			catch (e)
			{
				if (!(e instanceof ApiError))
				{
					throw e;
				}
				createErrorDialog(e.message);
			}
		});
	}

	async function renameReadingList (listId: bigint, newName: string): Promise<void>
	{
		await globalDisable.runAction(async () =>
		{
			try
			{
				const readingList = $allListData.get(listId)?.readingList;
				if (!readingList)
				{
					throw new RangeError(`List ID ${listId} is not in the set of known reading list IDs`);
				}
				await apiRenameReadingList(listId, newName);
				allListData.update(map =>
				{
					readingList.name = newName;
					return map;
				});
			}
			catch (e)
			{
				if (!(e instanceof ApiError))
				{
					throw e;
				}
				createErrorDialog(e.message);
			}
		});
	}

	async function deleteReadingList (listId: bigint)
	{
		await globalDisable.runAction(async () =>
		{
			try
			{
				const readingList = $allListData.get(listId)?.readingList;
				if (!readingList)
				{
					throw new RangeError(`List ID ${listId} is not in the set of known reading list IDs`);
				}
				await apiDeleteReadingList(listId);
				allListData.update(map =>
				{
					map.delete(listId);
					return map;
				});
			}
			catch (e)
			{
				if (!(e instanceof ApiError))
				{
					throw e;
				}
				createErrorDialog(e.message);
			}
		});
	}

	function changeDocumentTitle (): void
	{
		const activeTab = $currentTabId;
		let name: string;
		if (activeTab === infoTabId)
		{
			name = 'Info';
		}
		else if (activeTab === settingsTabId)
		{
			name = 'Settings';
		}
		else
		{
			const matchingList = Array.from($allListData?.values() ?? []).find(listData => listData.readingList?.listId === activeTab);
			if (matchingList?.readingList)
			{
				name = matchingList.readingList.name;
			}
			else
			{
				name = '[unknown reading list]';
			}
		}
		document.title = `${name} - Unified Reading List`;
	}

	const useHamburgerMenu = useMobileLayout;
	let menuIsOpen = false;

	$: sortedReadingLists = Array.from($allListData.values()).
		map(data => data.readingList).
		filter((readingList): readingList is ReadingListType => readingList !== undefined).
		sort((a, b) => Number(a.order - b.order));
</script>

{#if $currentTabId === null}
	<p>Loading story data...</p>
{:else}
	<div class="push-in">
		<TabGroup
			activeTab={$currentTabId}
			on:activeTabChange={event => $currentTabId = event.detail.activeTab}>
			<TabList
				collapse={$useHamburgerMenu || menuIsOpen}
				collapseIcon={IconBookOpen}
				collapseAlt="Reading Lists"
				collapseButtonActive={typeof $currentTabId === 'bigint'}
				collapseButtonClasses={['secondary']}
				collapseSelectedButtonClasses={[]}
				collapsedItemIsSelected={tabId => typeof tabId === 'bigint'}
				bind:dialogIsOpen={menuIsOpen}>
				<TabChoice
					id={infoTabId}
					icon={IconInfo}
					alt="Site Info"
					buttonClasses={['secondary']}
					selectedButtonClasses={[]}
					disabled={$globalDisable} />
				<TabChoice
					id={settingsTabId}
					icon={IconGear}
					alt="Settings"
					buttonClasses={['secondary']}
					selectedButtonClasses={[]}
					disabled={$globalDisable} />
				<svelte:fragment slot="collapsible">
					{#each sortedReadingLists as readingList (readingList.listId)}
						<span class:collapsed-choice={$useHamburgerMenu || menuIsOpen}>
							<TabChoice
								id={readingList.listId}
								buttonClasses={['secondary', 'collapsed-btn-wrap']}
								selectedButtonClasses={['collapsed-btn-wrap']}
								disabled={$globalDisable}
								on:select={() => switchDisplayedReadingList(readingList.listId, true)}>
								{readingList.name}
							</TabChoice>
						</span>
					{/each}
				</svelte:fragment>
			</TabList>
			<TabContent id={infoTabId}>
				{#await $pUserSettings}
					<p>Loading user settings...</p>
				{:then userSettings}
					<Info userSettings={userSettings} userHasAccess={true} />
				{:catch e}
					{@const _ = createErrorDialog(e.message)}
				{/await}
			</TabContent>
			<TabContent id={settingsTabId}>
				<Settings
					sortedReadingLists={sortedReadingLists}
					pUserSettings={pUserSettings}
					authState={authState}
					on:createReadingListRequest={event => createReadingList(event.detail.name)}
					on:readingListMoveRequest={event => moveReadingList(event.detail.listId, event.detail.increment)}
					on:readingListRenameRequest={event => renameReadingList(event.detail.listId, event.detail.newName)}
					on:readingListDeletionRequest={event => deleteReadingList(event.detail.listId)} />
			</TabContent>
			{#each sortedReadingLists as readingList (readingList.listId)}
				{@const listData = $allListData.get(readingList.listId)}
				<TabContent id={readingList.listId}>
					{#if isReadingListData(listData)}
						<div>
							{#if $useHamburgerMenu || menuIsOpen}
								<h1>{readingList.name}</h1>
							{/if}
							{#await $pSites}
								<p>Loading supported sites...</p>
							{:then siteData}
								{#await $pUserSettings}
									<p>Loading user settings...</p>
								{:then userSettings}
									<ReadingList
										siteData={siteData}
										allListData={allListData}
										allStoryData={allStoryData}
										allTags={allTags}
										completionData={completionData}
										userSettings={userSettings}
										listId={readingList.listId}
										numStories={$numStories.get(readingList.listId)}
										subscribeUpdateMessages={subscribeUpdateMessages}
										subscribeAbortManager={subscribeAbortManager}
										subscribeStartTime={subscribeStartTime}
										on:requestSubscribe={event => subscribeToStory(event.detail.listId, event.detail.url)} />
								{:catch e}
									{@const _ = createErrorDialog(e.message)}
								{/await}
							{:catch e}
								{@const _ = createErrorDialog(e.message)}
							{/await}
						</div>
					{/if}
				</TabContent>
			{/each}
		</TabGroup>
	</div>
{/if}

<style lang="scss">
	h1
	{
		text-align: center;
	}

	.collapsed-choice
	{
		position: relative;
		display: block;
		margin: var(--margin-medium) 0;
		text-align: center;
	}

	.collapsed-choice > :global(*)
	{
		width: 100%;
	}

	.collapsed-choice:first-child
	{
		margin-top: 0;
	}

	.collapsed-choice:last-child
	{
		margin-bottom: 0;
	}

	:global(.collapsed-btn-wrap)
	{
		white-space: normal;
	}
</style>
