<script lang="ts">
	import IconArrowUpRightFromSquare from '../svg-icons/IconArrowUpRightFromSquare.svelte';

	export let href: string;
	export let className: string = '';
	export let target: HTMLAnchorElement['target'] | undefined = undefined;
	export let inlineBlock = false;
</script>

<!-- This is all compressed into one line because of HTML whitespace rules -->
<a href={href} target={target} class={className} rel="noopener noreferrer" style:display={inlineBlock ? 'inline-block' : ''}><slot />{#if target === '_blank'}<sup><IconArrowUpRightFromSquare alt="(Link opens in new tab)" /></sup>{/if}</a>

<style lang="scss">
	sup :global(.icon)
	{
		width: 0.5rem;
		height: 0.5rem;
	}
</style>
