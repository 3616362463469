<script context="module" lang="ts">
	import { getContext, onDestroy, onMount, setContext } from 'svelte';
	import { writable, type Writable } from 'svelte/store';
	import { arrayChunk } from '../../../arrays';

	const cellWidthSymbol = Symbol('Cell Width Map');

	export function getCellWidthsMap (): Writable<Map<number, number>>
	{
		return getContext(cellWidthSymbol);
	}
</script>

<script lang="ts">
	export let margin = '0';
	export let gap = '0';

	const cellWidthMap = writable(new Map<number, number>());
	setContext(cellWidthSymbol, cellWidthMap);

	let gridContainer: HTMLElement | undefined;

	function updateRowWidths (map: Map<number, number>): void
	{
		if (gridContainer && map.size !== 0)
		{
			const selfWidth = gridContainer.offsetWidth;
			const gap = parseFloat(getComputedStyle(gridContainer).gap);
			const orderedWidths = Array.from(map).sort(([a], [b]) => a - b).map(([, width]) => width);
			for (let numColumns = map.size; numColumns >= 2; numColumns--)
			{
				const chunks = arrayChunk(orderedWidths, numColumns);
				const rowWidths = chunks.map(chunk =>
				{
					const contentWidth = numColumns * chunk.reduce(
						(max, width) => Math.max(max, width),
						0
					);
					return contentWidth + gap * (numColumns - 1);
				});
				const largestRowWidth = rowWidths.reduce(
					(max, width) => Math.max(max, width),
					0
				);
				if (largestRowWidth <= selfWidth)
				{
					gridContainer.style.gridTemplateColumns = `repeat(${numColumns}, 1fr)`;
					return;
				}
			}
			gridContainer.style.gridTemplateColumns = `1fr`;
		}
	}


	let raf: number | undefined;
	function enqueueUpdateRowWidths (map: Map<number, number>): void
	{
		if (raf !== undefined)
		{
			cancelAnimationFrame(raf);
		}
		raf = requestAnimationFrame(() =>
		{
			raf = undefined;
			updateRowWidths(map);
		});
	}

	function onResize (): void
	{
		enqueueUpdateRowWidths($cellWidthMap);
	}

	let cellWidthMapUnsub: (() => void) | undefined;
	onMount(() =>
	{
		cellWidthMapUnsub = cellWidthMap.subscribe(updateRowWidths);
		window.addEventListener('resize', onResize);
	});

	onDestroy(() =>
	{
		cellWidthMapUnsub?.();
		window.removeEventListener('resize', onResize);
	});
</script>

<div
	class="dynamic-grid"
	style:margin={margin}
	style:gap={gap}
	bind:this={gridContainer}>
	<slot />
</div>

<style lang="scss">
	.dynamic-grid
	{
		display: grid;
		grid-template-columns: 1fr;
	}
</style>
