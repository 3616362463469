<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	export let value: string;
	export let placeholder: string | undefined = undefined;
	export let disabled = false;

	const dispatcher = createEventDispatcher<{
		submit: {
			value: string;
		};
	}>();

	function onSubmit (): void
	{
		dispatcher('submit', {
			value: value
		});
	}
</script>

<form on:submit|preventDefault|stopPropagation={onSubmit}>
	<input type="text" placeholder={placeholder} disabled={disabled} bind:value={value} />
	<slot />
</form>

<style lang="scss">
	form
	{
		display: inline;
	}
</style>
