<script lang="ts">
	import { globalDisable } from '../state';

	import { normalizeForComparison } from '../text';
	import { createEventDispatcher } from 'svelte';

	type T = $$Generic;
	type U = $$Generic;

	export let items: readonly T[];
	export let itemToSearchableStrings: (item: T) => string | null | undefined | readonly (string | null | undefined)[];
	export let getItemId: (item: T) => U;
	export let label: string | undefined = undefined;
	export let placeholder: string | undefined = undefined;
	export let inline = false;

	const dispatcher = createEventDispatcher<{
		filter: {
			acceptedItemIds: U[];
		};
	}>();

	$: itemsWithStrings = items.map(item =>
	{
		const result = itemToSearchableStrings(item);
		return {
			item: item,
			strings: (typeof result === 'string' || result == null ? [result] : result).map(normalizeForComparison)
		};
	});

	let rawSearchText = '';
	let searcher = '';

	$: {
		if (rawSearchText === '')
		{
			if (searcher !== '')
			{
				searcher = '';
			}
		}
		else
		{
			const rawLower = normalizeForComparison(rawSearchText);
			if (searcher !== rawLower)
			{
				searcher = rawLower;
			}
		}
	}

	$: {
		const filtered = searcher === '' ? itemsWithStrings : itemsWithStrings.filter(info =>
		{
			return info.strings.some(string =>
			{
				if (string == null)
				{
					return false;
				}
				return string.includes(searcher);
			});
		});
		dispatcher('filter', {
			acceptedItemIds: filtered.map(info => getItemId(info.item))
		});
	}

	function onSearchTextChange (event: Event & { currentTarget: EventTarget & HTMLInputElement }): void
	{
		rawSearchText = event.currentTarget.value;
	}
</script>

<div class:p-like={!inline} style:display={inline ? 'inline' : ''}>
	{#if label}
		<label>
			{label}:
			<input
				type="text"
				placeholder={placeholder}
				on:input={onSearchTextChange}
				disabled={$globalDisable} />
		</label>
	{:else}
		<input
			type="text"
			placeholder={placeholder}
			on:input={onSearchTextChange}
			disabled={$globalDisable} />
	{/if}
</div>
