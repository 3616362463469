<script context="module" lang="ts">
	export interface ChangeEventDetail<T extends string>
	{
		option: T;
		direction: SortDirection;
	}
</script>

<script lang="ts">
	import SortDirectionControl from './SortDirectionControl.svelte';
	import type { SortDirection } from './SortDirectionControl.svelte';
	import { createEventDispatcher } from 'svelte';

	type T = $$Generic<string>;

	export let options: Readonly<Record<T, string>>;
	export let selectedOptionId: T;
	export let direction: SortDirection;
	export let label = 'Order by';
	export let disabled = false;

	$: optionsOrder = Object.keys(options).sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));

	const dispatcher = createEventDispatcher<{
		change: ChangeEventDetail<T>;
	}>();

	function emitChange (): void
	{
		dispatcher('change', {
			option: selectedOptionId,
			direction: direction
		});
	}

	function optionChange (event: Event & { currentTarget: EventTarget & HTMLSelectElement }): void
	{
		const id = event.currentTarget.selectedOptions.item(0)?.value;
		if (typeof id === 'string' && Object.hasOwn(options, id) && id !== selectedOptionId)
		{
			selectedOptionId = id as T;
			emitChange();
		}
	}

	function directionChange (event: CustomEvent<{ direction: SortDirection }>): void
	{
		direction = event.detail.direction;
		emitChange();
	}
</script>

<div class="p-like">
	<label>
		{label}:
		<select on:change={optionChange} disabled={disabled}>
			{#each optionsOrder as id (id)}
				<option value={id} selected={id === selectedOptionId}>{options[id]}</option>
			{/each}
		</select>
	</label>
	<SortDirectionControl
		direction={direction}
		disabled={disabled}
		on:change={directionChange} />
</div>
