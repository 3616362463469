<script lang="ts">
    import type { SiteData, StoryData } from '../../../shared/common';
    import type { Site, UserSettings } from '../../../shared/database';
    import { getStoryKey } from '../keying';
    import { getStoryIndexUrl, getStoryTitle } from '../settings';
    import SiteIcon from './SiteIcon.svelte';
    import ExternalLink from './input/ExternalLink.svelte';
    import IconButton from './input/IconButton.svelte';
    import ConjunctionList from './layout/ConjunctionList.svelte';
    import IconMaximize from './svg-icons/IconMaximize.svelte';
    import IconMinimize from './svg-icons/IconMinimize.svelte';

	export let hiddenStories: readonly StoryData[];
	export let userSettings: UserSettings;
	export let sites: SiteData['sites'];

	let isOpen = false;
</script>

{#if hiddenStories.length !== 0}
	<p>{hiddenStories.length} {hiddenStories.length === 1 ? 'story is' : 'stories are'} currently hidden because of the NSFW Content setting.</p>
	<details class="p-like" bind:open={isOpen}>
		<summary
			class="p-like"
			on:click|preventDefault={() => {}}>
			<IconButton
				icon={isOpen ? IconMinimize : IconMaximize}
				alt={`${isOpen ? 'Hide' : 'Show'} Filtered Stories`}
				classes={{
					secondary: true
				}}
				on:click={() => isOpen = !isOpen} />
		</summary>
		<ul>
			{#each hiddenStories as storyData (getStoryKey(storyData))}
				{@const site = sites[storyData.story.site]}
				<li>
					<SiteIcon
						site={site}
						allSites={sites}
						userSettings={userSettings}
						alternateAvailability={storyData.story.attrAlternateAvailability} />
					<ExternalLink href={getStoryIndexUrl(userSettings, site, storyData.story)}>
						{getStoryTitle(userSettings, site, storyData)}
					</ExternalLink>
					by
					<ConjunctionList>
						{#each storyData.authors as author (author.authorId)}
							<li>
								{#if author.url !== null}
									<ExternalLink href={author.url}>{author.name}</ExternalLink>
								{:else}
									{author.name}
								{/if}
							</li>
						{/each}
					</ConjunctionList>
				</li>
			{/each}
		</ul>
	</details>
{/if}

<style lang="scss">
	ul
	{
		padding: 0;
		list-style-type: none;
	}

	li
	{
		margin: var(--margin-small) 0;
	}

	summary
	{
		list-style: none;
	}

	summary::-webkit-details-marker,
	summary::marker
	{
		display: none;
	}
</style>
